import { View, Text } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';
import LightBackground from '../../components/LightBackground';
import CheckerHeader from '../../components/CheckerHeader';
import { ScrollView } from 'react-native';
import { Pressable } from 'react-native';
import {
	DownloadSvg,
	GreenTriageSvg,
	LimeTriageSvg,
	OrangeTriageSvg,
	RedTriageSvg,
	TeleSvg,
	YellowTriageSvg,
} from '../../components/Svg';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import Disclaimer from '../../components/Disclaimer';
import { Linking } from 'react-native';
import { api, baseUrl } from '../../helpers/helper';
import { warningToast } from '../../components/Toast';
import Skeleton from '../../components/Skeleton';
import { setMode, setUserActivity } from '../../redux/reducer';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';

const PatientEducationScreen = ({ navigation, route }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { checker, userActivity } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const [condition, setCondition] = useState();
	const [conditions, setConditions] = useState();
	const [resultId, setResultId] = useState();
	const [color, setColor] = useState();
	const [investigation, setInvestigation] = useState();
	const [management, setManagement] = useState();
	const [triage, setTriage] = useState();
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [isReport, setIsReport] = useState(false);

	const triageData = [
		{
			color: 'red',
			title: getStaticTranslation(
				'checker.red title',
				'Go To Nearest Emergency Department'
			),
			info: getStaticTranslation(
				'checker.red info',
				'Your symptoms appear to be serious, and you may require emergency care and immediate medical attention. Do not delay. Proceed to the hospital or emergency department immediately.'
			),
			svg: <RedTriageSvg color={theme.palette.secondary} />,
			bgColor: theme.palette.red[300],
			ftColor: theme.palette.secondary,
		},
		{
			color: 'orange',
			title: getStaticTranslation(
				'checker.orange title',
				'Clinic Visit Urgently Needed'
			),
			info: getStaticTranslation(
				'checker.orange info',
				'Your symptoms are worrisome and require a prompt evaluation and early medical attention. Recommended to visit a healthcare clinic for an urgent assessment to determine the appropriate course of action.'
			),
			svg: <OrangeTriageSvg />,
			bgColor: theme.palette.orange[200],
			ftColor: theme.palette.secondary,
		},
		{
			color: 'yellow',
			title: getStaticTranslation(
				'checker.yellow title',
				'Virtual Care or Clinic Visit Required'
			),
			info: getStaticTranslation(
				'checker.yellow info',
				'Your symptoms warrant attention, but they may not require an in-person visit at this point. Consider a virtual consultation with a healthcare provider. If advised, you can also choose to visit a clinic for a more thorough evaluation.'
			),
			svg: <YellowTriageSvg />,
			bgColor: theme.palette.yellow[200],
			ftColor: theme.palette.black[100],
		},
		{
			color: 'lime',
			title: getStaticTranslation(
				'checker.lime title',
				'Virtual Care or Telemedicine Recommended'
			),
			info: getStaticTranslation(
				'checker.lime info',
				"While your symptoms are not urgent, they may benefit from a virtual consultation with a doctor or scheduling a visit to clinic. It's a good idea to seek their advice to ensure your well-being."
			),
			svg: <LimeTriageSvg />,
			bgColor: theme.palette.lime[100],
			ftColor: theme.palette.black[100],
		},
		{
			color: 'green',
			title: getStaticTranslation(
				'checker.green title',
				'Self-Care Recommended'
			),
			info: getStaticTranslation(
				'checker.green info',
				'Home self-care and monitoring is an option at this current point of time but your symptoms may require medical evaluation if persistent or worsening. Schedule a teleconsultation or appointment with your doctor if need to.'
			),
			svg: <GreenTriageSvg />,
			bgColor: theme.palette.green[400],
			ftColor: theme.palette.secondary,
		},
	];
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		if (route.params?.data) {
			setCondition(route.params.data.condition);
			setConditions(route.params.data.conditions);
			setResultId(route.params.data.resultId);
			setIsReport(route.params.data.isReport);

			colorCheck();
			const parsedData = parseData(
				getTranslation(
					route?.params?.data?.condition,
					'triage.description',
					route?.params?.data?.condition?.triage?.description
				)
			);

			setInvestigation(parsedData.investigation);
			setManagement(parsedData.management);
			setLoading(false);
		}
	}, [route]);

	const colorCheck = () => {
		const { systolic, diastolic } = checker?.bloodPressure || {};
		const { pulseRate, temperature, pulseOximetry } = checker || {};

		const isHighBloodPressure =
			parseFloat(systolic) >= 180 || parseFloat(diastolic) >= 110;
		const isLowBloodPressure =
			parseFloat(systolic) <= 80 || parseFloat(diastolic) <= 50;
		const isAbnormalPulseRate =
			parseFloat(pulseRate) >= 120 || parseFloat(pulseRate) <= 50;
		const isHighTemperature = parseFloat(temperature) >= 40;
		const isLowPulseOximetry = parseFloat(pulseOximetry) <= 92;

		if (
			route.params.data.isYes ||
			isHighBloodPressure ||
			isLowBloodPressure ||
			isAbnormalPulseRate ||
			isHighTemperature ||
			isLowPulseOximetry
		) {
			setColor('red');
		} else {
			setColor(route.params.data.condition.triage.color);
		}
	};

	const parseData = data => {
		const sections = data.split(/\*\*([^*]+)\*\*/).filter(Boolean);

		const parsedData = {};
		for (let i = 0; i < sections.length; i += 2) {
			let key = sections[i].trim().replace(/:$/, '').toLowerCase();
			if (i === 0) {
				key = 'investigation';
			} else if (i === 2) {
				key = 'management';
			} else if (i === sections.length - 2) {
				key = 'disclaimer';
			}
			parsedData[key] = sections[i + 1].trim();
		}

		return parsedData;
	};

	useEffect(() => {
		const matchedTriage = triageData.find(triage => triage.color === color);
		setTriage(matchedTriage);
	}, [color]);

	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width - 25);
	};

	const handleDownload = () => {
		if (resultId) {
			const url = `${baseUrl}/api/conditions/pdf/${resultId}`;
			if (isWeb) {
				window.open(url, '_blank');
			} else {
				Linking.openURL(url);
			}
		}
	};

	const handleTele = async () => {
		if (userActivity && !isReport) {
			await api('user-activities', 'POST', {
				_id: userActivity._id,
				checker: true,
				teleconsultation: true,
				provider: 'abc company',
				clinic: 'abc Clinic',
				paymentMethod: 'Corporate Billing',
			}).then(() => {
				dispatch(setUserActivity(null));
			});
		}
		warningToast(
			getStaticTranslation(
				'checker.tele warning',
				'This function is not available yet.'
			)
		);
	};
	return (
		<LightBackground>
			<View style={styles.container} onLayout={handleLayout}>
				<CheckerHeader
					step={3}
					condition={condition}
					conditions={conditions}
					resultId={resultId}
					isReport={isReport}
					checker={isReport ? route.params?.data.checker : checker}
				/>
				<ScrollView
					style={styles.scrollContainer}
					contentContainerStyle={styles.scrollContent}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				>
					{loading ? (
						<View style={styles.loadingContainer}>
							<Skeleton height={180} width={containerWidth} borderRadius={12} />
							<Skeleton height={100} width={containerWidth} borderRadius={12} />
							<Skeleton height={500} width={containerWidth} borderRadius={12} />
							<Skeleton height={50} width={containerWidth} borderRadius={12} />
							<Skeleton height={60} width={containerWidth} borderRadius={12} />
							<Skeleton height={60} width={containerWidth} borderRadius={12} />
							<Skeleton height={60} width={containerWidth} borderRadius={12} />
						</View>
					) : (
						<>
							<View
								style={[
									styles.alertContainer,
									{ backgroundColor: triage?.bgColor },
								]}
							>
								<View style={styles.alertSubContainer}>
									{triage?.svg}
									<Text style={[styles.alertTitle, { color: triage?.ftColor }]}>
										{triage?.title}
									</Text>
								</View>
								<Text style={[styles.alertText, { color: triage?.ftColor }]}>
									{triage?.info}
								</Text>
							</View>
							<View style={styles.sectionContainer}>
								<Text style={styles.sectionTitle}>
									{getStaticTranslation(
										'checker.investigation',
										'Investigation'
									)}
								</Text>
								<Text style={styles.sectionText}>{investigation}</Text>
							</View>
							<View style={styles.sectionContainer}>
								<Text style={styles.sectionTitle}>
									{getStaticTranslation('checker.management', 'Management')}
								</Text>
								<Text style={styles.sectionText}>{management}</Text>
							</View>
							<View style={styles.disclaimer}>
								<Disclaimer isEdu={true} />
							</View>
							<Pressable
								style={[
									styles.button,
									// { backgroundColor: theme.palette.lightGreen[300] },
									{ backgroundColor: "transparent" },
								]}
								disabled
								onPress={handleTele}
							>
								<TeleSvg />
								<Text style={styles.secondaryButtonText}>
									{getStaticTranslation(
										'checker.tele title',
										'Teleconsultation a doctor'
									)}
								</Text>
							</Pressable>
							<Pressable
								style={[
									styles.button,
									{ backgroundColor: theme.palette.green[700] },
								]}
								onPress={handleDownload}
							>
								<DownloadSvg
									width={26}
									height={24}
									color={theme.palette.secondary}
								/>
								<Text style={styles.buttonText}>
									{getStaticTranslation('checker.download', 'Download Report')}
								</Text>
							</Pressable>
							
							<Pressable
								style={[
									styles.button,
									{ backgroundColor: theme.palette.green[100] },
								]}
								onPress={() => {
									dispatch(setMode('dark'));
									if (isReport) {
										navigation.navigate('Result', {
											data: {
												condition: condition,
												conditions: conditions,
												resultId: resultId,
												isReport: isReport,
												checker: isReport
													? route.params?.data.checker
													: checker,
											},
										});
									} else {
										navigation.navigate('Result');
									}
								}}
							>
								<Text style={styles.secondaryButtonText}>
									{getStaticTranslation(
										'checker.other',
										'Other Possible Condition(s)'
									)}
								</Text>
							</Pressable>
						</>
					)}
				</ScrollView>
			</View>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			flex: 1,
			paddingHorizontal: 20,
			paddingTop: 10,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'center',
			gap: 10,
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
			marginVertical: 10,
		},
		scrollContent: {
			paddingHorizontal: 5,
			paddingVertical: 10,
			flexGrow: 1,
			gap: 10,
			height: isWeb ? height * 0.7 : 'auto',
		},
		alertContainer: {
			paddingHorizontal: 15,
			paddingVertical: 15,
			borderRadius: 10,
			elevation: 1,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 2 },
			shadowOpacity: 0.25,
			shadowRadius: 1,
		},
		alertSubContainer: {
			alignItems: 'center',
			justifyContent: 'flex-start',
			display: 'flex',
			flexDirection: 'row',
			gap: 10,
		},
		alertTitle: {
			fontFamily: fontFamily.poppins[700],
			fontSize: fontSize[16],
			width: "90%",
			flexWrap: "wrap"
		},
		alertText: {
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			textAlign: align.justify,
		},
		sectionContainer: {
			backgroundColor: theme.palette.secondary,
			paddingHorizontal: 15,
			paddingVertical: 10,

			borderRadius: 10,
			elevation: 1,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 2 },
			shadowOpacity: 0.25,
			shadowRadius: 1,
		},
		sectionTitle: {
			fontFamily: fontFamily.poppins[700],
			fontSize: fontSize[16],
			color: theme.palette.black[100],
		},
		sectionText: {
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			color: theme.palette.black[100],
			textAlign: align.justify,
		},
		disclaimer: {
			marginBottom: 20,
		},
		button: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 15,
			borderRadius: 10,
			backgroundColor: '#00B0B9',
			gap: 10,
		},
		buttonText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},

		secondaryButtonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},
	});
};

export default PatientEducationScreen;
