import { View, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { StyleSheet } from 'react-native';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { Pressable } from 'react-native';
import { ArrowLeftSvg, HomeSvg } from './Svg';
import { setMode } from '../redux/reducer';
import BackHomeModal from './BackHomeModal';
import _ from 'lodash';
import { useTranslation } from './TranslationProvider';

const CheckerHeader = ({
	step,
	conditions,
	condition,
	resultId,
	isReport,
	checker,
}) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, step);
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const data = [
		{ step: 1, title: getStaticTranslation('checker.result', 'Results') },
		{
			step: 2,
			title: getStaticTranslation(
				'checker.condition symptoms',
				'Condition Symptoms'
			),
		},
		{
			step: 3,
			title: getStaticTranslation(
				'checker.patient education',
				'Patient Education'
			),
		},
	];
	const [modalVisible, setModalVisible] = useState(false);
	const currentStepData = data.find(d => d.step === step);
	useEffect(() => {
		if (step === 1) {
			dispatch(setMode('dark'));
		} else {
			dispatch(setMode('light'));
		}
	}, [step]);
	return (
		<View style={styles.header}>
			{((isReport && step === 1) ||
				step !== 1) && (
				<Pressable
					onPress={() => {
						if (step === 1) {
							dispatch(setMode('dark'));
							navigation.navigate('HomeTabs', {
								screen: 'Report',
							});
							return;
						} else if (step === 2) {
							dispatch(setMode('dark'));
							navigation.navigate('Result', {
								data: {
									condition: condition,
									conditions: conditions,
									resultId: resultId,
									isReport: isReport,
									checker: checker,
								},
							});
						} else {
							if (!_.isEmpty(checker?.symptom?.redFlagDescription)) {
								navigation.navigate('RedFlag', {
									data: {
										condition: condition,
										conditions: conditions,
										resultId: resultId,
										isReport: isReport,
										checker: checker,
									},
								});
							} else {
								dispatch(setMode('dark'));
								navigation.navigate('Result', {
									data: {
										condition: condition,
										conditions: conditions,
										resultId: resultId,
										isReport: isReport,
										checker: checker,
									},
								});
							}
						}
					}}
				>
					<ArrowLeftSvg
						color={
							step === 1 ? theme.palette.secondary : theme.palette.green[700]
						}
						width={12}
						height={19}
					/>
				</Pressable>
			)}
			<Text style={styles.title}>{currentStepData.title}</Text>
			{!isReport && (
				<Pressable onPress={() => setModalVisible(true)}>
					<HomeSvg
						color={
							step === 1 ? theme.palette.secondary : theme.palette.green[700]
						}
					/>
				</Pressable>
			)}

			<BackHomeModal
				visible={modalVisible}
				onClose={() => setModalVisible(false)}
				isResult={true}
			/>
		</View>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, step) => {
	const fontSize = getFontSize();
	const align = getAlign();
	return StyleSheet.create({
		header: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		title: {
			flex: 1,
			textAlign: align.center,
			color: step === 1 ? theme.palette.secondary : theme.palette.black[100],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingLeft: step === 1 ? 20 : 5,
		},
	});
};
export default CheckerHeader;
