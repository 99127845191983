import React, { useCallback, useEffect, useState } from 'react';
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	Pressable,
	Platform,
	useWindowDimensions,
} from 'react-native';
import { theme, fontFamily, getFontSize, getAlign } from '../../theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { errorToast, successToast } from '../../components/Toast';
import { ARXLogo, LogoSvg } from '../../components/Svg';
import Background from '../../components/Background';
import Language from '../../components/Language';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { setMode } from '../../redux/reducer';

const ForgotPasswordScreen = ({ route }) => {
	const { mode, oobCode } = route.params;
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const [isFocused, setIsFocused] = useState({
		password: false,
		confirmPassword: false,
	});
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const validatePassword = password => {
		const re =
			/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/
		return re.test(password);
	};
	const dispatch = useDispatch()
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('dark'));
		}, [dispatch])
	);
	useEffect(() => {
		if (mode !== 'resetPassword' || !oobCode) {
			errorToast(
				getStaticTranslation(
					'forgotPassword.expired',
					'Invalid or expired reset link'
				)
			);
			navigation.navigate('Auth');
		}
	}, [mode, oobCode]);

	const handleReset = async () => {
		try {
			if (!validatePassword(password)) {
				throw new Error(
					getStaticTranslation(
						'forgotPassword.invalid password',
						'Password must be at least 8 characters long and include a combination of letters, numbers, symbols, and at least one uppercase letter.'
					)
				);
			}
			if (password !== confirmPassword) {
				throw new Error(
					getStaticTranslation(
						'forgotPassword.password not match',
						'Passwords do not match.'
					)
				);
			}
			await confirmPasswordReset(auth, oobCode, password);
			successToast(
				getStaticTranslation(
					'forgotPassword.reset success',
					'Password has been reset successfully'
				)
			);
			navigation.navigate('Auth');
		} catch (e) {
			if (e.code === 'auth/expired-action-code') {
				errorToast(
					getStaticTranslation(
						'forgotPassword.link expired',
						'The reset password link has expired. Please try again.'
					)
				);
			} else if (e.code === 'auth/invalid-action-code') {
				errorToast(
					getStaticTranslation(
						'forgotPassword.link invalid',
						'The reset password link is invalid. Please try again.'
					)
				);
			} else {
				errorToast(e.message);
			}
		}
	};

	const handleInputFocus = textinput => {
		setIsFocused({
			[textinput]: true,
		});
	};
	const handleInputBlur = textinput => {
		setIsFocused({
			[textinput]: false,
		});
	};
	return (
		<Background>
			<View style={styles.languageContainer}>
				<Language isAuth={true} />
			</View>
			<View style={styles.logoContainer}>
				<Text style={styles.welcome}>
					{getStaticTranslation('forgotPassword.welcome', 'Welcome to')}
				</Text>
				<LogoSvg
					width={isAndroid || isIos ? '280' : '78%'}
					height={isAndroid || isIos ? '49' : '23%'}
				/>
				<Text style={styles.slogan}>
					{getStaticTranslation(
						'forgotPassword.slogan',
						'The transformation of future healthcare system'
					)}
				</Text>
			</View>

			<View style={styles.loginContainer}>
				<View style={styles.formContainer}>
					<Text style={styles.info}>
						{getStaticTranslation(
							'forgotPassword.reset title',
							'Reset your password'
						)}
					</Text>

					<View
						style={
							isFocused.password
								? [styles.passwordContainer, styles.border]
								: styles.passwordContainer
						}
					>
						<TextInput
							placeholder={getStaticTranslation(
								'forgotPassword.password',
								'Password'
							)}
							secureTextEntry={!passwordVisible}
							style={[
								styles.input,
								{ marginTop: 0, width: isAndroid || isIos ? '80%' : '100%' },
								isWeb && { outlineStyle: 'none' }
							]}
							value={password}
							onChangeText={setPassword}
							placeholderTextColor={theme.palette.grey[300]}
							onFocus={() => handleInputFocus('password')}
							onBlur={() => handleInputBlur('password')}
						/>
						<Pressable onPress={() => setPasswordVisible(!passwordVisible)}>
							<Icon
								name={passwordVisible ? 'eye-off' : 'eye'}
								size={25}
								style={styles.toggleIcon}
							/>
						</Pressable>
					</View>

					<View
						style={
							isFocused.confirmPassword
								? [styles.passwordContainer, styles.border]
								: styles.passwordContainer
						}
					>
						<TextInput
							placeholder={getStaticTranslation(
								'forgotPassword.confirm password',
								'Confirm Password'
							)}
							secureTextEntry={!confirmPasswordVisible}
							style={[
								styles.input,
								{
									marginTop: 0,
									width: isAndroid || isIos ? '80%' : '100%',
								},
								isWeb && { outlineStyle: 'none' }
							]}
							value={confirmPassword}
							onChangeText={setConfirmPassword}
							placeholderTextColor={theme.palette.grey[300]}
							onFocus={() => handleInputFocus('confirmPassword')}
							onBlur={() => handleInputBlur('confirmPassword')}
						/>
						<Pressable
							onPress={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
						>
							<Icon
								name={confirmPasswordVisible ? 'eye-off' : 'eye'}
								size={25}
								style={styles.toggleIcon}
							/>
						</Pressable>
					</View>
					<Text style={styles.passwordInfo}>
						{getStaticTranslation(
							'forgotPassword.password info',
							'Password must be at least 8 characters long and include a combination of letters, numbers, and symbols, with at least one uppercase letter, one number, and one symbol.'
						)}
					</Text>

					<Pressable style={styles.button} onPress={handleReset}>
						<Text style={styles.buttonText}>
							{getStaticTranslation('forgotPassword.change', 'Change Password')}
						</Text>
					</Pressable>

					<View style={styles.footerContainer}>
						<Pressable onPress={() => { }}>
							<ARXLogo color={theme.palette.primary} />
						</Pressable>
					</View>
				</View>
			</View>
		</Background>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		languageContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
			paddingTop: isAndroid || isIos ? 10 : 20,
			paddingRight: isAndroid || isIos ? 15 : 30,
			width: '100%',
		},
		logoContainer: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
		},
		welcome: {
			color: theme.palette.secondary,
			fontSize: fontSize[20],
			textAlign: align.center,
			fontFamily: fontFamily.poppins[500],
		},
		slogan: {
			color: theme.palette.secondary,
			marginTop: 10,
			fontSize: fontSize[14],
			textAlign: align.center,
			fontFamily: fontFamily.poppins[300],
		},
		loginContainer: {
			width: '100%',
			backgroundColor: theme.palette.lightGreen[100],
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderTopRightRadius: 25,
			borderTopLeftRadius: 25,
			paddingVertical: isAndroid || isIos ? (isSignUp ? 0 : '5%') : '5%',
			paddingHorizontal: '15%',
			paddingBottom: '0%',
		},
		formContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: 10,
			width: isAndroid || isIos ? '100%' : 'auto',
			height: isAndroid
				? isSignUp
					? '73%'
					: '62.8%'
				: isIos
					? isSignUp
						? '73%'
						: '64.5%'
					: 'auto',
		},

		info: {
			color: theme.palette.primary,
			marginBottom: 10,
			textAlign: align.center,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[500],
		},
		input: {
			backgroundColor: theme.palette.secondary,
			padding: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: '100%',
			height: 50,
			marginTop: 10,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
		},
		passwordContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: 10,
			justifyContent: 'space-between',
			backgroundColor: theme.palette.secondary,
			width: '100%',
			borderRadius: 10,
		},
		toggleIcon: {
			color: theme.palette.grey[300],
			marginHorizontal: 10,
		},

		border: {
			borderWidth: 2,
			borderColor: theme.palette.green[700],
		},
		button: {
			backgroundColor: theme.palette.green[700],
			marginTop: 15,
			width: '100%',
			height: 50,
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: 'center',
		},
		buttonText: {
			color: theme.palette.secondary,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
		passwordInfo: {
			color: theme.palette.grey[300],
			fontSize: fontSize[12],
			textAlign: align.justify,
			marginTop: 10,
			fontFamily: fontFamily.poppins[300],
			display: height < 570 ? 'none' : 'flex',
		},
		footerContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: 20,
			marginBottom: 0,
		},
	});
};
export default ForgotPasswordScreen;
