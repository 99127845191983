import React from 'react';
import {
	NavigationContainer,
	DefaultTheme,
	useNavigation,
	getPathFromState,
	getStateFromPath,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeTabs from './HomeTabs';
import { theme } from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import AuthScreen from '../screens/Auth/AuthScreen';
import { auth } from '../config/firebase';
import {
	setIsAuthenticated,
	setMode,
	setUser,
	setUserActivity,
} from '../redux/reducer';
import { useEffect } from 'react';
import { api, isDev, isTest } from '../helpers/helper';
import { signOut } from 'firebase/auth';
import ForgotPasswordScreen from '../screens/Auth/ForgotPasswordScreen';
import CheckerNavigator from './CheckerNavigator';
import LabNavigator from './LabNavigator';
import ProfileNavigator from './ProfileNavigator';
import { errorToast } from '../components/Toast';
import { Linking, LogBox } from 'react-native';
import { useTranslation } from '../components/TranslationProvider';
import ResultScreen from '../screens/Checker/ResultScreen';

const Stack = createNativeStackNavigator();

const AppNavigator = () => {
	const { isAuthenticated, user, userActivity } = useSelector(
		state => state.app
	);
	const { getStaticTranslation } = useTranslation();
	const dispatch = useDispatch();
	const handleAuthError = async error => {
		if (error.code) {
			switch (error.code) {
				case 'auth/user-disabled':
					errorToast(
						getStaticTranslation(
							'auth.user disable',
							'The user account has been disabled by an administrator.'
						)
					);
					break;
				case 'auth/user-token-expired':
					errorToast(
						getStaticTranslation(
							'auth.token expired',
							'User credential has expired. Please sign in again.'
						)
					);
					break;
				case 'auth/network-request-failed':
					errorToast(
						getStaticTranslation(
							'auth.network fail',
							'Network error. Please try again.'
						)
					);
					break;
				case 'auth/requires-recent-login':
					errorToast(
						getStaticTranslation(
							'auth.require login',
							'Recent authentication required. Please sign in again.'
						)
					);
					break;
				default:
					errorToast(
						getStaticTranslation(
							'auth.unknown auth error',
							'An unknown authentication error occurred.'
						)
					);
					break;
			}
		} else if (error.response) {
			switch (error.response.status) {
				case 401:
					errorToast(
						getStaticTranslation(
							'auth.401',
							'Unauthorized access. Please log in again.'
						)
					);
					break;
				case 403:
					errorToast(
						getStaticTranslation(
							'auth.403',
							'Forbidden. You do not have permission to access this resource.'
						)
					);
					break;
				case 404:
					errorToast(getStaticTranslation('auth.404', 'Resource not found.'));
					break;
				case 500:
					errorToast(
						getStaticTranslation(
							'auth.500',
							'Internal server error. Please try again later.'
						)
					);
					break;
				default:
					errorToast(
						getStaticTranslation(
							'auth.unknown error',
							'An unknown error occurred.'
						)
					);
					break;
			}
		} else {
			errorToast(
				getStaticTranslation('auth.unknown error', 'An unknown error occurred.')
			);
		}
	};

	useEffect(() => {
			dispatch(setMode('dark'));
			const unsubscribe = auth.onAuthStateChanged(async userAuth => {
				if (userAuth) {
					try {
						const res = await api(`users/profile`);
						dispatch(setUser(res.result));
						dispatch(setIsAuthenticated(true));
						if (!userActivity && res.result._id) {
							const activity = await api('user-activities', 'post', {
								_userId: res.result._id,
								paymentMethod: 'Corporate Billing',
							});
							dispatch(setUserActivity(activity.result));
						}
					} catch (e) {
						handleAuthError(e);
						await signOut(auth);
						dispatch(setUser(null));
						dispatch(setIsAuthenticated(false));
					}
				}
				else {
					await signOut(auth);
					dispatch(setUser(null));
					dispatch(setIsAuthenticated(false));
				}
			});

			return unsubscribe;
	}, [auth, isAuthenticated]);
	const onNavigationStateChange = state => {
		// console.log('Current navigation state:', state);
	};
	const MyTheme = {
		...DefaultTheme,
		colors: {
			...DefaultTheme.colors,
			primary: theme.palette.green[200],
			background: theme.palette.green[700],
			text: theme.palette.green[700],
			border: theme.palette.green[100],
		},
	};
	const uri = isDev
		? process.env.EXPO_APP_DEV_URI
		: isTest
		? process.env.EXPO_APP_TEST_URI
		: process.env.EXPO_APP_URI;
	const linking = {
		prefixes: [uri],
		config: {
			screens: {
				HomeTabs: {
					path: 'HomeTabs',
					screens: {
						Home: {
							path: 'Home',
							screens: {
								Overview: {
									path: 'Overview/:data?',
									parse: {
										data: data => data.replace(/^@/, ''),
									},
									stringify: {
										data: data => '',
									},
								},
							},
						},
					},
				},
				CheckerNavigator: {
					path: 'CheckerNavigator',
					screens: {
						Result: {
							path: 'Result/:data?',
							parse: {
								data: data => JSON.parse(decodeURIComponent(data)),
							},
							stringify: {
								data: data => '',
							},
						},
					},
				},
				ForgotPassword: {
					path: 'forgotPassword/:mode?',
					parse: {
						mode: mode => mode.replace(/^@/, ''),
						oobCode: oobCode => oobCode.replace(/^@/, ''),
						apiKey: apiKey => apiKey.replace(/^@/, ''),
						lang: lang => lang.replace(/^@/, ''),
					},
					stringify: {
						mode: mode => '',
						oobCode: oobCode => '',
						apiKey: apiKey => '',
						lang: lang => '',
					},
				},

				RedFlag: {
					path: 'RedFlag/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},
				// Result: {
				// 	path: 'Result/:data?',
				// 	parse: {
				// 		data: data => data.replace(/^@/, ''),
				// 	},
				// 	stringify: {
				// 		data: data => '',
				// 	},
				// },
				Education: {
					path: 'Education/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},
				Upload: {
					path: 'Upload/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},
				LabResult: {
					path: 'LabResult/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},
				LabResultDetail: {
					path: 'LabResultDetail/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},

				MedicalCase: {
					path: 'MedicalCase/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},
				FamilyMedical: {
					path: 'FamilyMedical/:data?',
					parse: {
						data: data => data.replace(/^@/, ''),
					},
					stringify: {
						data: data => '',
					},
				},
			},
		},
	};
	LogBox.ignoreLogs([
		'Warning: componentWillUpdate has been renamed',
		'Constants.platform.ios.model has been deprecated',
	]);
	return (
		<NavigationContainer
			theme={MyTheme}
			linking={linking}
			onStateChange={onNavigationStateChange}
		>
			<Stack.Navigator
				initialRouteName={
					isAuthenticated && user !== null ? 'HomeTabs' : 'Auth'
				}
				screenOptions={{ headerShown: false }}
			>
				{isAuthenticated && user !== null ? (
					<>
						<Stack.Screen name='HomeTabs' component={HomeTabs} />
						<Stack.Screen
							name='CheckerNavigator'
							component={CheckerNavigator}
						/>
						<Stack.Screen name='LabNavigator' component={LabNavigator} />
						<Stack.Screen
							name='ProfileNavigator'
							component={ProfileNavigator}
						/>
					</>
				) : (
					<>
						<Stack.Screen name='Auth' component={AuthScreen} />
						<Stack.Screen
							name='ForgotPassword'
							component={ForgotPasswordScreen}
						/>
					</>
				)}
			</Stack.Navigator>
		</NavigationContainer>
	);
};

export default AppNavigator;
