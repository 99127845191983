// src/utils/toast.js

import { Platform } from 'react-native';
import Toast from 'react-native-root-toast';
import { toast as webToast, ToastContainer } from 'react-toastify';
import { theme } from '../theme';
if (Platform.OS === 'web') {
import ('react-toastify/dist/ReactToastify.css'
)	

}
	
export const ToastProvider = () => {
	
	return Platform.OS === 'web' ? <ToastContainer /> : null;
};

const commonToastOptions = {
	duration: Toast.durations.SHORT,
	position: Toast.positions.TOP,
	hideOnPress: true,
	shadow: false,
	opacity: 1,
	textStyle: { fontSize: 14, paddingHorizontal: 10 },
	containerStyle: { borderRadius: 10 , zIndex: 9999999},
};

const showToast = (text, options, type) => {
	if (Platform.OS === 'web') {
		webToast[type](text, {
			position: 'top-center',
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			theme: 'colored',
			style: {
				...options.containerStyle,
				color: options.textColor,
				backgroundColor: options.backgroundColor,
			},
		});
	} else {
		Toast.show(text, { ...commonToastOptions, ...options });
	}
};

export const warningToast = text => {
	showToast(
		text,
		{
			borderRadius: 10,
			backgroundColor: theme.palette.orange[100],
			textColor: theme.palette.primary,
			containerStyle: {
				borderRadius: 10,
				margin: 10,
				marginTop: Platform.OS === 'android' && 20

			},
		},
		'warn'
	);
};

export const successToast = text => {
	showToast(
		text,
		{
			backgroundColor: theme.palette.green[200],
			textColor: theme.palette.secondary,
			containerStyle: {
				borderRadius: 10,
				margin: 10,
				marginTop: Platform.OS === 'android' && 20

			},
		},
		'success'
	);
};

export const errorToast = text => {
	showToast(
		text,
		{
			backgroundColor: theme.palette.red[100],
			textColor: theme.palette.secondary,
			containerStyle: {
				borderRadius: 10,
				margin: 10,
				marginTop: Platform.OS === 'android' && 20
			},
		},
		'error'
	);
};
