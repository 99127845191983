import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Pressable,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import Background from "../../components/Background";
import Language from "../../components/Language";
import { ArrowRightSvg, LogoSvg, SadSvg } from "../../components/Svg";
import { useFocusEffect } from "@react-navigation/native";
import { api } from "../../helpers/helper";
import _ from "lodash";
import Skeleton from "../../components/Skeleton";
import { useTranslation } from "../../components/TranslationProvider";
import { setMode } from "../../redux/reducer";
import { errorToast } from "../../components/Toast";
import Disclaimer from "../../components/Disclaimer";

const LabScreen = ({ navigation }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width, height } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("dark"));
    }, [dispatch])
  );
  // useFocusEffect(
  // 	useCallback(() => {
  useEffect(() => {
    setLoading(true);
    api("blood-test/companies")
      .then((res) => {
        if (res) {
          setCompanies(res.result);
        }
      })
      .catch((error) => {
        errorToast(error);
      })
      .finally(() => setLoading(false));
  }, []);
  // );
  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };
  return (
    <Background>
      <View style={styles.languageContainer}>
        <Language isAuth={false} />
      </View>
      <View style={styles.logoContainer}>
        <LogoSvg
          width={isAndroid || isIos ? "173" : width < 360 ? "80%" : "50%"}
          height={isAndroid || isIos ? "30" : width < 360 ? "15%" : "10%"}
        />
        <Text style={styles.title}>
          {getStaticTranslation("lab.lab", "A.I. Laboratory")}
        </Text>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollContent}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <Text style={styles.desc}>
            {getStaticTranslation(
              "lab.lab desc",
              "MEDIVERSE A.I. Laboratory is a researched medical Artificial Intelligence for the purpose of medical diagnosis with collaboration with various popular medical laboratory company in Malaysia."
            )}
          </Text>
        </ScrollView>
      </View>
      <View style={styles.startContainer}>
        <View style={styles.formContainer}>
          <ScrollView
            style={styles.scrollView2}
            contentContainerStyle={styles.scrollContent2}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            <View style={styles.disclaimerContainer}>
              <Disclaimer />
            </View>

            {loading ? (
              <View style={styles.loadingContainer} onLayout={handleLayout}>
                <Skeleton
                  height={160}
                  width={containerWidth}
                  borderRadius={12}
                />
                <Skeleton
                  height={160}
                  width={containerWidth}
                  borderRadius={12}
                />
                <Skeleton
                  height={160}
                  width={containerWidth}
                  borderRadius={12}
                />
              </View>
            ) : (
              <>
                {_.isEmpty(companies) && !loading && (
                  <View style={styles.noDataContainer}>
                    <View style={styles.icon}>
                      <SadSvg width={102} height={100} />
                    </View>
                    <Text style={styles.subTitle}>
                      {getStaticTranslation(
                        "lab.no lab",
                        "There are no laboratories available at the moment."
                      )}
                    </Text>
                    <Text style={styles.noDataDesc}>
                      {getStaticTranslation(
                        "lab.no lab desc",
                        "Please reload this page or try again later."
                      )}
                    </Text>
                  </View>
                )}

                {!loading &&
                  _.map(companies, (company, index) => {
                    return (
                      <Pressable
                        style={styles.card}
                        key={index}
                        onPress={() =>
                          navigation.navigate("LabNavigator", {
                            screen: "Upload",
                            params: { data: company },
                          })
                        }
                      >
                        <ImageBackground
                          style={styles.companyLogoContainer}
                          // width={'100%'}
                          // height={'100%'}
                          source={{ uri: company.logoUrl || "data:image/png" }}
                          resizeMode={"contain"}
                        >
                          <View style={styles.iconContainer}>
                            <ArrowRightSvg color={theme.palette.grey[700]} />
                          </View>
                        </ImageBackground>
                        <View style={styles.textContainer}>
                          <Text style={styles.labName}>{company.name}</Text>
                        </View>
                      </Pressable>
                    );
                  })}
              </>
            )}
          </ScrollView>
        </View>
      </View>
    </Background>
  );
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingTop: isAndroid || isIos ? 10 : 20,
      paddingRight: isAndroid || isIos ? 15 : 30,
      width: "100%",
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: height < 570 ? 0 : 20,
    },
    title: {
      color: theme.palette.secondary,
      fontSize: fontSize[28],
      textAlign: align.center,
      fontFamily: fontFamily.poppins[700],
      marginBottom: isWeb ? 10 : 0,
    },
    scrollView: {
      width: "100%",
    },
    scrollContent: {
      alignItems: "flex-start",
      paddingHorizontal: isWeb ? 30 : 20,
    },
    desc: {
      color: `${theme.palette.secondary}80`,
      fontSize: fontSize[14],
      textAlign: align.justify,
      fontFamily: fontFamily.poppins[300],
    },
    startContainer: {
      flex: 1,
      width: "100%",
      backgroundColor: theme.palette.lightGreen[100],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: 25,
      borderTopLeftRadius: 25,
      paddingVertical: isAndroid || isIos ? 20 : width < 360 ? "5%" : "5%",
    },
    formContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: isAndroid || isIos ? "30%" : "auto",
      gap: 10,
    },
    scrollView2: {
      flex: 1,
      width: "100%",
    },
    scrollContent2: {
      alignItems: "flex-start",

      gap: width < 360 ? 10 : 20,
      paddingHorizontal: isWeb ? 30 : 20,
    },
    loadingContainer: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: 10,
    },
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 12,
      backgroundColor: theme.palette.secondary,
      width: "100%",
      // height: "100%",
      minHeight: 160,
    },
    companyLogoContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    textContainer: {
      paddingVertical: 10,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.green[200],
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12,
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      paddingRight: 20,
    },
    labName: {
      color: theme.palette.secondary,
      fontSize: fontSize[14],
      textAlign: align.justify,
      fontFamily: fontFamily.poppins[600],
    },
    noDataContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingHorizontal: isWeb ? "5%" : "2%",
      paddingVertical: isWeb ? "10%" : "5%",
      width: "100%",
    },
    noDataTitle: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[28],
      color: theme.palette.lightGreen[400],
    },
    icon: {
      paddingBottom: 20,
    },
    subTitle: {
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[20],
      color: theme.palette.grey[300],
      textAlign: align.center,
    },
    noDataDesc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[18],
      color: theme.palette.grey[300],
      textAlign: align.center,
    },
    disclaimerContainer: {
      flex: 1,
      width: "100%",
    },
  });
};

export default LabScreen;
