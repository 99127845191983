import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	Pressable,
	StyleSheet,
} from 'react-native';
import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { setMode } from '../redux/reducer';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { ArrowLeftSvg } from './Svg';
import { useTranslation } from './TranslationProvider';

const ProfileHeader = ({ step, isHome }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const data = [
		{
			step: 1,
			title: getStaticTranslation('profile.user profile', 'User Profile'),
		},
		{
			step: 2,
			title: getStaticTranslation('profile.org', 'Organization Details'),
		},
		{
			step: 3,
			title: getStaticTranslation('profile.health', 'Health Details'),
		},
		{
			step: 4,
			title: getStaticTranslation('profile.password', 'Change Password'),
		},
		{
			step: 5,
			title: getStaticTranslation('profile.tnc', 'Terms and Conditions'),
		},
		{
			step: 6,
			title: getStaticTranslation(
				'profile.medical history',
				'Medical Case History'
			),
		},
		{
			step: 7,
			title: getStaticTranslation(
				'profile.family history',
				'Family Medical History'
			),
		},
	];
	const currentStepData = data.find(d => d.step === step);
	useEffect(() => {
		dispatch(setMode('light'));
	}, [step]);

	return (
		<View style={styles.header}>
			<Pressable
				onPress={() => {
					if (step < 6) {
						dispatch(setMode('dark'));
						navigation.navigate('Profile');
					} else {
						if (isHome) {
							dispatch(setMode('dark'));
							navigation.navigate('HomeTabs', { screen: 'Home' });
						} else {
							dispatch(setMode('light'));
							navigation.navigate('ProfileNavigator', {
								screen: 'EditIllness',
							});
						}
					}
				}}
			>
				<ArrowLeftSvg color={theme.palette.green[700]} width={12} height={19} />
			</Pressable>
			<Text style={styles.title}>{currentStepData.title}</Text>
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		header: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		title: {
			flex: 1,
			textAlign: align.center,
			color: theme.palette.black[100],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingLeft: 5,
		},
	});
};

export default ProfileHeader;
