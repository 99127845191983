import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const appSlice = createSlice({
	name: 'main',
	initialState: {
		isAuthenticated: false,
		user: null,
		language: 'en',
		isTnC: false,
		mode: 'dark',
		checker: null,
		side: 'front',
		userActivity: null,
	},
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		},

		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setIsTnC: (state, action) => {
			state.isTnC = action.payload;
		},
		setMode: (state, action) => {
			state.mode = action.payload;
		},
		setChecker: (state, action) => {
			if (action.payload === null) {
				state.checker = null;
			} else {
				const newState = {
					...state.checker,
					...action.payload,
				};

				Object.keys(newState).forEach(key => {
					if (newState[key] === null || newState[key] === ''){
						delete newState[key];
					}
				});

				state.checker = newState;
			}
		},
		setSide: (state, action) => {
			state.side = action.payload;
		},
		setUserActivity: (state, action) => {
			state.userActivity = action.payload;
		},
		logout: (state, action) => {
			state.isAuthenticated = false;
			state.user = null;
			state.isTnC = false;
			state.mode = 'dark';
			state.checker = null;
			state.userActivity = null;
		},
	},
});

export const {
	setUser,
	setIsAuthenticated,
	setLanguage,
	setIsTnC,
	setMode,
	setChecker,
	setSide,
	setUserActivity,
	logout,
} = appSlice.actions;
export default appSlice.reducer;
