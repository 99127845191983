import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  StyleSheet,
  Pressable,
} from "react-native";
import React, { useCallback, useState } from "react";
import StepBar from "../../components/StepBar";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import { ManSvg, WomenSvg } from "../../components/Svg";
import LightBackground from "../../components/LightBackground";
import { Slider } from "@react-native-assets/slider";
import { useDispatch, useSelector } from "react-redux";
import { setChecker, setMode, setSide } from "../../redux/reducer";
import { useTranslation } from "../../components/TranslationProvider";
import { useFocusEffect } from "@react-navigation/native";

const PersonalDetailsScreen = ({ navigation }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width, height } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const { checker } = useSelector((state) => state.app);
  const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
  const [isSliding, setIsSliding] = useState(false);
  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("light"));
    }, [dispatch])
  );
  const resetChecker = () => {
    dispatch(setChecker({ symptom: null }));
    dispatch(setChecker({ severity: null }));
    dispatch(setChecker({ painScore: null }));
    dispatch(setChecker({ presentations: null }));
    dispatch(setChecker({ condition: null }));
    dispatch(setSide("front"));
  };

  const onPress = (gender) => {
    const { gender: currentGender, symptom } = checker || {};

    if (
      (currentGender === "male" &&
        symptom?.area === "maleReproductiveOrgan" &&
        gender === "female") ||
      (currentGender === "female" &&
        symptom?.area === "femaleReproductiveOrgan" &&
        gender === "male") ||
      (currentGender === "female" &&
        symptom?.area === "chest" &&
        symptom?._id === "63641de51669e6e9ad4506f3" &&
        gender === "male")
    ) {
      resetChecker();
    }

    dispatch(setChecker({ gender: gender }));
  };

  return (
    <LightBackground>
      <View style={styles.container}>
        <StepBar step={1} />
        <Text style={styles.desc}>
          {getStaticTranslation(
            "checker.desc personal",
            "For a customized experience, gender and age is required for a personalized touch."
          )}
        </Text>

        <View style={styles.questionContainer}>
          <Text style={styles.question}>
            {" "}
            {getStaticTranslation(
              "checker.question personal",
              "What is your gender?"
            )}
          </Text>
          <View style={styles.genderContainer}>
            <View style={styles.genderTextContainer}>
              <Pressable
                style={[
                  styles.gender,
                  {
                    backgroundColor:
                      checker?.gender === "male"
                        ? theme.palette.blue[200]
                        : theme.palette.secondary,
                  },
                ]}
                disabled={isSliding} // Disable button when sliding
                onPress={() => onPress("male")}
              >
                <ManSvg
                  color={
                    checker?.gender === "male"
                      ? theme.palette.secondary
                      : theme.palette.blue[200]
                  }
                  width={height < 670 ? 40 : 66}
                  height={height < 670 ? 30 : 92}
                />
              </Pressable>
              <Text style={styles.genderText}>
                {" "}
                {getStaticTranslation("checker.male", "Male")}
              </Text>
            </View>
            <View style={styles.genderTextContainer}>
              <Pressable
                style={[
                  styles.gender,
                  {
                    backgroundColor:
                      checker?.gender === "female"
                        ? theme.palette.lightRed[300]
                        : theme.palette.secondary,
                  },
                ]}
                disabled={isSliding} // Disable button when sliding
                onPress={() => onPress("female")}
              >
                <WomenSvg
                  color={
                    checker?.gender === "female"
                      ? theme.palette.secondary
                      : theme.palette.lightRed[300]
                  }
                  width={height < 670 ? 40 : 66}
                  height={height < 670 ? 30 : 92}
                />
              </Pressable>
              <Text style={styles.genderText}>
                {" "}
                {getStaticTranslation("checker.female", "Female")}
              </Text>
            </View>
          </View>
          <Text style={styles.question}>
            {getStaticTranslation("checker.question age", "How old are you?")}
          </Text>
          <Text style={styles.age}>{checker?.age || 0}</Text>
          <Text style={styles.ageLabel}>
            {getStaticTranslation("checker.years old", "Years old")}
          </Text>
          <Slider
            style={styles.slider}
            minimumValue={0}
            maximumValue={100}
            step={1}
            value={checker?.age || 0}
            onValueChange={(value) => {
              dispatch(setChecker({ age: value }));
            }}
            onSlidingStart={() => setIsSliding(true)} // Set sliding state
            onSlidingComplete={() => setIsSliding(false)} // Reset sliding state
            minimumTrackTintColor={theme.palette.green[200]}
            maximumTrackTintColor={theme.palette.grey[300]}
            thumbTintColor={theme.palette.green[700]}
            trackStyle={{ height: height < 570 ? 5 : 10, borderRadius: 5 }}
            thumbStyle={{
              height: height < 570 ? 16 : 32,
              width: height < 570 ? 16 : 32,
              borderRadius: 20,
            }}
          />
        </View>

        <View style={styles.buttonContainer}>
          <Pressable
            disabled={!checker?.age || checker?.age === 0 || !checker?.gender}
            style={[
              styles.button,
              {
                backgroundColor:
                  !checker?.age || checker?.age === 0 || !checker?.gender
                    ? theme.palette.grey[200]
                    : theme.palette.green[200],
              },
            ]}
            onPress={() => navigation.navigate("Medical")}
          >
            <Text
              style={[
                styles.buttonText,
                {
                  color:
                    !checker?.age || checker?.age === 0 || !checker?.gender
                      ? theme.palette.grey[600]
                      : theme.palette.green[700],
                },
              ]}
            >
              {getStaticTranslation("checker.next", "Next")}
            </Text>
          </Pressable>
        </View>
      </View>
    </LightBackground>
  );
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingVertical: 3,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
    desc: {
      color: theme.palette.black[100],
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[400],
      paddingVertical: isWeb ? (height < 670 ? 0 : 10) : 0,
      textAlign: align.justify,
    },
    questionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      marginTop: height < 570 ? 0 : 10,
      alignItems: "center",
    },
    question: {
      color: theme.palette.green[700],
      fontSize: fontSize[20],
      fontFamily: fontFamily.poppins[700],
      paddingBottom: height < 670 ? "1%" : 10,
      textAlign: align.center,
    },
    genderContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: height < 670 ? "1%" : 10,
    },
    gender: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
      paddingVertical: "15%",
      width: "100%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    genderTextContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "47%",
    },
    genderText: {
      color: theme.palette.green[700],
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
      paddingVertical: height < 570 ? "1%" : 10,
    },
    age: {
      fontSize: fontSize[80],
      fontFamily: fontFamily.poppins[600],
      color: theme.palette.green[200],
      lineHeight: height < 570 ? 50 : 90,
    },
    ageLabel: {
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
      color: theme.palette.black[100],
      marginBottom: height < 670 ? 0 : 20,
      lineHeight: 18,
    },
    slider: {
      width: "80%",
      height: 40,
      touchAction: "none",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      width: "80%",
      marginTop: height < 570 ? "1%" : "3%",
      backgroundColor: theme.palette.green[200],
      borderRadius: 10,
      padding: 10,
      paddingHorizontal: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: height < 570 ? "1%" : "5%",
    },
    buttonText: {
      color: theme.palette.green[700],
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[18],
    },
  });
};
export default PersonalDetailsScreen;
