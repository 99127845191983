import React from 'react';
import OverviewScreen from '../screens/Home/OverviewScreen';
import HistoryScreen from '../screens/Home/HistoryScreen';
import AnnouncementScreen from '../screens/Home/AnnouncementScreen';
import TabBar from '../components/TabBar';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useTranslation } from '../components/TranslationProvider';

const Overview = () => {
	const Tab = createMaterialTopTabNavigator();
	const { getStaticTranslation } = useTranslation();
	return (
		<Tab.Navigator
			initialRouteName='Overview'
			tabBar={props => <TabBar {...props} />}
		>
			<Tab.Screen
				name='Overview'
				component={OverviewScreen}
				options={{
					tabBarLabel: getStaticTranslation('home.overview', 'Overview'),
				}}
			/>
			<Tab.Screen
				name='History'
				component={HistoryScreen}
				options={{
					tabBarLabel: getStaticTranslation('home.history', 'History'),
				}}
			/>
			<Tab.Screen
				name='Announcement'
				component={AnnouncementScreen}
				options={{
					tabBarLabel: getStaticTranslation(
						'home.announcement',
						'Announcement'
					),
				}}
			/>
		</Tab.Navigator>
	);
};

export default Overview;
