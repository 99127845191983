import React, { useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Platform,
	useWindowDimensions,
	Pressable,
} from 'react-native';
import { ArrowDownSvg, ArrowUpSvg } from './Svg';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';

const LabResultQnA = ({ question, answer }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const [expanded, setExpanded] = useState(false);

	return (
		<View style={styles.container}>
			<Pressable style={styles.header} onPress={() => setExpanded(!expanded)}>
				<Text style={styles.headerText}>{question}</Text>
				{expanded ? (
					<ArrowUpSvg color={theme.palette.secondary} />
				) : (
					<ArrowDownSvg color={theme.palette.secondary} />
				)}
			</Pressable>
			{expanded && (
				<View style={styles.body}>
					<Text style={styles.bodyText}>{answer}</Text>
				</View>
			)}
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			borderRadius: 10,
			backgroundColor: theme.palette.secondary,
			elevation: 1,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 2 },
			shadowOpacity: 0.25,
			shadowRadius: 1,
		},
		header: {
			backgroundColor: theme.palette.green[200],
			paddingVertical: 10,
			paddingHorizontal: 15,
			borderRadius: 10,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		headerText: {
			color: theme.palette.secondary,
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[600],
			width: '90%',
		},
		body: {
			padding: 10,
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
		},
		bodyText: {
			color: theme.palette.black[100],
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[400],
			textAlign: isAndroid || isIos ? 'left' : 'justify',
			// padding: 15,
		},
	});
};

export default LabResultQnA;
