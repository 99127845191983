import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UploadScreen from '../screens/Lab/UploadScreen';
import LabResultDetailScreen from '../screens/Lab/LabResultDetailScreen';
import LabResultScreen from '../screens/Lab/LabResultScreen';
import { theme } from '../theme';

const Stack = createStackNavigator();
const LabNavigator = () => {
	return (
		<Stack.Navigator
			initialRouteName='Upload'
			screenOptions={{
				gestureEnabled: false,
				headerShown: false,
				cardStyle: {
					backgroundColor: theme.palette.lightGreen[100],
				},
			}}
		>
			<Stack.Screen name='Upload' component={UploadScreen} />
			<Stack.Screen name='LabResult' component={LabResultScreen} />
			<Stack.Screen name='LabResultDetail' component={LabResultDetailScreen} />
		</Stack.Navigator>
	);
};

export default LabNavigator;
