import { View, Text } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import LightBackground from '../../components/LightBackground';
import CheckerHeader from '../../components/CheckerHeader';
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import _ from 'lodash';
import { ScrollView } from 'react-native';
import { Pressable } from 'react-native';
import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { setMode } from '../../redux/reducer';

const RedFlagScreen = ({ navigation, route }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { checker } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const [condition, setCondition] = useState();
	const [conditions, setConditions] = useState();
	const [resultId, setResultId] = useState();
	const [symptoms, setSymptoms] = useState([]);
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [isReport, setIsReport] = useState(false);
	const dispatch = useDispatch()
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		if (route.params?.data) {
			setConditions(route.params.data.conditions);
			setCondition(route.params.data.condition);
			setIsReport(route.params.data.isReport);
			setResultId(route.params.data.resultId);

			const lines = route.params.data.isReport
				? getTranslation(
					route.params.data.checker?.symptom,
					'redFlagDescription',
					route.params.data.checker?.symptom?.redFlagDescription
				)
				: getTranslation(
					checker?.symptom,
					'redFlagDescription',
					checker?.symptom?.redFlagDescription
				);

			const line = lines.split('\n');
			const data = line
				.filter(line => line.match(/^\d+\./))
				.map(line => line.replace(/^\d+\.\s*/, ''));
			setSymptoms(data);
			setLoading(false);
		}
	}, [route]);

	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width - 25);
	};
	return (
		<LightBackground>
			<View style={styles.container} onLayout={handleLayout}>
				<CheckerHeader
					step={2}
					conditions={conditions}
					condition={condition}
					resultId={resultId}
					isReport={isReport}
					checker={isReport ? route.params?.data.checker : checker}
				/>
				{loading ? (
					<View style={styles.loadingContainer}>
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton
							height={40}
							width={containerWidth / 2}
							borderRadius={12}
						/>
						<Skeleton
							height={50}
							width={containerWidth / 2}
							borderRadius={12}
						/>
					</View>
				) : (
					<>
						<Text style={styles.question}>
							{getStaticTranslation(
								'checker.desc redflag',
								'Select ‘Yes’ if you are experiencing any of the mentioned symptoms and vice versa.'
							)}
						</Text>
						<View
							style={[
								styles.evidenceContainer,
								{
									backgroundColor:
										condition?.evidence === 'weak'
											? theme.palette.green[400]
											: condition?.evidence === 'strong'
												? theme.palette.red[300]
												: theme.palette.orange[200],
								},
							]}
						>
							<Text style={styles.evidence}>
								{_.upperCase(
									`${getStaticTranslation(
										`checker.${condition?.evidence}`,
										condition?.evidence
									)}${getStaticTranslation('checker.evidence', 'Evidence')}`
								)}
							</Text>
						</View>
						<Text style={styles.name}>
							{_.startCase(getTranslation(condition, 'name', condition?.name))}
						</Text>
					</>
				)}
			</View>
			<View style={styles.questionContainer}>
				{loading ? (
					<View style={styles.loadingContainer}>
						<Skeleton height={80} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<Skeleton height={40} width={containerWidth} borderRadius={12} />
						<View style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
							<Skeleton
								height={50}
								width={containerWidth / 2 - 15}
								borderRadius={12}
							/>
							<Skeleton
								height={50}
								width={containerWidth / 2 - 15}
								borderRadius={12}
							/>
						</View>
					</View>
				) : (
					<>
						<Text style={styles.subQuestion}>
							{getStaticTranslation(
								'checker.question redFlag',
								'Do you experience any of these symptoms mentioned below?'
							)}
						</Text>
						<ScrollView
							style={styles.scrollContainer}
							contentContainerStyle={styles.scrollContent}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
						>
							{symptoms.map((symptom, index) => (
								<View key={index} style={styles.symptomItem}>
									<View style={styles.icon}>
										<Text style={styles.iconText}>{index + 1}</Text>
									</View>
									<Text style={styles.symptomText}>{symptom}</Text>
								</View>
							))}
						</ScrollView>
						<View style={styles.buttonContainer}>
							<Pressable
								style={styles.yesButton}
								onPress={() => {
									navigation.navigate('Education', {
										data: {
											condition: condition,
											conditions: conditions,
											resultId: resultId,
											isYes: true,
											isReport: isReport,
											checker: isReport ? route.params?.data.checker : checker,
										},
									});
								}}
							>
								<Text style={styles.buttonText}>
									{getStaticTranslation('checker.yes', 'Yes')}
								</Text>
							</Pressable>
							<Pressable
								style={styles.noButton}
								onPress={() => {
									navigation.navigate('Education', {
										data: {
											condition: condition,
											conditions: conditions,
											resultId: resultId,
											isYes: false,
											isReport: isReport,
											checker: isReport ? route.params?.data.checker : checker,
										},
									});
								}}
							>
								<Text style={styles.buttonText}>
									{getStaticTranslation('checker.no', 'No')}
								</Text>
							</Pressable>
						</View>
					</>
				)}
			</View>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingTop: 10,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'center',
			gap: 10,
		},
		question: {
			color: theme.palette.black[100],
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			paddingVertical: 10,
			paddingBottom: 20,
			textAlign: align.justify,
		},
		evidenceContainer: {
			alignItems: 'center',
			justifyContent: 'center',
			padding: 5,
			paddingHorizontal: 20,
			borderRadius: 20,
		},
		evidence: {
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[500],
			color: theme.palette.secondary,
		},
		name: {
			color: theme.palette.green[700],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingTop: 10,
			textAlign: align.justify,
		},
		questionContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			marginTop: 10,
			alignItems: 'center',
			width: '100%',
			backgroundColor: theme.palette.secondary,
			borderTopLeftRadius: 10,
			borderTopRightRadius: 10,
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			width: '100%',
			padding: 15,
		},
		subQuestion: {
			color: theme.palette.green[700],
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
			paddingBottom: 10,
			textAlign: align.center,
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
			paddingHorizontal: 5,
		},
		scrollContent: {
			flexGrow: 1,
			height: isWeb ? height * 0.3 : 'auto',
		},
		symptomItem: {
			flexDirection: 'row',
			gap: 10,
			marginBottom: 10,
		},
		icon: {
			borderRadius: 5,
			backgroundColor: theme.palette.green[100],
			justifyContent: 'center',
			alignItems: 'center',
			paddingHorizontal: 10,
			paddingVertical: 2,
			width: 30,
			height: 30,
		},
		iconText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
			paddingTop: 2,
		},
		symptomText: {
			flex: 1,
			color: theme.palette.black[100],
			textAlign: align.justify,
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[16],
		},
		buttonContainer: {
			flexDirection: 'row',
			justifyContent: 'space-around',
			width: '100%',
			marginTop: 20,
			marginBottom: 20,
		},
		yesButton: {
			backgroundColor: theme.palette.lightGreen[500],
			paddingVertical: 15,
			paddingHorizontal: 20,
			borderRadius: 10,
			alignItems: 'center',
			flex: 1,
			marginHorizontal: 10,
		},
		noButton: {
			backgroundColor: theme.palette.lightRed[200],
			paddingVertical: 15,
			paddingHorizontal: 20,
			borderRadius: 10,
			alignItems: 'center',
			flex: 1,
			marginHorizontal: 10,
		},
		buttonText: {
			color: theme.palette.secondary,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
	});
};
export default RedFlagScreen;
