import {
    View,
    Text,
    Platform,
    useWindowDimensions,
    StyleSheet,
    ImageBackground,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { G, Path, Svg } from 'react-native-svg';
import _ from 'lodash';
import { api } from '../helpers/helper';
import { theme } from '../theme';
import Loading from './Loading';
import { errorToast } from './Toast';

const Body = ({ area, setArea }) => {
    const isWeb = Platform.OS === 'web';
    const isAndroid = Platform.OS === 'android';
    const isIos = Platform.OS === 'ios';
    const { width } = useWindowDimensions();
    const { checker, side } = useSelector(state => state.app);
    const styles = cssStyles(isWeb, isAndroid, isIos, width);
    const [svgData, setSvgData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        if (checker?.gender) {
            api(`svg/${checker?.gender}/${side}`)
                .then(res => {
                    setSvgData(res.result);
                })
                .catch((error) => {
                    errorToast(error.message)
                })
                .finally(() => setLoading(false));
        }
    }, [checker?.gender, side]);
    const bodyImages = {
        'male-front': require('../../assets/male-body.png'),
        'male-back': require('../../assets/male-body-back.png'),
        'female-front': require('../../assets/female-body.png'),
        'female-back': require('../../assets/female-body-back.png'),
    };
    const parts = [
        {
            id: 'general',
        },
        {
            id: 'head',
        },
        {
            id: 'neck',
        },
        {
            id: 'chest',
        },
        {
            id: 'arm',
        },
        {
            id: 'wristAndHand',
        },
        {
            id: 'upperAbdomen',
        },
        {
            id: 'middleAbdomen',
        },
        {
            id: 'lowerAbdomen',
        },
        {
            id:
                checker?.gender === 'male'
                    ? 'maleReproductiveOrgan'
                    : 'femaleReproductiveOrgan',
        },
        {
            id: 'hip',
        },
        {
            id: 'lowerLimb',
        },
        {
            id: 'knee',
        },
        {
            id: 'footAndAnkle',
        },
        {
            id: 'back',
        },
        {
            id: 'buttock',
        },
        {
            id: 'anus',
        },
    ];

    return (
        <>
            {loading ? (
                <View style={styles.loadingContainer}>
                    <Loading />
                </View>
            ) : (
                <ImageBackground
                    source={bodyImages[`${checker?.gender}-${side}`]}
                    style={styles.container}
                    resizeMode='contain'
                >
                    <Svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={'100%'}
                        height={'100%'}
                        viewBox={_.get(svgData, 'viewBox')}
                    >
                        <G fill={`${theme.palette.secondary}00`}>
                            {_.map(parts, ({ id }) => {
                                return (
                                    <G
                                        key={id}
                                        {...(isWeb
                                            ? {
                                                onClick: () => {
                                                    if (area !== id) {
                                                        setArea(id);
                                                    } else {
                                                        setArea('general');
                                                    }
                                                },
                                            }
                                            : {
                                                onPressIn: () => {
                                                    if (area !== id) {
                                                        setArea(id);
                                                    } else {
                                                        setArea('general');
                                                    }
                                                },
                                            })}
                                    >
                                        {_.map(_.get(svgData, `data.${id}`), (props, index) => {
                                            if (id === area) {
                                                props.fill = `${theme.palette.green[200]}50`;
                                                props.stroke = theme.palette.secondary;
                                                props.strokeLinecap = 'round';
                                            } else {
                                                props.stroke = theme.palette.secondary;
                                                props.fill = 'transparent';
                                                props.strokeLinecap = 'round';
                                            }
                                            return (
                                                <Path
                                                    strokeWidth={3}
                                                    key={`${id}-${index}`}
                                                    {...props}
                                                />
                                            );
                                        })}
                                    </G>
                                );
                            })}
                        </G>
                    </Svg>
                </ImageBackground>
            )}
        </>
    );
};
const cssStyles = (isWeb, isAndroid, isIos, width) => {
    return StyleSheet.create({
        container: {
            display: 'flex',
            width: '100%',
            height: '100%',
            resizeMode: 'contain',
        },
        loadingContainer: {
            flex: 1,
            display: 'flex',
        },
    });
};
export default Body;
