import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	Modal,
	Pressable,
	StyleSheet,
} from 'react-native';
import React from 'react';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setChecker, setMode, setUserActivity } from '../redux/reducer';
import { useNavigation } from '@react-navigation/native';
import { useTimer } from './TimerProvider';
import { api } from '../helpers/helper';
import { useTranslation } from './TranslationProvider';
import { errorToast, successToast } from './Toast';
import { deleteUser, signOut } from 'firebase/auth';
import { auth } from '../config/firebase';

const DeleteAccountModal = ({ visible, onClose }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const { getStaticTranslation } = useTranslation();
	const { language, user } = useSelector(state => state.app);
	const handleConfirm = async () => {
		try {
				await api(`users/delete`, 'delete', {
					uid: user.uid,
				})
					.then(async () => {
						await signOut(auth);
						onClose();
						successToast(
							getStaticTranslation(
								'profile.delete success',
								'Your account have been successfuly deleted.'
							)
						);
					})
					.catch(error => {
						errorToast(error.message);
					});
		} catch (error) {
			errorToast(error.message);
		}
	};
	return (
		<Modal
			visible={visible}
			animationType='slide'
			transparent={true}
			onRequestClose={onClose}
		>
			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
				<Pressable
					style={[styles.modalOverlay, isWeb && { outlineStyle: 'none' }]}
					onPress={onClose}
				>
					<View style={styles.modalContainer}>
						<Text style={styles.title}>
							{getStaticTranslation(
								'profile.confirm delete',
								'Are you sure delete this account?'
							)}
						</Text>
						<Text style={styles.warningText}>
							{getStaticTranslation(
								'profile.delete warning',
								'This step cannot be undo and all your data will be lost'
							)}
						</Text>
						<Pressable style={styles.confirmButton} onPress={handleConfirm}>
							<Text style={styles.confirmButtonText}>
								{getStaticTranslation('profile.confirm', 'Confirm')}
							</Text>
						</Pressable>
						<Pressable style={styles.cancelButton} onPress={onClose}>
							<Text style={styles.cancelButtonText}>
								{getStaticTranslation('profile.cancel delete', 'Cancel')}
							</Text>
						</Pressable>
					</View>
				</Pressable>
			</View>
		</Modal>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align = getAlign();
	return StyleSheet.create({
		modalOverlay: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: `${theme.palette.primary}50`,
			width: '100%',
			maxWidth: isWeb ? 500 : width,
		},
		modalContainer: {
			width: '85%',
			maxWidth: isWeb ? 500 : width,
			padding: 20,
			backgroundColor: theme.palette.lightGreen[100],
			borderRadius: 10,
			alignItems: 'center',
			shadowColor: theme.palette.primary,
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			elevation: 5,
			paddingVertical: 30,
		},
		title: {
			fontSize: fontSize[22],
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.black[100],
			textAlign: align.center,
		},
		warningText: {
			fontSize: fontSize[16],
			textAlign: align.center,
			marginBottom: 20,
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.black[100],
		},
		span: {
			fontSize: fontSize[16],
			textAlign: align.center,
			marginBottom: 20,
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
		},
		confirmButton: {
			width: '100%',
			padding: 15,
			borderRadius: 10,
			backgroundColor: theme.palette.green[700],

			alignItems: 'center',
			marginBottom: 10,
		},
		confirmButtonText: {
			color: theme.palette.secondary,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
		cancelButton: {
			width: '100%',
			padding: 15,
			borderRadius: 10,
			backgroundColor: theme.palette.lightGreen[300],
			alignItems: 'center',
		},
		cancelButtonText: {
			color: theme.palette.green[700],
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
	});
};
export default DeleteAccountModal;
