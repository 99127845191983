import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import EditProfileScreen from '../screens/Profile/EditProfileScreen';
import EditIllnessScreen from '../screens/Profile/EditIllnessScreen';
import EditPasswordScreen from '../screens/Profile/EditPasswordScreen';
import TnCScreen from '../screens/Profile/TnCScreen';
import { theme } from '../theme';
import OrganizationScreen from '../screens/Profile/OrganizationScreen';
import MedicalCaseScreen from '../screens/Profile/MedicalCaseScreen';
import FamilyMedicalScreen from '../screens/Profile/FamilyMedicalScreen';

const Stack = createStackNavigator();
const ProfileNavigator = () => {
	return (
		<>
			<Stack.Navigator
				initialRouteName='EditProfile'
				screenOptions={{
					gestureEnabled: false,
					headerShown: false,
					cardStyle: {
						backgroundColor: theme.palette.lightGreen[100],
					},
				}}
			>
				<Stack.Screen name='EditProfile' component={EditProfileScreen} />
				<Stack.Screen name='Organization' component={OrganizationScreen} />
				<Stack.Screen name='EditIllness' component={EditIllnessScreen} />
				<Stack.Screen name='MedicalCase' component={MedicalCaseScreen} />
				<Stack.Screen name='FamilyMedical' component={FamilyMedicalScreen} />
				<Stack.Screen name='EditPassword' component={EditPasswordScreen} />
				<Stack.Screen name='TnC' component={TnCScreen} />
			</Stack.Navigator>
		</>
	);
};

export default ProfileNavigator;
