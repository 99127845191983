import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	ImageBackground,
	ScrollView,
	Pressable,
	Linking,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import LightBackground from '../../components/LightBackground';
import ProfileHeader from '../../components/ProfileHeader';
import { CompanySvg, ProfileBgSvg, SadSvg } from '../../components/Svg';
import { errorToast } from '../../components/Toast';
import { Country, State } from 'country-state-city';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { setMode } from '../../redux/reducer';
import { isDev, isTest } from '../../helpers/helper';
const OrganizationScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.app);
	const [org, setOrg] = useState();
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		if (user?.organization) {
			setOrg(user.organization);
		}
	}, [user?.organization]);
	const handleLogin = async () => {
		const url =
			isDev
				? process.env.EXPO_PUBLIC_DEV_ADMIN_URI
				: isTest? process.env.EXPO_PUBLIC_TEST_ADMIN_URI
				: process.env.EXPO_PUBLIC_ADMIN_URI;
		if (await Linking.canOpenURL(url)) {
			try {
				await Linking.openURL(url);
			} catch (error) {
				errorToast('An error occurred', error);
			}
		} else {
			errorToast(
				getStaticTranslation(
					'profile.error open',
					"Don't know how to open URI:"
				) + url
			);
		}
	};

	const getState = (state, country) => {
		if (state && country) {
			const stateCode = State.getStateByCodeAndCountry(state, country);
			return stateCode?.name;
		}
		return '';
	};

	const getCountry = country => {
		if (country) {
			const countryCode = Country.getCountryByCode(country);
			return countryCode?.name;
		}
		return '';
	};
	return (
		<LightBackground>
			<View style={styles.container}>
				<ProfileHeader step={2} />
			</View>
			<View style={styles.header}>
				<ProfileBgSvg width={width} height={height / 4.4} />
				<View style={styles.profilePic}>
					{org?._organizationId?.logo ? (
						<ImageBackground
							source={{ uri: org?._organizationId?.logo || "data:image/png"  }}
							style={styles.profileImage}
							resizeMode='cover'
							imageStyle={{ borderRadius: !isWeb ? 100 : '100%' }}
							borderRadius={!isWeb ? 100 : '100%'}
						></ImageBackground>
					) : (
						<View style={styles.noImage}>
							<CompanySvg />
						</View>
					)}
				</View>
			</View>
			<ScrollView
				style={styles.scrollContainer}
				contentContainerStyle={[
					styles.scrollContent,
					{
						marginBottom: !org ? 20:'auto',
					},
				]}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			>
				<View
					style={[
						styles.testContainer,
						{
							alignItems: org ? 'flex-start' : 'center',
							flex: !org ? 1: 1,
							paddingVertical: org ? '5%' : 0,
							justifyContent: org ? 'flex-start' : 'center',
						},
					]}
				>
					{org ? (
						<>
							<View style={styles.itemContainer}>
								<View style={styles.diagnosisContainer}>
									<Text style={styles.diagnosisTitle}>
										{getStaticTranslation(
											'profile.company name',
											'Company Name'
										)}
									</Text>
								</View>
								<Text style={styles.diagnosisDescription}>
									{org?._organizationId?.name||'-'}
								</Text>

								<View style={styles.separator} />
							</View>
							<View style={styles.itemContainer}>
								<View style={styles.diagnosisContainer}>
									<Text style={styles.diagnosisTitle}>
										{getStaticTranslation(
											'profile.address',
											'Business Address'
										)}
									</Text>
								</View>
								<Text style={styles.diagnosisDescription}>
									{org?._organizationId?.address},{' '}
									{org?._organizationId?.postcode}, {org?._organizationId?.city}
									,{' '}
									{getState(
										org?._organizationId?.state,
										org?._organizationId?.country
									)}
									, {getCountry(org?._organizationId?.country)}
								</Text>

								<View style={styles.separator} />
							</View>
							<View style={styles.itemContainer}>
								<View style={styles.diagnosisContainer}>
									<Text style={styles.diagnosisTitle}>
										{getStaticTranslation('profile.contact', 'Contact Number')}
									</Text>
								</View>
								<Text style={styles.diagnosisDescription}>
									{org?._organizationId?.dialCode}{org?._organizationId?.phone||'-'}
								</Text>

								<View style={styles.separator} />
							</View>
							<View style={styles.itemContainer}>
								<View style={styles.diagnosisContainer}>
									<Text style={styles.diagnosisTitle}>
										{getStaticTranslation('profile.email', 'Email Address')}
									</Text>
								</View>
								<Text style={styles.diagnosisDescription}>
									{org?._organizationId?.email||'-'}
								</Text>

								<View style={styles.separator} />
							</View>
							<View style={styles.itemContainer}>
								<View style={styles.diagnosisContainer}>
									<Text style={styles.diagnosisTitle}>
										{getStaticTranslation('profile.role', 'Role')}
									</Text>
								</View>
								<Text style={styles.diagnosisDescription}>
									{getStaticTranslation(`profile.${org?.role}`, org?.role)}
								</Text>
							</View>
						</>
					) : (
						<>
							<View>
								<SadSvg width={72} height={70} />
							</View>
							<Text style={styles.noDataSubTitle}>
								{getStaticTranslation(
									'profile.not a member',
									'Not a member of organization?'
								)}
							</Text>
							<Text style={styles.noDataDesc}>
								{getStaticTranslation(
									'profile.ask to add',
									"Ask your organization's administrator to add you."
								)}
							</Text>
						</>
					)}
				</View>
			</ScrollView>
			{org && (
				<View style={styles.buttonContainer}>
					<Pressable
						style={[
							styles.button,
							{
								backgroundColor: theme.palette.green[200],
							},
						]}
						onPress={handleLogin}
					>
						<Text
							style={[
								styles.buttonText,
								{
									color: theme.palette.green[700],
								},
							]}
						>
							{getStaticTranslation(
								'profile.login as org',
								'Login as Organization'
							)}
						</Text>
					</Pressable>
				</View>
			)}
		</LightBackground>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			// flex: 1,
			display: 'flex',
			flexDirection: 'column',
			zIndex: 9999
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		profilePic: {
			position: 'absolute',
			width: height < 500 ? 80 : 130,
			height: height < 500 ? 80 : 130,
		},
		profileImage: {
			width: '100%',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		noImage: {
			backgroundColor: theme.palette.grey[200],
			width: '100%',
			height: '100%',
			borderRadius: !isWeb ? 100 : '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			gap: 10,
			padding: 15,
			height: isWeb ? height * 0.5 : 'auto',
			paddingHorizontal: 25,
		},

		testContainer: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
			width: '100%',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: isWeb ? '5%' : '3%',
			paddingVertical: isWeb ? '10%' : '5%',
			gap: 10,
		},
		testTitle: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.black[100],
		},
		itemContainer: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
			flex: 1,
			width: '100%',
			gap: 5,
		},
		diagnosisContainer: {
			backgroundColor: theme.palette.green[600],
			borderRadius: 20,
			paddingHorizontal: 20,
			paddingVertical: 5,
		},
		diagnosisTitle: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[14],
		},
		diagnosisDescription: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			textAlign: align.justify,
			paddingHorizontal: 10,
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 5,
			width: '100%',
		},
		noDataSubTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[300],
		},
		noDataDesc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[14],
			color: theme.palette.grey[300],
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingBottom: 20,
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};

export default OrganizationScreen;
