import React, { useEffect, useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Platform,
	useWindowDimensions,
	ScrollView,
	Pressable,
} from 'react-native';
import { fontFamily, getFontSize, theme } from '../../theme';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { AddSvg, FileAddSvg } from '../../components/Svg';
import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../components/TranslationProvider';

const HistoryScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const { user } = useSelector(state => state.app);
	const [knownIllnesses, setKnownIllnesses] = useState();
	const [familyIllnesses, setFamilyIllnesses] = useState();
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);

	useEffect(() => {
		setLoading(true);
		if (user) {
			if (user.knownIllnesses) {
				setKnownIllnesses(user.knownIllnesses);
			}
			if (user.familyIllnesses) {
				setFamilyIllnesses(user.familyIllnesses);
			}
		}
		setLoading(false);
	}, [user]);
	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width);
	};
	return (
		<ScrollView
			style={styles.container}
			showsVerticalScrollIndicator={false}
			showsHorizontalScrollIndicator={false}
			nestedScrollEnabled={true}
		>
			<Text style={styles.title}>
				{getStaticTranslation('history.medical', 'Medical Case History')}
			</Text>
			{loading ? (
				<View style={styles.loadingContainer} onLayout={handleLayout}>
					<Skeleton height={150} width={containerWidth} borderRadius={12} />
				</View>
			) : knownIllnesses && Object.keys(knownIllnesses).length > 0 ? (
				<View style={styles.illnessContainer}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
						style={styles.innerScroll}
						nestedScrollEnabled={true}
					>
					
						{Object.entries(knownIllnesses).map(([key, illness], index) => (
							<View key={key}>
								<View style={styles.illness}>
									<Text style={styles.illnessText}>
										{getTranslation(illness, 'name', illness.name)}
									</Text>
								</View>
								{index < Object.entries(knownIllnesses).length - 1 && (
									<View style={styles.separator} />
								)}
							</View>
						))}
					</ScrollView>
					<View style={styles.pressableContainer}>
						<Pressable
							style={styles.pressable}
							onPress={() => {
								navigation.navigate('ProfileNavigator', {
									screen: 'MedicalCase',
									params: {
										data: {
											isHome: true,
										},
									},
								});
							}}
						>
							<Text style={styles.pressableText}>
								{getStaticTranslation('history.edit', 'Edit Details')}
							</Text>
						</Pressable>
					</View>
				</View>
			) : (
				<View style={styles.noDataContainar}>
					<View style={styles.noData}>
						<View>
							<FileAddSvg />
						</View>
						<View style={styles.noDataTextContainer}>
							<Text style={styles.subTitle}>
								{getStaticTranslation('history.no data', 'No Data Available')}
							</Text>
							<Text style={styles.desc}>
								{getStaticTranslation(
									'history.no data info',
									'No medical case history added.'
								)}
							</Text>
						</View>
					</View>
					<View style={styles.pressableContainer}>
						<Pressable
							style={styles.iconPressable}
							onPress={() => {
								navigation.navigate('ProfileNavigator', {
									screen: 'MedicalCase',
									params: {
										data: {
											isHome: true,
										},
									},
								});
							}}
						>
							<AddSvg
								color1={theme.palette.secondary}
								color2={theme.palette.green[700]}
								width={16}
								height={16}
							/>
							<Text style={styles.pressableText}>
								{getStaticTranslation('history.add', 'Add Condition')}
							</Text>
						</Pressable>
					</View>
				</View>
			)}

			<Text style={styles.title}>
				{getStaticTranslation('history.family', 'Family History')}
			</Text>
			{loading ? (
				<View style={styles.loadingContainer} onLayout={handleLayout}>
					<Skeleton height={150} width={containerWidth} borderRadius={12} />
				</View>
			) : familyIllnesses && Object.keys(familyIllnesses).length > 0 ? (
				<View style={styles.illnessContainer}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
						style={styles.innerScroll}
						nestedScrollEnabled={true}
					>
						{Object.entries(familyIllnesses).map(([key, illness], index) => (
							<View key={key}>
								<View style={styles.illness}>
									<Text style={styles.illnessText}>
										{getTranslation(illness, 'name', illness.name)}
									</Text>
								</View>
								{index < Object.entries(familyIllnesses).length - 1 && (
									<View style={styles.separator} />
								)}
							</View>
						))}
					</ScrollView>
					<View style={styles.pressableContainer}>
						<Pressable
							style={styles.pressable}
							onPress={() => {
								navigation.navigate('ProfileNavigator', {
									screen: 'FamilyMedical',
									params: {
										data: {
											isHome: true,
										},
									},
								});
							}}
						>
							<Text style={styles.pressableText}>
								{getStaticTranslation('history.edit', 'Edit Details')}
							</Text>
						</Pressable>
					</View>
				</View>
			) : (
				<View style={styles.noDataContainar}>
					<View style={styles.noData}>
						<View>
							<FileAddSvg />
						</View>
						<View style={styles.noDataTextContainer}>
							<Text style={styles.subTitle}>
								{getStaticTranslation('history.no data', 'No Data Available')}
							</Text>
							<Text style={styles.desc}>
								{getStaticTranslation(
									'history.no family info',
									'No family history added.'
								)}
							</Text>
						</View>
					</View>
					<View style={styles.pressableContainer}>
						<Pressable
							style={styles.iconPressable}
							onPress={() => {
								navigation.navigate('ProfileNavigator', {
									screen: 'FamilyMedical',
									params: {
										data: {
											isHome: true,
										},
									},
								});
							}}
						>
							<AddSvg
								color1={theme.palette.secondary}
								color2={theme.palette.green[700]}
								width={16}
								height={16}
							/>
							<Text style={styles.pressableText}>
								{getStaticTranslation('history.add', 'Add Condition')}
							</Text>
						</Pressable>
					</View>
				</View>
			)}
		</ScrollView>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		container: {
			flex: 1,
			backgroundColor: theme.palette.lightGreen[100],
			paddingHorizontal: isWeb ? '5%' : 20,
		},
		title: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[700],
			marginHorizontal: isWeb ? '3%' : 10,
			marginVertical: 5,
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 10,
		},
		illnessContainer: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: '5%',
			paddingVertical: '5%',
			marginBottom: '3%',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
		},
		innerScroll: {
			display: 'flex',
			minHeight: 50,
			maxHeight: 200,
		},
		illness: {
			marginBottom: '1%',
		},
		illnessText: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.black[100],
			textTransform: 'capitalize',
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 8,
		},
		noDataContainar: {
			// flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: isWeb ? '15%' : '10%',
			paddingVertical: '5%',
			marginBottom: '3%',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
		},
		noData: {
			// flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		noDataTextContainer: {
			// flex: 1,
			display: 'flex',
			judtifyContent: 'flex-start',
			alignItems: 'flex-start',
			flexDirection: 'column',
			// marginLeft: '5%',
		},
		subTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[300],
		},
		desc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[14],
			color: theme.palette.grey[300],
		},
		pressableContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		pressable: {
			marginTop: '3%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 20,
		},
		iconPressable: {
			marginTop: '5%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 25,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		pressableText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[14],
		},
	});
};

export default HistoryScreen;
