import React, { useEffect, useRef } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Animated,
    Platform,
    useWindowDimensions,
} from 'react-native';
import { theme, fontFamily, getFontSize, getAlign } from '../theme';
import { useTranslation } from './TranslationProvider';

const Condition = ({ name, evidence, score }) => {
    const progressAnim = useRef(new Animated.Value(0)).current;
    const percentage = (score / 800) * 100;
    const isWeb = Platform.OS === 'web';
    const isAndroid = Platform.OS === 'android';
    const isIos = Platform.OS === 'ios';
    const { width } = useWindowDimensions();
    const { getStaticTranslation, getTranslation } = useTranslation();
    const styles = cssStyles(isWeb, isAndroid, isIos, width);
    useEffect(() => {
        Animated.timing(progressAnim, {
            toValue: percentage,
            duration: 2000,
            useNativeDriver: false,
        }).start();
    }, [percentage]);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text
                    numberOfLines={1}
                    ellipsizeMode='tail'
                    style={styles.conditionText}
                >
                    {name}
                </Text>
                <Text
                    style={[
                        styles.severityText,
                        {
                            color:
                                evidence === 'strong'
                                    ? theme.palette.red[200]
                                    : evidence === 'moderate'
                                        ? theme.palette.orange[200]
                                        : theme.palette.green[400],
                        },
                    ]}
                >
                    {getStaticTranslation(`checker.${evidence}`)}
                </Text>
            </View>
            <View style={styles.progressBarBackground}>
                <Animated.View
                    style={[
                        styles.progressBarForeground,
                        {
                            width: progressAnim.interpolate({
                                inputRange: [0, 100],
                                outputRange: ['0%', '100%'],
                            }),
                        },
                    ]}
                />
            </View>
        </View>
    );
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
    const fontSize = getFontSize();
    const align=getAlign()
    return StyleSheet.create({
        container: {
            flex: 1,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 8,
        },
        conditionText: {
            fontFamily: fontFamily.poppins[500],
            fontSize: fontSize[16],
            color: theme.palette.black[100],
            textTransform: 'capitalize',
            width: '74%',
        },
        severityText: {
            fontFamily: fontFamily.poppins[600],
            fontSize: fontSize[16],
            textTransform: 'capitalize',
            textAlign: align.right,
        },
        progressBarBackground: {
            height: 8,
            borderRadius: 4,
            backgroundColor: theme.palette.grey[300],
        },
        progressBarForeground: {
            height: 8,
            borderRadius: 4,
            backgroundColor: theme.palette.green[200],
        },
    });
};

export default Condition;
