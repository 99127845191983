import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	Modal,
	Pressable,
	StyleSheet,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { api } from '../helpers/helper';
import Skeleton from './Skeleton';
import { errorToast, warningToast } from './Toast';
import { useTranslation } from './TranslationProvider';

const InvitationModal = ({ invitationCode }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const { user, language } = useSelector(state => state.app);
	const navigation = useNavigation();
	const [invitation, setInvitation] = useState();
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const router = useRoute();
	useEffect(() => {
		setLoading(true);
		if (invitationCode) {
			api('invitations', 'get', {
				code: invitationCode,
			})
				.then(res => {
					if (res) {
						setInvitation(res.result);
						const result = res.result;

						if (result.status === 'Expired') {
							errorToast(
								getStaticTranslation(
									`profile.invitation expired`,
									'This invitation link is expired'
								)
							);
						} else if (
							result.status === 'Pending' &&
							result.type === 'Email' &&
							user &&
							result.email !== user.email
						) {
							errorToast(
								getStaticTranslation(
									`profile.invitation not valid`,
									'This invitation link is not valid for this user. Please try with another account'
								)
							);
						} else if (!result._organizationId) {
							errorToast(
								getStaticTranslation(
									`profile.invitation not active`,
									'This invitation link is not yet activated or invalid. Please wait for the admin to create the organization first'
								)
							);
						} else if (result._organizationId && user && !user.organization) {
							setModalVisible(true);
						} else if (
							result._organizationId &&
							user &&
							user.organization &&
							result._organizationId._id !==
								user.organization._organizationId._id
						) {
							const text =
								language === 'zh'
									? getStaticTranslation(`profile.invitation already`) +
									  user.organization._organizationId.name +
									  getStaticTranslation(`profile.invitation already1`) +
									  user.organization.role +
									  getStaticTranslation(`profile.invitation already2`)
									: getStaticTranslation(
											`profile.invitation already`,
											'You already is the '
									  ) +
									  user.organization.role +
									  getStaticTranslation(`profile.invitation already1`, 'of') +
									  user.organization._organizationId.name +
									  getStaticTranslation(
											`profile.invitation already2`,
											'. You cannot join two organizations at the same time.'
									  );
							warningToast(text);
						}
						setLoading(false);
					}
				})
				.catch(e => {
					errorToast(e.message);
				});
		}
	}, [user]);

	const onClose = e => {
		setInvitation();
		navigation.push('HomeTabs');
		setModalVisible(false);
	};
	const handleInvitation = async status => {
		const data = {
			...invitation,
			status: status,
			_userId: user._id || user._doc._id,
			email: user.email,
			_organizationId: invitation._organizationId._id,
		};
		await api('invitations/update', 'post', data).then(res => {
			onClose();
		});
	};
	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width);
	};

	return (
		<Modal
			visible={modalVisible}
			animationType='slide'
			transparent={true}
			onRequestClose={onClose}
		>
			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
				<Pressable style={[styles.modalOverlay, isWeb && { outlineStyle: 'none' }]} onPress={onClose}>
					<View style={styles.modalContainer} onLayout={handleLayout}>
						{loading ? (
							<View style={styles.loadingContainer}>
								<Skeleton
									height={40}
									width={containerWidth / 2}
									borderRadius={20}
								/>
								<Skeleton
									height={50}
									width={containerWidth - 20}
									borderRadius={12}
								/>
								<Skeleton
									height={60}
									width={containerWidth - 20}
									borderRadius={12}
								/>
								<Skeleton
									height={60}
									width={containerWidth - 20}
									borderRadius={12}
								/>
							</View>
						) : (
							<>
								<Text style={styles.title}>Invitation</Text>
								<Text style={styles.warningText}>
									You are invited to join as {invitation?.role} of{' '}
									{invitation?._organizationId?.name} organization.
								</Text>

								<>
									<Pressable
										style={styles.confirmButton}
										onPress={() => {
											handleInvitation('Accepted');
										}}
									>
										<Text style={styles.confirmButtonText}>Accept</Text>
									</Pressable>
									<Pressable
										style={styles.cancelButton}
										onPress={() => {
											handleInvitation('Rejected');
										}}
									>
										<Text style={styles.confirmButtonText}>Reject</Text>
									</Pressable>
								</>
							</>
						)}
					</View>
				</Pressable>
			</View>
		</Modal>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		modalOverlay: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: `${theme.palette.primary}50`,
			width: '100%',
			maxWidth: isWeb ? 500 : width,
			// outlineStyle: 'none',
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: 10,
			paddingHorizontal: 20,
		},
		modalContainer: {
			width: '85%',
			maxWidth: isWeb ? 500 : width,
			padding: 20,
			backgroundColor: theme.palette.lightGreen[100],
			borderRadius: 10,
			alignItems: 'center',
			shadowColor: theme.palette.primary,
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			elevation: 5,
			paddingVertical: 30,
		},
		title: {
			fontSize: fontSize[22],
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.black[100],
		},
		warningText: {
			fontSize: fontSize[16],
			textAlign: align.center,
			marginBottom: 20,
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.black[100],
		},
		span: {
			fontSize: fontSize[16],
			textAlign: align.center,
			marginBottom: 20,
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
		},
		confirmButton: {
			width: '100%',
			padding: 15,
			borderRadius: 10,
			backgroundColor: theme.palette.green[700],

			alignItems: 'center',
			marginBottom: 10,
		},
		confirmButtonText: {
			color: theme.palette.secondary,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
		cancelButton: {
			width: '100%',
			padding: 15,
			borderRadius: 10,
			backgroundColor: theme.palette.lightGreen[300],
			alignItems: 'center',
		},
		cancelButtonText: {
			color: theme.palette.green[700],
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
	});
};
export default InvitationModal;
