import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Pressable,
} from 'react-native';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import StepBar from '../../components/StepBar';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import { setChecker, setMode } from '../../redux/reducer';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';

const SymptomDurationScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { checker } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const dispatch = useDispatch();
	const { getStaticTranslation } = useTranslation();
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	return (
		<LightBackground>
			<View style={styles.container}>
				<StepBar step={5} />
				<View style={styles.questionContainer}>
					<Text style={styles.question}>
						{getStaticTranslation(
							'checker.question duration',
							'How long has your symptom been bothering you?'
						)}
					</Text>
					<View style={styles.answerContainer}>
						{['a', 'b', 'c'].map((duration, index) => {
							const isSelected = checker?.duration === duration;
							return (
								<Pressable
									key={index}
									style={[
										styles.answer,
										{
											backgroundColor: isSelected
												? theme.palette.lightGreen[400]
												: theme.palette.secondary,
										},
									]}
									onPress={() => dispatch(setChecker({ duration: duration }))}
								>
									<Text
										style={[
											styles.answerText,
											{
												color: isSelected
													? theme.palette.secondary
													: theme.palette.black[100],
											},
										]}
									>
										{duration === 'a' &&
											getStaticTranslation(
												'checker.less than 48',
												'Less than 48 hours'
											)}
										{duration === 'b' &&
											getStaticTranslation(
												'checker.48 hours - 7 days',
												'48 hours to 7 days'
											)}
										{duration === 'c' &&
											getStaticTranslation(
												'checker.more than 7 days',
												'More than 7 days'
											)}
									</Text>
								</Pressable>
							);
						})}
					</View>
				</View>
				<View style={styles.buttonContainer}>
					<Pressable
						disabled={!checker?.duration}
						style={[
							styles.button,
							{
								backgroundColor: !checker?.duration
									? theme.palette.grey[200]
									: theme.palette.green[200],
							},
						]}
						onPress={() => navigation.navigate('Severity')}
					>
						<Text
							style={[
								styles.buttonText,
								{
									color: !checker?.duration
										? theme.palette.grey[600]
										: theme.palette.green[700],
								},
							]}
						>
							{getStaticTranslation('checker.next', 'Next')}
						</Text>
					</Pressable>
				</View>
			</View>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		questionContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			marginTop: 10,
			alignItems: 'center',
			width: '100%',
		},
		question: {
			color: theme.palette.green[700],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingBottom: 10,
			textAlign: align.center,
		},
		answerContainer: {
			gap: 10,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			paddingHorizontal: 20,
		},
		answer: {
			padding: 10,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 12,
			width: '100%',
		},
		answerText: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};
export default SymptomDurationScreen;
