import React, { useEffect, useRef } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Animated,
	Platform,
	useWindowDimensions,
	Pressable,
} from 'react-native';
import { fontFamily, getFontSize, theme } from '../theme';
import { ArrowRightSvg } from './Svg';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from './TranslationProvider';
import { useSelector } from 'react-redux';
const RiskCard = ({
	scenarios,
	resultId,
	scenario,
	isHome = false,
	isReport = false,
	company,
}) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { language } = useSelector(state => state.app);
	const { getStaticTranslation, getTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const opacityAnim = useRef(new Animated.Value(0)).current;
	const navigation = useNavigation();
	useEffect(() => {
		Animated.timing(opacityAnim, {
			toValue: 1,
			duration: 2000,
			useNativeDriver: true,
		}).start();
	}, []);

	const getColor = value => {
		if (value === 'high') {
			return {
				backgroundColor: `${theme.palette.red[300]}10`,
				color: theme.palette.red[300],
			};
		} else if (value === 'low') {
			return {
				backgroundColor: `${theme.palette.green[400]}10`,
				color: theme.palette.green[400],
			};
		} else if (value === 'positive') {
			return {
				backgroundColor: `${theme.palette.orange[500]}10`,
				color: theme.palette.orange[500],
			};
		} else {
			return {
				backgroundColor: `${theme.palette.blue[200]}10`,
				color: theme.palette.blue[200],
			};
		}
	};

	return (
		<Pressable
			style={styles.card}
			onPress={() => {
				const navigationData = {
					data: {
						isReport,
						isHome,
						scenario,
					},
				};

				if (!isHome && !isReport) {
					navigationData.data.resultId = resultId;
					navigationData.data.scenarios = scenarios;
					navigationData.data.company = company;
				}

				navigation.navigate('LabNavigator', {
					screen: 'LabResultDetail',
					params: navigationData,
				});
			}}
		>
			<View
				style={[
					styles.header,
					{
						backgroundColor:
							scenario?.risk === 'low'
								? theme.palette.green[400]
								: scenario?.risk === 'moderate'
								? theme.palette.orange[200]
								: theme.palette.red[300],
					},
				]}
			>
				<Text style={styles.headerText}>
					{language === 'ms'
						? _.upperCase(
								getStaticTranslation('overview.risk', 'RISK') +
									getStaticTranslation(
										`overview.${scenario?.risk || 'high'}`,
										scenario?.risk || 'high'
									)
						  )
						: _.upperCase(
								getStaticTranslation(
									`overview.${scenario?.risk || 'high'}`,
									scenario?.risk || 'high'
								) + getStaticTranslation('overview.risk', 'RISK')
						  )}
				</Text>
			</View>
			<View style={styles.container}>
				<View style={styles.subContainer}>
					{scenario?.btTypes.map((item, index) => (
						<View key={index} style={styles.itemContainer}>
							<View
								style={[
									styles.labelContainer,
									{ backgroundColor: getColor(item.value).backgroundColor },
								]}
							>
								<Text
									style={[
										styles.labelText,
										{ color: getColor(item.value).color },
									]}
								>
									{_.upperCase(
										getStaticTranslation(`overview.${item.value}`, item.value)
									)}
								</Text>
							</View>
							<Text
								style={styles.itemText}
								numberOfLines={1}
								ellipsizeMode='tail'
							>
								{getTranslation(item.btTypeInfo, 'name', item.btTypeInfo.name)}
							</Text>
						</View>
					))}
				</View>

				<View style={styles.arrowContainer}>
					<ArrowRightSvg color={theme.palette.grey[500]} />
				</View>
			</View>
		</Pressable>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		card: {
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
			overflow: 'hidden',
			marginVertical: 10,
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 2 },
			shadowOpacity: 0.1,
			shadowRadius: 2,
		},
		header: {
			padding: 10,
			justifyContent: 'center',
			alignItems: 'center',
		},
		headerText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},
		container: {
			display: 'flex',
			flexDirection: 'row',
			padding: 15,
		},
		subContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			gap: 5,
		},
		itemContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			width: '100%',
			gap: 10,
		},
		labelContainer: {
			borderRadius: 5,
			width: '40%',
			alignItems: 'center',
		},
		labelText: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},
		itemText: {
			flex: 1,
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[16],
			color: theme.palette.black[100],
		},

		arrowContainer: {
			alignItems: 'flex-end',
			justifyContent: 'center',
		},
	});
};

export default RiskCard;
