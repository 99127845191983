import React, { useEffect, useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Platform,
	useWindowDimensions,
	Pressable,
	BackHandler,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme, fontFamily, getFontSize, getAlign } from '../theme';
import { ArrowLeftSvg, HomeSvg } from './Svg';
import { useSelector } from 'react-redux';
import BackHomeModal from './BackHomeModal';
import { useTranslation } from './TranslationProvider';

const StepBar = ({ step, isPain = false }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const navigation = useNavigation();
	const { language } = useSelector(state => state.app);
	const data = [
		{
			step: 1,
			title: getStaticTranslation('checker.personal', 'Personal Details'),
			previousPath: 'Checker',
		},
		{
			step: 2,
			title: getStaticTranslation('checker.medical', 'Medical Case History'),
			previousPath: 'Personal',
		},
		{
			step: 3,
			title: getStaticTranslation('checker.family', 'Family History'),
			previousPath: 'Medical',
		},
		{
			step: 4,
			title: getStaticTranslation(
				'checker.symptom select',
				'Symptom Selection'
			),
			previousPath: 'Family',
		},
		{
			step: 5,
			title: getStaticTranslation(
				'checker.symptom duration',
				'Symptom Duration'
			),
			previousPath: 'Symptom',
		},
		{
			step: 6,
			title: isPain
				? getStaticTranslation('checker.pain scale', 'Pain Scale')
				: getStaticTranslation('checker.symptom severity', 'Symptom Severity'),
			previousPath: 'Duration',
		},
		{
			step: 7,
			title: getStaticTranslation('checker.question', 'Questionnaire'),
			previousPath: 'Severity',
		},
		{
			step: 8,
			title: getStaticTranslation('checker.vital', 'Vital Signs (Optional)'),
			previousPath: 'Question',
		},
	];
	const [modalVisible, setModalVisible] = useState(false);
	const totalSteps = data.length;
	const currentStepData = data.find(d => d.step === step);
	useEffect(() => {
		const handleBackPress = () => {
			if (step === 1) {
				setModalVisible(true);
				return true; 
			}
			return false;
		};
		if (isAndroid) {
			BackHandler.addEventListener('hardwareBackPress', handleBackPress);
		}
		return () => {
			if (isAndroid) {
				BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
			}
		};
	}, [step, isAndroid]);
	return (
		<View style={styles.headerContainer}>
			<View style={styles.header}>
				<Pressable
					onPress={() => {
						if (step === 1) {
							setModalVisible(true);
						} else {
							navigation.navigate(currentStepData.previousPath);
						}
					}}
				>
					<ArrowLeftSvg
						color={theme.palette.green[700]}
						width={12}
						height={19}
					/>
				</Pressable>
				<Text style={styles.title}>{currentStepData.title}</Text>
				<Pressable onPress={() => setModalVisible(true)}>
					<HomeSvg color={theme.palette.green[700]} />
				</Pressable>
			</View>
			<View style={styles.stepContainer}>
				{Array.from({ length: totalSteps }).map((_, index) => (
					<View
						key={index}
						style={[
							styles.step,
							index < step ? styles.completedStep : styles.incompleteStep,
						]}
					/>
				))}
			</View>
			<Text style={styles.stepText}>
				{language === 'zh'
					? `${getStaticTranslation(
							'checker.number',
							'第'
					  )} ${step}/${totalSteps} ${getStaticTranslation(
							'checker.step',
							'STEP'
					  )}`
					: `${getStaticTranslation(
							'checker.step',
							'STEP'
					  )} ${step}/${totalSteps}`}
			</Text>
			<BackHomeModal
				visible={modalVisible}
				onClose={() => setModalVisible(false)}
			/>
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		headerContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			backgroundColor: theme.palette.lightGreen[100],

			gap: 10,
			marginTop: isWeb ? (height < 570 ? '1%' : '5%') : 0,
		},
		header: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		title: {
			flex: 1,
			textAlign: align.center,
			color: theme.palette.black[100],
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[700],
			paddingLeft: 5,
		},
		stepContainer: {
			flexDirection: 'row',
			flex: 1,
			justifyContent: 'center',
			marginVertical: height < 570 ? 0 : 10,
			width: '100%',
		},
		step: {
			height: 5,
			flex: 1,
			marginHorizontal: 2,
			borderRadius: 5,
		},
		completedStep: {
			backgroundColor: theme.palette.green[200],
		},
		incompleteStep: {
			backgroundColor: theme.palette.secondary,
		},
		stepText: {
			fontSize: width < 360 ? 14 : 18,
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.lightGreen[300],
			marginLeft: 10,
		},
	});
};

export default StepBar;
