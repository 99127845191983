import React from 'react';
import { LogBox } from 'react-native';
import { View } from 'react-native';
import AnimateNumber from 'react-native-animate-number';

const AnimatedNumberComponent = ({ value, styles }) => {
	const formatter = val => {
		const num = parseFloat(val);
		return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
	}

// LogBox.ignoreLogs([
//   'Warning: componentWillUpdate has been renamed', 
// ]);

	return (
		
			<AnimateNumber
				value={value}
				formatter={formatter}
				timing='linear'
				style={styles}
				countBy={1}
			/>
		
	);
};

export default AnimatedNumberComponent;
