import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    Modal,
    StyleSheet,
    TouchableOpacity,
    Platform,
    useWindowDimensions,
    Pressable,
} from 'react-native';
import DateTimePicker from 'react-native-ui-datepicker';
import moment from 'moment';
import { fontFamily, getFontSize, theme } from '../theme';
import _ from 'lodash';
import dayjs from 'dayjs';

const DobPicker = ({ isVisible, onConfirm, onCancel, initialDate }) => {
    const isWeb = Platform.OS === 'web';
    const isAndroid = Platform.OS === 'android';
    const isIos = Platform.OS === 'ios';
    const { width, height } = useWindowDimensions();
    const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
    const [date, setDate] = useState();
    const getMaxDate = () => {
        const tomorrow = new Date();
        return tomorrow;
    };
    useEffect(() => {
        if (initialDate) {
            const momentDate = moment(initialDate, 'DD-MM-YYYY').toDate();
            const formated = moment(momentDate).format('YYYY-MM-DD');
            setDate(formated);
        }
    }, [initialDate]);

    return (
        <Modal
            transparent={true}
            animationType='slide'
            visible={isVisible}
            onRequestClose={onCancel}
        >
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Pressable style={styles.modalOverlay} onPress={onCancel}>
                    <View style={styles.modalContainer}>
                        <DateTimePicker
                            locale='en'
                            mode='single'
                            initialView='day'
                            onChange={onConfirm}
                            maxDate={getMaxDate()}
                            date={date}
                            calendarTextStyle={styles.calendarTextStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            selectedItemColor={theme.palette.green[300]}
                        />
                    </View>
                </Pressable>
            </View>
        </Modal>
    );
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
    const fontSize = getFontSize();
    return StyleSheet.create({
        modalOverlay: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${theme.palette.primary}50`,
            width: '100%',
            maxWidth: isWeb ? 500 : width,
        },
        modalContainer: {
            width: '85%',
            maxWidth: isWeb ? 500 : width,
            padding: 20,
            backgroundColor: theme.palette.secondary,
            borderRadius: 10,
            alignItems: 'center',
            shadowColor: theme.palette.primary,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            paddingVertical: 30,
        },
        confirmButton: {
            width: '100%',
            padding: 15,
            borderRadius: 10,
            backgroundColor: theme.palette.green[700],

            alignItems: 'center',
            marginBottom: 10,
        },
        confirmButtonText: {
            color: theme.palette.secondary,
            fontSize: fontSize[18],
            fontFamily: fontFamily.poppins[600],
        },
        cancelButton: {
            width: '100%',
            padding: 15,
            borderRadius: 10,
            backgroundColor: theme.palette.lightGreen[300],
            alignItems: 'center',
        },
        cancelButtonText: {
            color: theme.palette.green[700],
            fontSize: fontSize[18],
            fontFamily: fontFamily.poppins[600],
        },
        calendarTextStyle: {
            fontSize: fontSize[14],
            fontFamily: fontFamily.poppins[400],
        },
        selectedTextStyle: {
            color: theme.palette.secondary,
            fontSize: fontSize[14],
            fontFamily: fontFamily.poppins[500],
        },
    });
};

export default DobPicker;
