import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Pressable,
} from 'react-native';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import StepBar from '../../components/StepBar';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import { setChecker, setMode } from '../../redux/reducer';
import { Slider } from '@react-native-assets/slider';
import { ModerateSvg, PainlessSvg, UnbearableSvg } from '../../components/Svg';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';

const SeverityScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { checker } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const dispatch = useDispatch();
	const { getStaticTranslation } = useTranslation();
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	return (
		<LightBackground>
			<View style={styles.container}>
				<StepBar
					step={6}
					isPain={checker?.symptom?.severityType === 'painScore'}
				/>
				<View style={styles.questionContainer}>
					<Text style={styles.question}>
						{checker?.symptom?.severityType === 'painScore'
							? getStaticTranslation(
									'checker.question severity',
									'How would you rate your pain on the scale of 1 - 10'
							  )
							: getStaticTranslation(
									'checker.question pain score',
									'How does it change over time?'
							  )}
					</Text>

					{checker?.symptom?.severityType === 'painScore' ? (
						<>
							<View style={styles.answerContainer}>
								<Text
									style={[
										styles.score,
										{
											color:
												checker?.painScore >= 8
													? theme.palette.red[400]
													: checker?.painScore >= 3
													? theme.palette.orange[400]
													: theme.palette.green[500],
										},
									]}
								>
									{checker?.painScore || 1}
								</Text>
								<Slider
  style={styles.slider}
  minimumValue={1}
  maximumValue={10}
  step={1}
  value={checker?.painScore || 1}
  onValueChange={(value) => {
    // Explicitly dispatch even if the value is 1
    if (value === 1 || value !== checker?.painScore) {
      dispatch(setChecker({ painScore: value }));
    }
  }}
  minimumTrackTintColor={
    checker?.painScore >= 8
      ? theme.palette.lightRed[200]
      : checker?.painScore >= 3
      ? theme.palette.orange[300]
      : theme.palette.lightGreen[500]
  }
  maximumTrackTintColor={theme.palette.grey[300]}
  thumbTintColor={
    checker?.painScore >= 8
      ? theme.palette.red[400]
      : checker?.painScore >= 3
      ? theme.palette.orange[400]
      : theme.palette.green[500]
  }
  trackStyle={{ height: 10, borderRadius: 5 }}
  thumbStyle={{ height: 32, width: 32, borderRadius: 20 }}
/>

							</View>
							<View style={styles.emojiContainer}>
								<View style={styles.svgContainer}>
									<PainlessSvg />
									<Text
										style={[
											styles.emojiText,
											{ color: theme.palette.green[500] },
										]}
									>
										{getStaticTranslation('checker.painless', 'Painless')}
									</Text>
								</View>
								<View style={styles.svgContainer}>
									<ModerateSvg />
									<Text
										style={[
											styles.emojiText,
											{ color: theme.palette.orange[400] },
										]}
									>
										{getStaticTranslation('checker.moderate', 'Moderate')}
									</Text>
								</View>
								<View style={styles.svgContainer}>
									<UnbearableSvg />
									<Text
										style={[
											styles.emojiText,
											{ color: theme.palette.red[400] },
										]}
									>
										{getStaticTranslation('checker.unbearable', 'Unbearable')}
									</Text>
								</View>
							</View>
						</>
					) : (
						<View style={styles.answerContainer}>
							{['a', 'b', 'c'].map((severity, index) => {
								const isSelected = checker?.severity === severity;
								return (
									<Pressable
										key={index}
										style={[
											styles.answer,
											{
												backgroundColor: isSelected
													? theme.palette.lightGreen[400]
													: theme.palette.secondary,
											},
										]}
										onPress={() => dispatch(setChecker({ severity: severity }))}
									>
										<Text
											style={[
												styles.answerText,
												{
													color: isSelected
														? theme.palette.secondary
														: theme.palette.black[100],
												},
											]}
										>
											{severity === 'a' &&
												getStaticTranslation(
													'checker.getting better',
													'Getting better'
												)}
											{severity === 'b' &&
												getStaticTranslation(
													'checker.remains the same',
													'Remains the same'
												)}
											{severity === 'c' &&
												getStaticTranslation('checker.worsening', 'Worsening')}
										</Text>
									</Pressable>
								);
							})}
						</View>
					)}
				</View>
				<View style={styles.buttonContainer}>
				<Pressable
  disabled={
    checker?.symptom?.severityType === 'painScore'
      ? checker?.painScore === 0 || checker?.painScore === null
      : !checker?.severity
  }
  style={[
    styles.button,
    {
      backgroundColor: (
        checker?.symptom?.severityType === 'painScore'
          ? checker?.painScore === 0 || checker?.painScore === null
          : !checker?.severity
      )
        ? theme.palette.grey[200]
        : theme.palette.green[200],
    },
  ]}
  onPress={() => navigation.navigate('Question')}
>
  <Text
    style={[
      styles.buttonText,
      {
        color: (
          checker?.symptom?.severityType === 'painScore'
            ? checker?.painScore === 0 || checker?.painScore === null
            : !checker?.severity
        )
          ? theme.palette.grey[600]
          : theme.palette.green[700],
      },
    ]}
  >
    {getStaticTranslation('checker.next', 'Next')}
  </Text>
</Pressable>

				</View>
			</View>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		questionContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			marginTop: 10,
			alignItems: 'center',
			width: '100%',
		},
		question: {
			color: theme.palette.green[700],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingBottom: 10,
			textAlign: align.center,
		},
		answerContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			paddingHorizontal: 20,
		},
		answer: {
			padding: 10,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 12,
			width: '100%',
			marginVertical: 5,
		},
		answerText: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},
		score: {
			fontSize: fontSize[80],
			fontFamily: fontFamily.poppins[600],
			lineHeight: 120,
		},
		slider: {
			width: '100%',
			height: 20,
			touchAction: 'none',
		},
		emojiContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginTop: 30,
			paddingLeft: 10,
		},
		svgContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		emojiText: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
		},

		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};
export default SeverityScreen;
