import React, { useEffect } from 'react';
import { View, StyleSheet, Animated, Easing } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { theme } from '../theme';

const Skeleton = ({ width=100, height=20, borderRadius=4 }) => {
	const styles = cssStyles();
	const AnimatedLG = Animated.createAnimatedComponent(LinearGradient);
	const animatedValue = new Animated.Value(0);
	useEffect(() => {
		Animated.loop(
			Animated.timing(animatedValue, {
				toValue: 1,
				duration: 1000,
				easing: Easing.linear.inOut,
				useNativeDriver: true,
			})
		).start();
	});
	const translateX = animatedValue.interpolate({
		inputRange: [0, 1],
		outputRange: [-width, width],
	});

	return (
		<View style={[styles.container, { width, height, borderRadius }]}>
			<AnimatedLG
				colors={[
					`${theme.palette.secondary}10`,
					`${theme.palette.secondary}80`,
					`${theme.palette.secondary}10`,
				]}
				start={[0, 0]}
				end={[1, 0]}
				style={{
					...StyleSheet.absoluteFill,
					transform: [{ translateX: translateX || 0}],
				}}
			/>
		</View>
	);
};

const cssStyles = () => {
	return StyleSheet.create({
		container: {
			backgroundColor: theme.palette.grey[100],
			overflow: 'hidden',
		},
		gradient: {
			flex: 1,
		},
	});
};

export default Skeleton;
