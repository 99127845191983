import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  StyleSheet,
  Pressable,
  ScrollView,
} from "react-native";
import React, { useCallback, useState } from "react";
import Background from "../../components/Background";
import { LogoSvg } from "../../components/Svg";
import Language from "../../components/Language";
import { useDispatch, useSelector } from "react-redux";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import Checkbox from "expo-checkbox";
import { setIsTnC, setMode, setUserActivity } from "../../redux/reducer";
import { useTimer } from "../../components/TimerProvider";
import { api } from "../../helpers/helper";
import TNCModal from "../../components/TNCModal";
import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "../../components/TranslationProvider";
import { warningToast } from "../../components/Toast";

const CheckerScreen = ({ navigation, route }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const { isTnC, userActivity, user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const styles = cssStyles(isWeb, isAndroid, isIos, width);
  const [modalVisible, setModalVisible] = useState(false);

  const { startTimer } = useTimer();
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("dark"));
      if (isTnC) {
        setModalVisible(false);
      } else {
        setModalVisible(true);
      }
    }, [isTnC])
  );

  const handleStart = async () => {
    if (isTnC) {
      if (!userActivity) {
        const activity = await api("user-activities", "post", {
          _userId: user?._id,
          paymentMethod: "Corporate Billing",
        });
        dispatch(setUserActivity(activity.result));
      }
      dispatch(setMode("light"));
      startTimer();
      navigation.navigate("CheckerNavigator"); ///////////////////////
    } else {
      warningToast(
        getStaticTranslation(
          "checker.no tnc",
          "You need to accept the terms and condition to continue"
        )
      );
    }
  };

  const handleCheckboxChange = () => {
    if (!isTnC) {
      setModalVisible(true);
    } else {
      dispatch(setIsTnC(!isTnC));
    }
  };

  return (
    <Background>
      <View style={styles.languageContainer}>
        <Language isAuth={false} />
      </View>
      <View style={styles.logoContainer}>
        <LogoSvg
          width={isAndroid || isIos ? "173" : width < 360 ? "80%" : "50%"}
          height={isAndroid || isIos ? "30" : width < 360 ? "15%" : "10%"}
        />
        <Text style={styles.title}>
          {getStaticTranslation("checker.title", "Symptom Checker A.I.")}
        </Text>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollContent}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <Text style={styles.desc}>
            {getStaticTranslation(
              "checker.desc1",
              "MEDIVERSE Symptom Checker A.I. is a researched medical Artificial Intelligence for the purpose of diagnosis, triaging and management."
            )}
          </Text>
					<Text style={styles.desc1}>
            {getStaticTranslation(
              "checker.desc2",
              "Please read and understand our Terms and Conditions before using our platform."
            )}
          </Text>
          <Text style={styles.warning}>
            {getStaticTranslation(
              "checker.desc3",
              "[ THE INFORMATION PROVIDED BY THIS APP IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE CONSIDERED MEDICAL ADVICE. ALWAYS CONSULT A HEALTHCARE PROFESSIONAL BEFORE MAKING HEALTH DECISIONS ]"
            )}
          </Text>
        </ScrollView>
				
        <Pressable style={styles.button} onPress={() => setModalVisible(true)}>
          <Text style={styles.buttonText}>
            {" "}
            {getStaticTranslation("checker.tnc", "Terms and Conditions")}
          </Text>
        </Pressable>
      </View>
      <View style={styles.startContainer}>
        <View style={styles.formContainer}>
          <View style={styles.checkboxContainer}>
            <Checkbox
              value={isTnC || false}
              onValueChange={handleCheckboxChange}
              color={isTnC ? theme.palette.primary : theme.palette.secondary}
            />
            <Text style={styles.checkboxLabel}>
              {getStaticTranslation("checker.agree", "I agreed to the ")}
              <Text style={styles.boldText}>
                {getStaticTranslation("checker.tnc", "Terms and Conditions")}
              </Text>
            </Text>
          </View>
          <Pressable
            disabled={!isTnC}
            style={[
              styles.getStartedButton,
              {
                backgroundColor: isTnC
                  ? theme.palette.green[200]
                  : theme.palette.grey[200],
              },
            ]}
            onPress={handleStart}
          >
            <Text
              style={[
                styles.getStartedButtonText,
                {
                  color: isTnC
                    ? theme.palette.green[700]
                    : theme.palette.grey[600],
                },
              ]}
            >
              {getStaticTranslation("checker.start", "Get Started")}
            </Text>
          </Pressable>
          <Text style={styles.footerText}>
            {getStaticTranslation(
              "checker.info",
              "By clicking 'Get Started', you acknowledge and accept to the privacy policy, terms and conditions stated above."
            )}
          </Text>
        </View>
      </View>
      <TNCModal
        visible={modalVisible}
        onClose={(event) => {
          setModalVisible(false);
        }}
      />
    </Background>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingTop: isAndroid || isIos ? 10 : 20,
      paddingRight: isAndroid || isIos ? 15 : 30,
      width: "100%",
    },
    logoContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: theme.palette.secondary,
      fontSize: fontSize[28],
      textAlign: align.center,
      fontFamily: fontFamily.poppins[700],
      marginBottom: isWeb ? 10 : 0,
    },
    scrollView: {
      width: "100%",
      maxHeight: isAndroid || isIos ? 260 : 280,
    },
    scrollContent: {
      alignItems: "flex-start",
      gap: width < 360 ? 10 : 20,
      paddingHorizontal: isWeb ? 30 : 20,
    },
    desc: {
      color: `${theme.palette.secondary}80`,
      fontSize: fontSize[14],
      textAlign: align.justify,
      fontFamily: fontFamily.poppins[300],
    },
    desc1: {
      color:theme.palette.secondary,
      fontSize: fontSize[16],
      textAlign: align.justify,
      fontFamily: fontFamily.poppins[600],
    },
    warning: {
      color: `${theme.palette.secondary}80`,
      fontSize: fontSize[15],
      textAlign: align.justify,
      fontFamily: fontFamily.poppins[500],
    },
    desc2: {
      color: theme.palette.secondary,
      fontSize: fontSize[14],
      textAlign: align.justify,
      fontFamily: fontFamily.poppins[500],
    },
    button: {
      backgroundColor: theme.palette.secondary,
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 8,
      marginVertical: 15,
    },
    buttonText: {
      color: theme.palette.primary,
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
    },
    startContainer: {
      width: "100%",
      backgroundColor: theme.palette.lightGreen[100],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: 25,
      borderTopLeftRadius: 25,
      paddingVertical: isAndroid || isIos ? 10 : width < 360 ? "5%" : "10%",
      paddingHorizontal: "5%",
    },
    formContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: isAndroid || isIos ? "100%" : "auto",
      height: isAndroid || isIos ? "28%" : "auto",
      gap: 18,
    },
    checkboxContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    checkboxLabel: {
      marginLeft: 12,
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[400],
      color: theme.palette.black[100],
    },
    boldText: {
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
      color: theme.palette.black[100],
    },
    getStartedButton: {
      width: "70%",
      height: 50,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    getStartedButtonText: {
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[600],
    },
    footerText: {
      fontSize: fontSize[13],
      fontFamily: fontFamily.poppins[400],
      color: `${theme.palette.black[100]}80`,
      textAlign: align.center,
    },
  });
};

export default CheckerScreen;
