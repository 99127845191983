import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  StyleSheet,
  Image,
  Pressable,
  ScrollView,
  Dimensions,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LightBackground from "../../components/LightBackground";
import StepBar from "../../components/StepBar";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import { api } from "../../helpers/helper";
import _ from "lodash";
import { ArrowLeftSvg, CrossSvg, SadSvg, TickSvg } from "../../components/Svg";
import { setChecker, setMode } from "../../redux/reducer";
import Skeleton from "../../components/Skeleton";
import { useTranslation } from "../../components/TranslationProvider";
import { useFocusEffect } from "@react-navigation/native";
import { errorToast } from "../../components/Toast";

const QuestionnaireScreen = ({ navigation }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width, height } = useWindowDimensions();
  const { getStaticTranslation, getTranslation } = useTranslation();
  const { checker } = useSelector((state) => state.app);
  const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
  const screenWidth = Dimensions.get("window").width;
  const dispatch = useDispatch();
  const [conditions, setConditions] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [condition, setCondition] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [presentation, setPresentation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("light"));
    }, [dispatch])
  );
  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };

  useEffect(() => {
    setCurrentQuestion(1);
    if (checker?.symptom?._id) {
      setLoading(true);
      api(`conditions/${checker?.symptom?._id}/presentations`, "post", checker)
        .then((res) => {
          const { conditions, presentations } = res.result;
          setConditions(conditions);
          setPresentations(presentations);
          setLoading(false);
        })
        .catch((error) => {
          errorToast(error.message);
        });
    }
  }, [checker?.symptom?._id]);

  useEffect(() => {
    if (!presentations.length) {
      return;
    }
    const next = getNextPresentation();
    if (!next) {
      dispatch(setChecker({ condition: condition }));
      navigation.navigate("Vital");
    }
  }, [answered, presentations]);

  const onPress = (answer, presentation) => {
    const presentationExists =
      checker.presentations && checker.presentations[presentation._id];
    if (answer === "yes" && !presentationExists) {
      dispatch(
        setChecker({
          presentations: {
            ...checker.presentations,
            [presentation._id]: presentation,
          },
        })
      );
    } else if (answer === "no" && presentationExists) {
      const updatedPresentations = { ...checker.presentations };
      delete updatedPresentations[presentation._id];
      dispatch(setChecker({ presentations: updatedPresentations }));
    }
    const clone = _.cloneDeep(answered);
    clone.push({ _id: presentation._id, answer });
    setAnswered(clone);
    setCurrentQuestion(clone.length + 1);
  };
  const undo = () => {
    const clone = _.cloneDeep(answered);
    const presentation = _.findLast(clone);
    clone.pop();
    if (presentation) {
      const updatedPresentations = { ...checker.presentations };
      delete updatedPresentations[presentation._id];

      dispatch(
        setChecker({
          presentations: updatedPresentations,
        })
      );
    }
    setAnswered(clone);
    setCurrentQuestion(clone.length + 1);
  };
  const getNextPresentation = () => {
    let next;
    let out = _.filter(presentations, { specific: true, required: true });

    let groupByIndex = {};
    _.forEach(
      _.filter(presentations, { specific: true, required: false }),
      (item) => {
        let prev = _.get(groupByIndex, `${item.index}`, []);
        prev.push(item);
        _.set(groupByIndex, item.index, prev);
      }
    );
    _.forEach(groupByIndex, (arr) => {
      const first = _.get(_.orderBy(arr, "frequency", "desc"), "0");
      let filtered = _.filter(arr, { frequency: first.frequency });
      if (
        _.some(_.map(filtered, (p) => _.has(checker, `presentations.${p._id}`)))
      ) {
        filtered = arr;
      }
      _.forEach(filtered, (item) => {
        out.push(item);
      });
    });

    _.forEach(conditions, (condition) => {
      let nonSpecifics = [];
      _.forEach(checker?.presentations, ({ _id, specific }) => {
        if (specific && _.includes(condition.specificPresentations, _id)) {
          nonSpecifics = _.map(condition.nonSpecificPresentations, (_id) => {
            return _.find(presentations, { _id });
          });
        }
      });
      out = _.union(out, nonSpecifics);
    });

    _.forEach(out, (presentation) => {
      if (!_.find(answered, { _id: presentation?._id })) {
        next = presentation;
        return false;
      }
    });

    return next;
  };

  const nextPresentation = getNextPresentation();

  useEffect(() => {
    if (nextPresentation) {
      const data = extractLinkFromString(
        getTranslation(nextPresentation, "question", nextPresentation?.question)
      );
      setPresentation(data);
    }
  }, [nextPresentation]);
  const extractLinkFromString = (inputString) => {
    const parts = inputString.split("![]");
    const question = parts[0].trim();
    const urls = [];

    for (let i = 1; i < parts.length; i++) {
      const urlMatch = parts[i].match(/\(([^)]+)\)/);
      if (urlMatch) {
        urls.push(urlMatch[1]);
      }
    }

    return { question, urls };
  };

  return (
    <LightBackground>
      <View style={styles.container}>
        <StepBar step={7} />
      </View>
      <View style={styles.questionContainer} onLayout={handleLayout}>
        {loading ? (
          <View style={styles.loadingOuterContainer}>
            <View style={styles.loadingContainer}>
              <Skeleton
                height={30}
                width={containerWidth * 0.4}
                borderRadius={12}
              />
              <Skeleton
                height={60}
                width={containerWidth * 0.9}
                borderRadius={12}
              />
              <Skeleton
                height={200}
                width={containerWidth * 0.9}
                borderRadius={12}
              />
            </View>
            <View style={styles.loadingContainer}>
              <View style={styles.loadingInnerContainer}>
                <Skeleton
                  height={60}
                  width={containerWidth * 0.4}
                  borderRadius={12}
                />
                <Skeleton
                  height={60}
                  width={containerWidth * 0.4}
                  borderRadius={12}
                />
              </View>
              <View style={styles.loadingInnerContainer}>
                <Skeleton
                  height={40}
                  width={containerWidth * 0.5}
                  borderRadius={12}
                />
                <Skeleton
                  height={40}
                  width={containerWidth * 0.3}
                  borderRadius={12}
                />
              </View>
            </View>
          </View>
        ) : (
          <>
            <>
              {nextPresentation && (
                <>
                  <Text style={styles.questionNumber}>
                    {getStaticTranslation("checker.question", "Question")}{" "}
                    {currentQuestion}
                  </Text>
                  <ScrollView
                    style={styles.scrollContainer}
                    contentContainerStyle={styles.scrollContent}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                  >
                    <Text style={styles.question}>
                      {presentation?.question}
                    </Text>
                    {presentation?.urls && presentation.urls.length > 0 && (
                      <View style={styles.imageContainer}>
                        {presentation.urls.map((url, index) => (
                          <Image
                            key={index}
                            source={{ uri: url }}
                            style={[
                              styles.image,
                              isWeb
                                ? {
                                    height: `${100 / presentation.urls.length}%`, // Each image takes up an equal percentage of the container height
                                    width: "100%", // Full width of the container
                                    aspectRatio: 16 / 9, // Maintain 16:9 ratio
                                  }
                                : {
                                    width: screenWidth, // Set a fixed width for non-web (mobile) platforms
                                    aspectRatio: 16 / 9, // Maintain 16:9 ratio
                                  },
                            ]}
                            borderRadius={isWeb ? 10 : 0} // Use borderRadius on web
                            resizeMode="contain" // Ensure the image fits within its bounds
                          />
                        ))}
                      </View>
                    )}
                  </ScrollView>
                  <View style={styles.buttonContainer}>
                    <Pressable
                      style={styles.yesButton}
                      onPress={() => onPress("yes", nextPresentation)}
                    >
                      <Text style={styles.buttonText}>
                        <TickSvg />
                      </Text>
                    </Pressable>
                    <Pressable
                      style={styles.noButton}
                      onPress={() => onPress("no", nextPresentation)}
                    >
                      <Text style={styles.buttonText}>
                        <CrossSvg />
                      </Text>
                    </Pressable>
                  </View>
                  <View style={styles.navigationContainer}>
                    {currentQuestion > 1 && (
                      <Pressable style={styles.previousButton} onPress={undo}>
                        <ArrowLeftSvg
                          color={theme.palette.secondary}
                          width={10}
                          height={14}
                        />
                        <Text style={styles.previousButtonText}>
                          {getStaticTranslation(
                            "checker.previous question",
                            "Previous Question"
                          )}
                        </Text>
                      </Pressable>
                    )}
                    <View
                      style={[
                        styles.progressContainer,
                        { width: currentQuestion > 1 ? "auto" : "100%" },
                      ]}
                    >
                      <Text style={styles.currentQuestion}>
                        {String(currentQuestion).padStart(2, "0")}
                      </Text>
                    </View>
                  </View>
                </>
              )}
            </>
            <>
              {presentations?.length <= 0 && (
                <>
                  <View style={styles.noDataContainer}>
                    <Text style={styles.noDataTitle}>
                      {getStaticTranslation("checker.error", "Error 404")}
                    </Text>
                    <View style={styles.icon}>
                      <SadSvg width={102} height={100} />
                    </View>
                    <Text style={styles.subTitle}>
                      {getStaticTranslation(
                        "checker.error desc1",
                        "Opps! Something went wrong!"
                      )}
                    </Text>
                    <Text style={styles.desc}>
                      {getStaticTranslation(
                        "checker.error desc2",
                        "Sorry that we encountered an error and unable to proceed."
                      )}
                    </Text>
                  </View>
                  <View style={styles.noButtonContainer}>
                    <Pressable
                      style={[
                        styles.backButton,
                        {
                          backgroundColor: theme.palette.green[200],
                        },
                      ]}
                      onPress={() => navigation.navigate("Symptom")}
                    >
                      <Text
                        style={[
                          styles.backButtonText,
                          {
                            color: theme.palette.green[700],
                          },
                        ]}
                      >
                        {getStaticTranslation("checker.go back", "Go Back")}
                      </Text>
                    </Pressable>
                  </View>
                </>
              )}
            </>
          </>
        )}
      </View>
    </LightBackground>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    questionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      marginTop: 10,
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.secondary,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      width: "100%",
      padding: 15,
    },
    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
      paddingHorizontal: 20,
    },
    loadingOuterContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    loadingInnerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 30,
    },
    questionNumber: {
      color: theme.palette.green[700],
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[600],
      paddingBottom: 10,
      textAlign: align.center,
    },
    scrollContainer: {
      flex: 1,
      width: "100%",
      paddingHorizontal: 5,
    },
    scrollContent: {
      flexGrow: 1,
      alignItems: "center",
    },
    question: {
      color: theme.palette.black[100],
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[400],
      paddingBottom: 10,
      textAlign: align.justify,
      width: "100%",
    },
    imageContainer: {
      marginHorizontal: 0,
      borderRadius: 10,
      justifyContent: "flex-start",
      alignItems: isIos || isAndroid ? "center" : "center",
      width: "100%",
      marginTop: 5,
      flex: 1,
    },
    image: {
      borderRadius: 10,
    },
    buttonContainer: {
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    yesButton: {
      backgroundColor: theme.palette.lightGreen[500],
      paddingVertical: 15,
      paddingHorizontal: 20,
      borderRadius: 10,
      alignItems: "center",
      flex: 1,
      marginHorizontal: 10,
    },
    noButton: {
      backgroundColor: theme.palette.lightRed[200],
      paddingVertical: 15,
      paddingHorizontal: 20,
      borderRadius: 10,
      alignItems: "center",
      flex: 1,
      marginHorizontal: 10,
    },
    buttonText: {
      color: theme.palette.secondary,
      fontSize: fontSize[24],
    },
    navigationContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
      paddingHorizontal: 10,
    },
    previousButton: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 8,
      backgroundColor: theme.palette.green[700],
      padding: 10,
      paddingHorizontal: 20,
      borderRadius: 10,
    },
    previousButtonText: {
      color: theme.palette.secondary,
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[500],
      paddingTop: 2,
    },
    progressContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    currentQuestion: {
      color: `${theme.palette.black[100]}50`,
      fontSize: fontSize[24],
      fontFamily: fontFamily.poppins[700],
      borderBottomWidth: 5,
      borderColor: theme.palette.green[200],
      borderRadius: 2,
      lineHeight: 30,
      paddingBottom: 0,
    },
    totalQuestions: {
      paddingLeft: 5,
      color: `${theme.palette.black[100]}50`,
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[500],
    },
    noDataContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingHorizontal: isWeb ? "5%" : "2%",
      paddingVertical: isWeb ? "10%" : "5%",
    },
    noDataTitle: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[28],
      color: theme.palette.lightGreen[400],
    },
    icon: {
      paddingBottom: 20,
    },
    subTitle: {
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[20],
      color: theme.palette.grey[300],
    },
    desc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[18],
      color: theme.palette.grey[300],
      textAlign: align.center,
    },
    noButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    backButton: {
      width: "80%",
      marginTop: height < 570 ? "1%" : "3%",
      backgroundColor: theme.palette.green[200],
      borderRadius: 10,
      padding: 10,
      paddingHorizontal: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: height < 570 ? "1%" : "5%",
    },
    backButtonText: {
      color: theme.palette.green[700],
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[18],
    },
  });
};

export default QuestionnaireScreen;
