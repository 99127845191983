import { View, Text, ScrollView } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../components/Background';
import { Platform } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { StyleSheet } from 'react-native';
import { ArrowRightSvg, LogoSvg, StartOverSvg } from '../../components/Svg';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import Disclaimer from '../../components/Disclaimer';
import { api } from '../../helpers/helper';
import { errorToast } from '../../components/Toast';
import _ from 'lodash';
import Condition from '../../components/Condition';
import { Pressable } from 'react-native';
import Skeleton from '../../components/Skeleton';
import CheckerHeader from '../../components/CheckerHeader';
import StartOverModal from '../../components/StartOverModal';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { setMode } from '../../redux/reducer';

const ResultScreen = ({ navigation, route }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const { checker } = useSelector(state => state.app);
	const [conditions, setConditions] = useState();
	const [resultId, setResultId] = useState('');
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [isReport, setIsReport] = useState(false);
	const [isPdf, setIsPdf] = useState(false);
	const [symptom, setSymptom] = useState();
	const dispatch = useDispatch();
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('dark'));
		}, [dispatch])
	);
	useEffect(() => {
		setLoading(true);
		if (route.params?.data) {
			if (route.params.data.isPdf) {
				setIsPdf(route.params.data.isPdf);
				setIsReport(route.params.data.isReport);
				setResultId(route.params.data.resultId);
				getResultById(route.params.data.resultId);
			} else {
				setIsReport(route.params.data.isReport);
				setConditions(route.params.data.conditions);
				setResultId(route.params.data.reportId);
			}
			setLoading(false);
		} else {
			getResult();
		}
	}, [route]);
	const getResultById = _resultId => {
		setLoading(true);
		api(`results/${_resultId}`, 'get', null, null, null, false)
			.then(res => {
				setConditions(res.result.result.conditions);
				setSymptom(res.result.result.details);
			})
			.catch(e => {
				errorToast(e.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const getResult = async () => {
		setLoading(true);
		await api('conditions/find', 'post', checker)
			.then(res => {
				setConditions(res.result.data);
				setResultId(res.result._resultId);
			})
			.catch(e => {
				errorToast(e.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onPress = cd => {
		if (!isReport && !_.isEmpty(checker?.symptom?.redFlagDescription)) {
			navigation.navigate('RedFlag', {
				data: {
					conditions: conditions,
					condition: cd,
					resultId: resultId,
					isReport: isReport,
					checker: checker,
				},
			});
		} else if (isReport) {
			if (isPdf) {
				navigation.navigate( _.isEmpty(symptom?.symptom?.redFlagDescription)? 'Education': 'RedFlag', {
					data: _.isEmpty(symptom?.symptom?.redFlagDescription)
						? {
								conditions: conditions,
								condition: cd,
								resultId: resultId,
								isYes: false,
								isReport: isReport,
								checker: symptom,
						  }
						: {
								conditions: conditions,
								condition: cd,
								resultId: resultId,
								isReport: isReport,
								checker: symptom,
						  },
				});
			} else{
				navigation.navigate( _.isEmpty(route.params?.data.checker?.symptom?.redFlagDescription)? 'Education': 'RedFlag', {
					data: _.isEmpty(route.params?.data.checker?.symptom?.redFlagDescription)
						? {
								conditions: conditions,
								condition: cd,
								resultId: resultId,
								isYes: false,
								isReport: isReport,
								checker: route.params?.data.checker,
						  }
						: {
								conditions: conditions,
								condition: cd,
								resultId: resultId,
								isReport: isReport,
								checker: route.params?.data.checker,
						  },
				});
			}
		} else {
			navigation.navigate('Education', {
				data: {
					conditions: conditions,
					condition: cd,
					resultId: resultId,
					isYes: false,
					isReport: isReport,
					checker: isReport
						? isPdf
							? symptom
							: route.params?.data.checker 
						: checker,
				},
			});
		}
	};

	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width - 25);
	};
	return (
		<Background>
			<View style={styles.headerContainer}>
				<CheckerHeader
					step={1}
					conditions={conditions}
					resultId={resultId}
					isReport={isReport}
					checker={isReport ? isPdf? symptom: route.params?.data.checker : checker}
				/>
			</View>
			<View style={styles.container}>
				<LogoSvg
					width={isAndroid ? 150 : isIos ? 173 : '50%'}
					height={isWeb ? '60%' : 30}
				/>
				<Text style={styles.title}>
					{getStaticTranslation('checker.title', 'Symptom Checker A.I.')}
				</Text>
			</View>

			<View style={styles.resultContainer} onLayout={handleLayout}>
				<Text style={styles.subTitle}>
					{getStaticTranslation(
						'checker.possible',
						'Analyzed Possible Condition(s)'
					)}
				</Text>
				<ScrollView
					style={styles.scrollContainer}
					contentContainerStyle={styles.scrollContent}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				>
					{loading ? (
						<View style={styles.loadingContainer}>
							<Skeleton height={400} width={containerWidth} borderRadius={12} />
							<Skeleton height={150} width={containerWidth} borderRadius={12} />
							<Skeleton height={150} width={containerWidth} borderRadius={12} />
							<Skeleton height={150} width={containerWidth} borderRadius={12} />
						</View>
					) : (
						<>
							<Disclaimer />
							{_.map(conditions, (cd, index) => {
								return (
									<Pressable
										key={index}
										style={styles.conditionContainer}
										onPress={() => {
											onPress(cd);
										}}
									>
										<View style={styles.conditionHeader}>
											<Condition
												name={getTranslation(cd, 'name', cd?.name)}
												evidence={cd.evidence}
												score={cd.score}
											/>
											<ArrowRightSvg color={theme.palette.grey[500]} />
										</View>
										<View style={styles.separator} />
										<Text style={styles.description}>
											{getTranslation(cd, 'description', cd.description)}
										</Text>
									</Pressable>
								);
							})}
							{!isReport && (
								<View style={styles.pressableContainer}>
									<Pressable
										style={styles.pressableDownload}
										onPress={() => setModalVisible(true)}
									>
										<StartOverSvg />
										<Text style={styles.pressableText}>
											{getStaticTranslation('checker.restart', 'Start Over')}
										</Text>
									</Pressable>
								</View>
							)}
						</>
					)}
				</ScrollView>
			</View>
			<StartOverModal
				visible={modalVisible}
				onClose={() => setModalVisible(false)}
			/>
		</Background>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align = getAlign();
	return StyleSheet.create({
		headerContainer: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			display: 'flex',
			flexDirection: 'column',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			gap: 10,
		},
		title: {
			fontSize: fontSize[28],
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[700],
		},
		resultContainer: {
			flex: 1,
			paddingVertical: 20,
			flexDirection: 'column',
			gap: 10,
			alignItems: 'center',
			justifyContent: 'center',
			paddingHorizontal: 10,
			backgroundColor: theme.palette.lightGreen[100],
			borderTopLeftRadius: 25,
			borderTopRightRadius: 25,
			marginBottom: isIos ? 50 : 0,
		},
		subTitle: {
			fontSize: fontSize[18],
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			gap: 10,
			padding: 5,
			height: isWeb ? height * 0.5 : 'auto',
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 10,
		},
		conditionContainer: {
			borderRadius: 10,
			backgroundColor: theme.palette.secondary,
			padding: 15,
			elevation: 1,
			shadowColor: '#000',
			shadowOffset: { width: 0, height: 2 },
			shadowOpacity: 0.25,
			shadowRadius: 1,
		},
		conditionHeader: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingBottom: 5,
			gap: 10,
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 5,
		},
		description: {
			color: theme.palette.black[100],
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[400],
			textAlign: align.justify,
		},
		pressableContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},

		pressableDownload: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
			marginBottom: '5%',
		},
		pressableText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};
export default ResultScreen;
