import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import qs from 'qs';
import axios from 'axios';
import _ from 'lodash';

export const isDev = process.env.EXPO_PUBLIC_ENV === 'dev';
export const isTest = process.env.EXPO_PUBLIC_ENV === 'test';

export let baseUrl = process.env.EXPO_PUBLIC_BASE_URI;
if (isDev) {
	baseUrl = process.env.EXPO_PUBLIC_DEV_BASE_URI;
} else if (isTest) {
	baseUrl = process.env.EXPO_PUBLIC_TEST_BASE_URI;
}

const axiosAPI = async (options, auth, setProgress) => {
	return axios(options)
		.then(res => res.data)
		.catch(async e => {
			const err = _.get(e, 'response.data');
			if (err) {
				if (err.status === 401) {
					await signOut(auth);
				}
				throw err;
			}
			throw e;
		});
};
export const api = async (
	path,
	method = 'get',
	json = {},
	setProgress,
	moreOptions = {},
	requireVerification = true,
	signal
) => {
	if (setProgress) setProgress(0);
	const headers = {
		'Content-Type': 'application/json',
	};

	const options = {
		method: method,
		url: `${baseUrl}/api/${path}`,
		headers,
		paramsSerializer: qs.stringify,
		onDownloadProgress: progressEvent => {
			const total = progressEvent.total || 1;
			let progress = Math.round((progressEvent.loaded / total) * 100);
			progress = Math.max(0, Math.min(progress, 100));

			if (setProgress) setProgress(progress);
		},
		onUploadProgress: progressEvent => {
			const total = progressEvent.total || 1;
			let progress = Math.round((progressEvent.loaded / total) * 100);
			progress = Math.max(0, Math.min(progress, 100));

			if (setProgress) setProgress(progress);
		},
		signal,

		...moreOptions,
	};

	if (method === 'get') {
		options.params = json;
	} else {
		options.data = json;
	}
	if (requireVerification) {
		if (!_.isEmpty(auth.currentUser)) {
			const token = (await auth.currentUser?.getIdTokenResult(true))?.token;

			if (!_.isEmpty(token)) {
				_.set(options, 'headers.Authorization', token);
				return axiosAPI(options, auth, setProgress);
			}
		}
	} else {
		return axiosAPI(options, auth, setProgress);
	}
};
