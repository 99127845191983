import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	ScrollView,
	Pressable,
	StyleSheet,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileHeader from '../../components/ProfileHeader';
import { AddSvg, FileAddSvg, MinusSvg } from '../../components/Svg';
import IllnessModal from '../../components/IlnessModal';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import LightBackground from '../../components/LightBackground';
import _ from 'lodash';
import { api } from '../../helpers/helper';
import { errorToast, successToast } from '../../components/Toast';
import { setMode, setUser } from '../../redux/reducer';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';

const FamilyMedicalScreen = ({ navigation, route }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { user } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	const [illness, setIllness] = useState(user?.familyIllnesses || {});
	const [loading, setLoading] = useState(false);
	const [isHome, setIsHome] = useState(false);
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		if (route.params) {
			setIsHome(route.params.data.isHome);
		}
	}, [route]);
	useEffect(() => {
		if (user.familyIllnesses) {
			setIllness(user.familyIllnesses);
		}
	}, [user?.familyIllnesses]);
	const removeCondition = id => {
		if (!illness) return;
		const updatedConditions = { ...illness };
		delete updatedConditions[id];
		setIllness(updatedConditions);
	};
	const handleAddCondition = item => {
		if (!illness) {
			setIllness({ [item._id]: item });
		} else if (!illness[item._id]) {
			const updatedConditions = { ...illness, [item._id]: item };
			setIllness(updatedConditions);
		}
		setModalVisible(false);
	};
	const save = async () => {
		setLoading(true);
		try {
			const userData = { ...user, familyIllnesses: illness };
			const profile = await api('users/profile', 'put', userData).then(
				res => res.result
			);
			if (profile) {
				successToast(getStaticTranslation(
					'profile.family upload success', 'Family Medical History updated successfully'));
				dispatch(setUser(profile));
				if (isHome) {
					dispatch(setMode('dark'));
					navigation.navigate('HomeTabs', { screen: 'Home' });
				} else {
					navigation.navigate('EditIllness');
				}
			}
		} catch (e) {
			errorToast(e.message);
		} finally {
			setLoading(false);
		}
	};
	return (
		<LightBackground>
			<View style={styles.container}>
				<ProfileHeader step={7} isHome={isHome} />
				<Text style={styles.question}>
					{getStaticTranslation(
						'profile.family question',
						'Do you have any conditions that run in your family?'
					)}
				</Text>
				<View style={styles.familyIllnessContainer}>
					{!illness || Object.keys(illness).length === 0 ? (
						<View style={styles.noData}>
							<View>
								<FileAddSvg />
							</View>
							<View style={styles.noDataTextContainer}>
								<Text style={styles.subTitle}>
									{getStaticTranslation('profile.no data', 'No Data Available')}
								</Text>
								<Text style={styles.desc}>
									{getStaticTranslation(
										'profile.no family desc',
										'No family history added.'
									)}
								</Text>
							</View>
						</View>
					) : (
						<ScrollView
							style={styles.familyIllnessScrollContainer}
							contentContainerStyle={styles.scrollContent}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
						>
              {Object.entries(illness).map(
                ([id, illnessItem], index, array) => (
                  <View key={id}>
                    <View style={styles.conditionItem} key={illnessItem._id}>
                      <Text
                        style={styles.conditionText}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {getTranslation(illnessItem, "name", illnessItem.name)}
                      </Text>
                      <Pressable onPress={() => removeCondition(id)}>
                        <MinusSvg color={theme.palette.red[300]} />
                      </Pressable>
                    </View>
                    {/* Render separator for every item except the last one */}
                    {index < array.length - 1 && (
                      <View style={styles.separator} />
                    )}
                  </View>
                )
              )}
						</ScrollView>
					)}
					<View style={styles.pressableContainer}>
						<Pressable
							style={styles.iconPressable}
							onPress={() => setModalVisible(true)}
						>
							<AddSvg
								color1={theme.palette.secondary}
								color2={theme.palette.green[700]}
								width={16}
								height={16}
							/>
							<Text style={styles.pressableText}>
								{getStaticTranslation('profile.add', 'Add Condition')}
							</Text>
						</Pressable>
					</View>
				</View>
				<View style={styles.buttonContainer}>
					<Pressable
						style={[
							styles.button,
							{
								backgroundColor: theme.palette.green[200],
							},
						]}
						onPress={save}
					>
						<Text
							style={[
								styles.buttonText,
								{
									color: theme.palette.green[700],
								},
							]}
						>
							{getStaticTranslation('profile.save', 'Save')}
						</Text>
					</Pressable>
				</View>
			</View>

			<IllnessModal
				visible={modalVisible}
				onClose={() => setModalVisible(false)}
				onAdd={handleAddCondition}
				filter={'familyHistory'}
			/>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align = getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		desc: {
			color: theme.palette.black[100],
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			paddingVertical: isWeb ? 10 : 0,
			textAlign: align.justify,
		},
		question: {
			color: theme.palette.green[700],
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[600],
			paddingBottom: 10,
			lineHeight: 25,
			textAlign: align.center,
			paddingVertical: 10,
		},
		familyIllnessContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: '5%',
			paddingVertical: '5%',
			marginBottom: '3%',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			width: '100%',
		},
		noData: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			width: '100%',
			flexGrow: 1,
		},
		noDataTextContainer: {
			display: 'flex',
			judtifyContent: 'flex-start',
			alignItems: 'flex-start',
			flexDirection: 'column',
			marginLeft: '5%',
		},
		subTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[300],
		},
		desc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[14],
			color: theme.palette.grey[300],
		},
		familyIllnessScrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			height: isWeb ? height * 0.3 : 'auto',
		},
		conditionItem: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		conditionText: {
			fontSize: fontSize[16],
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[400],
			width: '90%',
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 10,
		},
		pressableContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		iconPressable: {
			marginTop: '5%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 25,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		pressableText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[14],
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};
export default FamilyMedicalScreen;
