import React, { useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Modal,
	ScrollView,
	Platform,
	Pressable,
	useWindowDimensions,
} from 'react-native';
import { theme, fontFamily, getFontSize } from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../redux/reducer';
import { DownSvg } from './Svg';
import _ from 'lodash';
const Language = ({ isAuth = false }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, isAuth);
	const [isModalVisible, setModalVisible] = useState(false);
	const options = [
		{ en: 'English' },
		{ zh: '简体中文' },
		{ ms: 'Bahasa Malaysia' },
	];
	const { language } = useSelector(state => state.app);
	const dispatch = useDispatch();

	const getLanguageName = langCode => {
		const option = options.find(opt => Object.keys(opt)[0] === langCode);
		return option ? option[langCode] : 'English';
	};
    
	const handleSelect = langCode => {
		dispatch(setLanguage(langCode));
		setModalVisible(false);
	};

	return (
		<View>
			<Pressable
				style={styles.dropdownContainer}
				onPress={() => setModalVisible(!isModalVisible)}
			>
				<View style={styles.dropdownInner}>
					<Text
						numberOfLines={1}
						ellipsizeMode='tail'
						style={styles.dropdownText}
					>
						{getLanguageName(language)}
					</Text>
					<DownSvg color={theme.palette.secondary} />
				</View>
			</Pressable>

			<Modal visible={isModalVisible} transparent={true} animationType='fade'>
			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
				<Pressable
					style={styles.modalOverlay}
					activeOpacity={1}
					onPressOut={() => setModalVisible(false)}
				>
					<View style={styles.modalContainer}>
						<ScrollView>
							{_.map(options, (option, index) => {
								const key = Object.keys(option)[0];
								return (
									<Pressable
										key={key}
										style={[
											styles.modalItem,
											{
												borderBottomWidth: index !== options.length - 1 ? 1 : 0,
											},
										]}
										onPress={() => handleSelect(key)}
									>
										<Text
											style={[
												styles.modalItemText,
												{
													fontFamily:
														key === language
															? fontFamily.poppins[700]
															: fontFamily.poppins[400],
												},
											]}
										>
											{option[key]}
										</Text>
									</Pressable>
								);
							})}
						</ScrollView>
					</View>
				</Pressable>
				</View>
			</Modal>
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, isAuth) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		dropdownContainer: {
			width: '100%',
			minWidth: 150,
			maxWidth: 150,
			height: 40,
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			borderRadius: 20,
			justifyContent: 'center',
			paddingHorizontal: 15,
		},
		dropdownInner: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		dropdownText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			paddingRight: 10,
			width: 110,
			textTransform: 'capitalize',
		},
		modalOverlay: {
			flex: 1,
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'flex-end',
			paddingTop: isAuth
				? isAndroid
					? 50
					: isIos
					? 70
					: 60
				: isAndroid
				? 55
				: isIos
				? 75
				: 65,
			paddingRight: isAuth
				? isAndroid
					? 15
					: isIos
					? 15
					: 35
				: isAndroid
				? 20
				: isIos
				? 30
				: 30,
				width: '100%',
			maxWidth: isWeb ? 500 : width,
		},
		modalContainer: {
			width: isAndroid ? 180 : isIos ? 200 : 190,
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
			paddingVertical: 5,
		},
		modalItem: {
			marginHorizontal: 15,
			paddingVertical: 10,
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			borderColor: theme.palette.grey[100],
		},
		modalItemText: {
			paddingHorizontal: 20,
			color: theme.palette.primary,
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			borderRadius: 10,
			width: '100%',
			textTransform: 'capitalize',
		},
	});
};

export default Language;
