import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	ScrollView,
	Pressable,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import ProfileHeader from '../../components/ProfileHeader';
import { fontFamily, getFontSize, theme } from '../../theme';
import Skeleton from '../../components/Skeleton';
import { AddSvg, FileAddSvg } from '../../components/Svg';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { setMode } from '../../redux/reducer';

const EditIllnessScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { user, language } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height, language);

	const [knownIllnesses, setKnownIllnesses] = useState();
	const [familyIllnesses, setFamilyIllnesses] = useState();
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const dispatch = useDispatch()
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		setLoading(true);
		if (user) {
			if (user.knownIllnesses) {
				setKnownIllnesses(user.knownIllnesses);
			}
			if (user.familyIllnesses) {
				setFamilyIllnesses(user.familyIllnesses);
			}
		}
		setLoading(false);
	}, [user]);
	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width);
	};

	return (
		<LightBackground>
			<View style={styles.container}>
				<ProfileHeader step={3} />
			</View>
			<ScrollView
				style={styles.scrollContainer}
				contentContainerStyle={styles.scrollContent}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				nestedScrollEnabled={true}
			>
				{loading ? (
					<View style={styles.loadingContainer} onLayout={handleLayout}>
						<Skeleton height={150} width={containerWidth} borderRadius={12} />
					</View>
				) : knownIllnesses && Object.keys(knownIllnesses).length > 0 ? (
					<View style={styles.illnessContainer}>
						<Text style={styles.title}>
							{getStaticTranslation(
								'profile.medical history',
								'Medical Case History'
							)}
						</Text>
						<Text style={styles.question}>
							{getStaticTranslation(
								'profile.medical question',
								'Do you have any previous health problems or known disease?'
							)}
						</Text>

						<ScrollView
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
							style={styles.innerScroll}
							nestedScrollEnabled={true}
						>
							{Object.entries(knownIllnesses).map(([key, illness], index) => (
								<View key={key}>
									<View style={styles.illness}>
										<Text style={styles.illnessText}>
											{getTranslation(illness, 'name', illness.name)}
										</Text>
									</View>
									{index < Object.entries(knownIllnesses).length - 1 && (
										<View style={styles.separator} />
									)}
								</View>
							))}
						</ScrollView>
						<View style={styles.pressableContainer}>
							<Pressable
								style={styles.pressable}
								onPress={() => {
									navigation.navigate('MedicalCase');
								}}
							>
								<Text style={styles.pressableText}>
									{getStaticTranslation('profile.edit details', 'Edit Details')}
								</Text>
							</Pressable>
						</View>
					</View>
				) : (
					<View style={styles.noDataContainar}>
						<Text style={styles.title}>
							{getStaticTranslation(
								'profile.medical history',
								'Medical Case History'
							)}
						</Text>
						<Text style={styles.question}>
							{getStaticTranslation(
								'profile.medical question',
								'Do you have any previous health problems or known disease?'
							)}
						</Text>

						<View style={styles.noData}>
							<View>
								<FileAddSvg />
							</View>
							<View style={styles.noDataTextContainer}>
								<Text style={styles.subTitle}>
									{getStaticTranslation('profile.no data', 'No Data Available')}
								</Text>
								<Text style={styles.desc}>
									{getStaticTranslation(
										'profile.no data desc',
										'No medical case history added.'
									)}
								</Text>
							</View>
						</View>
						<View style={styles.pressableContainer}>
							<Pressable
								style={styles.iconPressable}
								onPress={() => {
									navigation.navigate('MedicalCase');
								}}
							>
								<AddSvg
									color1={theme.palette.secondary}
									color2={theme.palette.green[700]}
									width={16}
									height={16}
								/>
								<Text style={styles.pressableText}>
									{getStaticTranslation('profile.add', 'Add Condition')}
								</Text>
							</Pressable>
						</View>
					</View>
				)}

				{loading ? (
					<View style={styles.loadingContainer} onLayout={handleLayout}>
						<Skeleton height={150} width={containerWidth} borderRadius={12} />
					</View>
				) : familyIllnesses && Object.keys(familyIllnesses).length > 0 ? (
					<View style={styles.illnessContainer}>
						<Text style={styles.title}>
							{getStaticTranslation('profile.family history', 'Family History')}
						</Text>
						<Text style={styles.question}>
							{getStaticTranslation(
								'profile.family question',
								'Do you have any conditions that run in your family?'
							)}
						</Text>
						<ScrollView
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
							style={styles.innerScroll}
							nestedScrollEnabled={true}
						>
							{Object.entries(familyIllnesses).map(([key, illness], index) => (
								<View key={key}>
									<View style={styles.illness}>
										<Text style={styles.illnessText}>
											{getTranslation(illness, 'name', illness.name)}
										</Text>
									</View>
									{index < Object.entries(familyIllnesses).length - 1 && (
										<View style={styles.separator} />
									)}
								</View>
							))}
						</ScrollView>
						<View style={styles.pressableContainer}>
							<Pressable
								style={styles.pressable}
								onPress={() => {
									navigation.navigate('FamilyMedical');
								}}
							>
								<Text style={styles.pressableText}>
									{getStaticTranslation('profile.edit details', 'Edit Details')}
								</Text>
							</Pressable>
						</View>
					</View>
				) : (
					<View style={styles.noDataContainar}>
						<Text style={styles.title}>
							{getStaticTranslation('profile.family history', 'Family History')}
						</Text>
						<Text style={styles.question}>
							{getStaticTranslation(
								'profile.family question',
								'Do you have any conditions that run in your family?'
							)}
						</Text>

						<View style={styles.noData}>
							<View>
								<FileAddSvg />
							</View>
							<View style={styles.noDataTextContainer}>
								<Text style={styles.subTitle}>
									{getStaticTranslation('profile.no data', 'No Data Available')}
								</Text>
								<Text style={styles.desc}>
									{getStaticTranslation(
										'profile.no family desc',
										'No family history added.'
									)}
								</Text>
							</View>
						</View>
						<View style={styles.pressableContainer}>
							<Pressable
								style={styles.iconPressable}
								onPress={() => {
									navigation.navigate('FamilyMedical');
								}}
							>
								<AddSvg
									color1={theme.palette.secondary}
									color2={theme.palette.green[700]}
									width={16}
									height={16}
								/>
								<Text style={styles.pressableText}>
									{getStaticTranslation('profile.add', 'Add Condition')}
								</Text>
							</Pressable>
						</View>
					</View>
				)}
			</ScrollView>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height, language) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			// flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		scrollContainer: {
			flex: 1,
			backgroundColor: theme.palette.lightGreen[100],
			paddingHorizontal: isWeb ? '5%' : 20,
			// backgroundColor: "red"
			// marginBottom: 20
		},
		scrollContent: {
			flexGrow: 1,
			height: isWeb ? height * 0.3 : 'auto',
			marginBottom: 20
		},
		title: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.black[100],
			// marginHorizontal: isWeb ? '3%' : 10,
			// marginVertical: 5,
		},
		question: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
			color: theme.palette.green[700],
			marginBottom: 10,
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 10,
		},
		illnessContainer: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: '5%',
			paddingVertical: '5%',
			marginBottom: '3%',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
		},
		innerScroll: {
			display: 'flex',
			height: language === 'ms' ? '20%' : language === 'zh' ? '28%' : '25%',
			minHeight: 80,
			maxHeight: 180,
		},
		illness: {
			marginBottom: '1%',
		},
		illnessText: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.black[100],
			textTransform: 'capitalize',
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 8,
		},
		noDataContainar: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: isWeb ? '5%' : '5%',
			paddingVertical: '5%',
			marginBottom: '3%',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
		},
		noData: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
			paddingVertical: '15%',
		},
		noDataTextContainer: {
			display: 'flex',
			judtifyContent: 'flex-start',
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
		subTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[300],
		},
		desc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[14],
			color: theme.palette.grey[300],
		},
		pressableContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		pressable: {
			marginTop: '3%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 20,
		},
		iconPressable: {
			marginTop: '5%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 25,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		pressableText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[14],
		},
	});
};
export default EditIllnessScreen;
