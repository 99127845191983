import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	ScrollView,
	Pressable,
	StyleSheet,
	TextInput,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import ProfileHeader from '../../components/ProfileHeader';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import ForgotPassword from '../../components/ForgotPassword';
import Icon from 'react-native-vector-icons/Ionicons';
import { api } from '../../helpers/helper';
import { errorToast, successToast } from '../../components/Toast';
import 'firebase/auth';
import { auth } from '../../config/firebase';
import {
	EmailAuthProvider,
	reauthenticateWithCredential,
	signOut,
	updatePassword,
} from 'firebase/auth';
import { SadSvg } from '../../components/Svg';
import { logout, setMode } from '../../redux/reducer';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
const EditPasswordScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [isPassword, setIsPassword] = useState(true);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();

	const [data, setData] = useState({
		current: '',
		new: '',
		confirm: '',
	});
	const [isFocused, setIsFocused] = useState({
		current: false,
		new: false,
		confirm: false,
	});
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	const handleInputFocus = textinput => {
		setIsFocused({
			[textinput]: true,
		});
	};
	const handleInputBlur = textinput => {
		setIsFocused({
			[textinput]: false,
		});
	};
	useEffect(() => {
		if (auth.currentUser.providerData[0].providerId !== 'password') {
			setIsPassword(false);
		}
	}, [auth]);
	const validatePassword = password => {
		const re =
			/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/
		return re.test(password);
	};
	const reauthenticate = async currentPassword => {
		const user = auth.currentUser;
		const cred = EmailAuthProvider.credential(user.email, currentPassword);
		return reauthenticateWithCredential(user, cred);
	};
	const save = async () => {
		try {

			if (data.current.length === 0) {
				throw new Error(
					getStaticTranslation(
						'profile.empty current password',
						'Current password cannot be empty.'
					)
				);
			}
			await reauthenticate(data.current);
			if (data.new.length === 0) {
				throw new Error(
					getStaticTranslation(
						'profile.empty new password',
						'New password cannot be empty.'
					)
				);
			}
			if (data.confirm.length === 0) {
				throw new Error(
					getStaticTranslation(
						'profile.empty confirm password',
						'Confirm password cannot be empty.'
					)
				);
			}
			if (!validatePassword(data.new)) {
				throw new Error(
					getStaticTranslation(
						'profile.password info',
						'Password must be at least 8 characters long and include a combination of letters, numbers, symbols, and at least one uppercase letter.'
					)
				);
			}
			if (data.new !== data.confirm) {
				throw new Error(
					getStaticTranslation(
						'profile.password not match',
						'Passwords do not match.'
					)
				);
			}
			await updatePassword(auth.currentUser, data.new);
			await api(`users/password`, 'put', data).then(async (res) => {
				if (res) {
					successToast(
						getStaticTranslation(
							'profile.password success',
							'Password changed successfully! Please login again.'
						)
					);
					await signOut(auth);
					dispatch(logout());
				}
			});
		} catch (e) {
			if (e.code === 'auth/wrong-password') {
				errorToast(
					getStaticTranslation(
						'profile.current password incorrect',
						'Current password is incorrect. Please try again or use forgot password instead.'
					)
				);
			} else {
				errorToast(e.message);
			}
		}
	};
	return (
		<LightBackground>
			<View style={styles.container}>
				<ProfileHeader step={4} />
			</View>
			<ScrollView
				style={styles.scrollContainer}
				contentContainerStyle={styles.scrollContent}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			>
				{isPassword ? (
					<>
						<View style={styles.inputContainer}>
							<View style={styles.forgotPasswordContainer}>
								<Text style={styles.label}>
									{getStaticTranslation(
										'profile.current password',
										'Current Password'
									)}
								</Text>
								<ForgotPassword isChange={true} />
							</View>
							<View
								style={
									isFocused.current
										? [styles.passwordContainer, styles.border]
										: styles.passwordContainer
								}
							>
								<TextInput
									secureTextEntry={!currentPasswordVisible}
									style={[
										styles.input,
										{
											marginTop: 0,
											width: isAndroid || isIos ? '80%' : '100%',
										},
										isWeb && { outlineStyle: 'none' }
									]}
									value={data?.current}
									onChangeText={text => {
										setData({ ...data, current: text });
									}}
									placeholderTextColor={theme.palette.grey[300]}
									onFocus={() => handleInputFocus('current')}
									onBlur={() => handleInputBlur('current')}
								/>
								<Pressable
									onPress={() =>
										setCurrentPasswordVisible(!currentPasswordVisible)
									}
								>
									<Icon
										name={currentPasswordVisible ? 'eye-off' : 'eye'}
										size={25}
										style={styles.toggleIcon}
									/>
								</Pressable>
							</View>
						</View>
						<View style={styles.inputContainer}>
							<Text style={styles.label}>
								{getStaticTranslation('profile.new password', 'New Password')}
							</Text>

							<View
								style={
									isFocused.new
										? [styles.passwordContainer, styles.border]
										: styles.passwordContainer
								}
							>
								<TextInput
									secureTextEntry={!passwordVisible}
									style={[
										styles.input,
										{
											marginTop: 0,
											width: isAndroid || isIos ? '80%' : '100%',
										},
										isWeb && { outlineStyle: 'none' }
									]}
									value={data?.new}
									onChangeText={text => {
										setData({ ...data, new: text });
									}}
									placeholderTextColor={theme.palette.grey[300]}
									onFocus={() => handleInputFocus('new')}
									onBlur={() => handleInputBlur('new')}
								/>
								<Pressable onPress={() => setPasswordVisible(!passwordVisible)}>
									<Icon
										name={passwordVisible ? 'eye-off' : 'eye'}
										size={25}
										style={styles.toggleIcon}
									/>
								</Pressable>
							</View>
						</View>
						<View style={styles.inputContainer}>
							<Text style={styles.label}>
								{getStaticTranslation(
									'profile.confirm password',
									'Confirm New Password'
								)}
							</Text>

							<View
								style={
									isFocused.confirm
										? [styles.passwordContainer, styles.border]
										: styles.passwordContainer
								}
							>
								<TextInput
									secureTextEntry={!confirmPasswordVisible}
									style={[
										styles.input,
										{
											marginTop: 0,
											width: isAndroid || isIos ? '80%' : '100%',
										},
										isWeb && { outlineStyle: 'none' }
									]}
									value={data?.confirm}
									onChangeText={text => {
										setData({ ...data, confirm: text });
									}}
									placeholderTextColor={theme.palette.grey[300]}
									onFocus={() => handleInputFocus('confirm')}
									onBlur={() => handleInputBlur('confirm')}
								/>
								<Pressable
									onPress={() =>
										setConfirmPasswordVisible(!confirmPasswordVisible)
									}
								>
									<Icon
										name={confirmPasswordVisible ? 'eye-off' : 'eye'}
										size={25}
										style={styles.toggleIcon}
									/>
								</Pressable>
							</View>
						</View>
						<Text style={styles.passwordInfo}>
							{getStaticTranslation(
								'profile.password info',
								'Password must be at least 8 characters long and include a combination of letters, numbers, and symbols, with at least one uppercase letter, one number, and one symbol.'
							)}
						</Text>
					</>
				) : (
					<View style={[styles.testContainer]}>
						<View>
							<SadSvg width={72} height={70} />
						</View>
						<Text style={styles.noDataSubTitle}>
							{getStaticTranslation(
								'profile.not available',
								'This feature is not available'
							)}
						</Text>
						<Text style={styles.noDataDesc}>
							{getStaticTranslation(
								'profile.using google',
								'You are login using Google account'
							)}
						</Text>
					</View>
				)}
			</ScrollView>
			{isPassword && (
				<View style={styles.buttonContainer}>
					<Pressable
						style={[
							styles.button,
							{
								backgroundColor:
									!data.new || !data.current || !data.confirm

										? theme.palette.grey[200]
										: theme.palette.green[200],
							},
						]}
						disabled={
							!data.new || !data.current || !data.confirm
						}
						onPress={save}
					>
						<Text
							style={[
								styles.buttonText,
								{
									color: (
										!data.new || !data.current || !data.confirm
									)
										? theme.palette.grey[600]
										: theme.palette.green[700],
								},
							]}
						>
							{getStaticTranslation('profile.password', 'Change Password')}
						</Text>
					</Pressable>
				</View>
			)}
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align = getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			// flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		testContainer: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			width: '100%',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: isWeb ? '5%' : '3%',
			paddingVertical: isWeb ? '10%' : '5%',
			gap: 10,
		},
		noDataSubTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[300],
		},
		noDataDesc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[14],
			color: theme.palette.grey[300],
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			gap: 10,
			padding: 15,
			height: isWeb ? height * 0.5 : 'auto',
			paddingHorizontal: 25,
		},
		label: {
			fontFamily: fontFamily.poppins[500],
			color: theme.palette.black[100],
			fontSize: fontSize[18],
		},
		inputContainer: {
			display: 'flex',
			flexDirection: 'column',
			gap: 5,
		},
		toggleIcon: {
			color: theme.palette.grey[300],
			marginHorizontal: 10,
		},
		input: {
			backgroundColor: theme.palette.secondary,
			padding: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: '100%',
			height: 50,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
		},
		forgotPasswordContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		passwordContainer: {
			flexDirection: 'row',
			alignItems: 'center',

			justifyContent: 'space-between',
			backgroundColor: theme.palette.secondary,
			width: '100%',
			borderRadius: 10,
		},
		border: {
			borderWidth: 2,
			borderColor: theme.palette.green[700],
		},
		passwordInfo: {
			color: theme.palette.grey[500],//
			fontSize: fontSize[12],
			textAlign: align.justify,
			marginTop: 10,
			fontFamily: fontFamily.poppins[300],
			display: height < 670 ? 'none' : 'flex',
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingBottom: 20,
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};
export default EditPasswordScreen;
