import React, { useEffect, useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Pressable,
	Animated,
	Platform,
	useWindowDimensions,
} from 'react-native';
import { theme, fontFamily, getFontSize } from '../theme';
import _ from 'lodash';

const TabBar = ({ state, descriptors, navigation, position }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';

	const { width: screenWidth } = useWindowDimensions();
	const [width, setWidth] = useState(screenWidth);
	const tabWidth = width / state.routes.length;
	const styles = cssStyles(tabWidth, width, isWeb, isAndroid, isIos);
	const [tabLayouts, setTabLayouts] = useState(new Array(state.routes.length).fill(0));
	const [key, setKey] = useState(0);
	useEffect(() => {
		setWidth(isWeb && screenWidth > 500 ? 500 : screenWidth);
	}, [screenWidth, isWeb]);

	// useEffect(() => {
	// 	setTabLayouts([]);
	// 	setKey(prevKey => prevKey + 1);
	// }, [screenWidth]);

	const onTabLayout = (event, index) => {
		const layout = event.nativeEvent.layout;
		setTabLayouts(prevLayouts => {
			const newLayouts = [...prevLayouts];
			newLayouts[index] = layout.x;
			return newLayouts;
		});
	};

	const translateX = position.interpolate({
		inputRange: state.routes.map((_, i) => i),
		outputRange:
			tabLayouts
	});

	return (
		<View style={styles.tabBarStyle}>
			<View style={styles.divider}>
				{_.map(state.routes, (route, index) => {
					const { options } = descriptors[route.key];
					const label = options.tabBarLabel || options.title || route.name;


					const isFocused = state.index === index;

					const onPress = () => {
						const event = navigation.emit({
							type: 'tabPress',
							target: route.key,
						});

						if (!isFocused && !event.defaultPrevented) {
							navigation.navigate(route.name);
						}
					};

					const onLongPress = () => {
						navigation.emit({
							type: 'tabLongPress',
							target: route.key,
						});
					};

					return (
						<Pressable
							key={`${route.key}-${index}`}
							accessibilityState={isFocused ? { selected: true } : {}}
							accessibilityLabel={options.tabBarAccessibilityLabel}
							testID={options.tabBarTestID}
							onPress={onPress}
							onLongPress={onLongPress}
							style={styles.pressableStyle}
							onLayout={event => onTabLayout(event, index)}
						>
							<Text
								numberOfLines={1}
								ellipsizeMode='tail'
								style={[
									styles.tabBarLabelStyle,
									{
										color: isFocused
											? theme.palette.primary
											: theme.palette.grey[700],
									},
								]}
							>
								{label}
							</Text>
						</Pressable>
					);
				})}
			</View>
			{tabLayouts.length === state.routes.length && (
				<Animated.View
					style={[styles.customIndicator, { transform: [{ translateX :translateX|| 0}] }]}
				>
					<View style={styles.customIndicatorCircle} />
				</Animated.View>
			)}
		</View>
	);
};

const cssStyles = (tabWidth, width, isWeb, isAndroid, isIos) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		tabBarStyle: {
			backgroundColor: theme.palette.lightGreen[100],
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			marginTop: 10,
		},
		pressableStyle: {
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 8,
			paddingBottom: isWeb ? 5 : 3,
		},
		tabBarLabelStyle: {
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.green[700],
			maxWidth: width < 300 ? 100 : 150,
		},
		divider: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			borderBottomWidth: 2,
			borderBottomColor: theme.palette.grey[100],
			marginBottom: 7,
			paddingHorizontal: '8%',
			width: '100%',
		},
		customIndicator: {
			position: 'absolute',
			width: tabWidth / 3,
			height: 5,
			borderRadius: 10,
			backgroundColor: theme.palette.primary,
			bottom: 5,
			justifyContent: 'center',
			alignItems: 'flex-end',
		},
		customIndicatorCircle: {
			width: 10,
			height: 10,
			borderRadius: 5,
			backgroundColor: theme.palette.primary,
		},
	});
};

export default TabBar;
