import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './src/redux/store';
import AppNavigator from './src/navigation/AppNavigator';
import { ImageBackground, Platform, StatusBar, StyleSheet } from 'react-native';
import Wrapper from './src/components/Wrapper';
import { RootSiblingParent } from 'react-native-root-siblings';
import {
	SafeAreaProvider,
	SafeAreaView,
	useSafeAreaInsets,
} from 'react-native-safe-area-context';
import * as SplashScreen from 'expo-splash-screen';
import {
	useFonts,
	Poppins_100Thin,
	Poppins_200ExtraLight,
	Poppins_300Light,
	Poppins_400Regular,
	Poppins_500Medium,
	Poppins_600SemiBold,
	Poppins_700Bold,
	Poppins_800ExtraBold,
	Poppins_900Black,
	Poppins_100Thin_Italic,
	Poppins_200ExtraLight_Italic,
	Poppins_300Light_Italic,
	Poppins_400Regular_Italic,
	Poppins_500Medium_Italic,
	Poppins_600SemiBold_Italic,
	Poppins_700Bold_Italic,
	Poppins_800ExtraBold_Italic,
	Poppins_900Black_Italic,
} from '@expo-google-fonts/poppins';
import { ToastProvider } from './src/components/Toast';
import AppLoading from './src/components/AppLoading';
import { StatusBarCustom } from './src/components/StatusBarCustom';
import { TimerProvider } from './src/components/TimerProvider';
import { TranslationProvider } from './src/components/TranslationProvider';

SplashScreen.preventAutoHideAsync();

const App = () => {
	const [appIsReady, setAppIsReady] = useState(false);
	const styles = cssStyles();
	let [fontsLoaded] = useFonts({
		Poppins_100Thin,
		Poppins_200ExtraLight,
		Poppins_300Light,
		Poppins_400Regular,
		Poppins_500Medium,
		Poppins_600SemiBold,
		Poppins_700Bold,
		Poppins_800ExtraBold,
		Poppins_900Black,
		Poppins_100Thin_Italic,
		Poppins_200ExtraLight_Italic,
		Poppins_300Light_Italic,
		Poppins_400Regular_Italic,
		Poppins_500Medium_Italic,
		Poppins_600SemiBold_Italic,
		Poppins_700Bold_Italic,
		Poppins_800ExtraBold_Italic,
		Poppins_900Black_Italic,
	});

	useEffect(() => {
		if (fontsLoaded) {
			setAppIsReady(true);
			SplashScreen.hideAsync();
		}
	}, [fontsLoaded]);

	if (!appIsReady) {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<SafeAreaProvider>
						<Wrapper>
							<StatusBarCustom />
							<AppLoading />
						</Wrapper>
					</SafeAreaProvider>
				</PersistGate>
			</Provider>
		);
	}
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<SafeAreaProvider>
					<RootSiblingParent>
						<StatusBarCustom />
						<SafeAreaView style={{ flex: 1 }}>
							<Wrapper>
								<TranslationProvider>
									<TimerProvider>
										<AppNavigator />
										{Platform.OS === "web" && <ToastProvider />}
									</TimerProvider>
								</TranslationProvider>
							</Wrapper>
						</SafeAreaView>
					</RootSiblingParent>
				</SafeAreaProvider>
			</PersistGate>
		</Provider>
	);
};

const cssStyles = () => {
	return StyleSheet.create({
		background: {
			flex: 1,
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	});
};
export default App;
