import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Modal,
	Pressable,
	ScrollView,
	Linking,
} from 'react-native';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { errorToast } from './Toast';
import { setIsTnC } from '../redux/reducer';
import { useFocusEffect } from '@react-navigation/native';
import { DoubleArrowDownSvg } from './Svg';
import { useTranslation } from './TranslationProvider';

const TNCModal = ({ visible, onClose }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const dispatch = useDispatch();
	const { isTnC } = useSelector(state => state.app);
	const [showScrollMessage, setShowScrollMessage] = useState(true);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);

	const handlePolicy = () => {
		const url = `https://mediverse.my/privacy-policy-cookies`;
		if (Platform.OS === 'web') {
			window.open(url, '_blank');
		} else {
			Linking.openURL(url);
		}
	};
	const handlePress = () => {
		const email = process.env.EXPO_EMAIL_URI;
		const mailtoLink = `mailto:${email}`;

		if (Platform.OS === 'web') {
			window.open(mailtoLink, '_blank');
		} else {
			Linking.canOpenURL(mailtoLink).then(supported => {
				if (supported) {
					Linking.openURL(mailtoLink).catch(error => {
						
						errorToast(
							`${getStaticTranslation(
								'tnc.error',
								"Don't know how to open URI:"
							)} ${mailtoLink}, error: ${error}`
						);
						onClose()
					});
				
		}else {
			errorToast(
				`${getStaticTranslation(
					'tnc.error',
					"Don't know how to open URI:"
				)} ${mailtoLink}, Phone did not find and support any email sending app`
			);
			onClose()
	
				}
			});
		}
	};
	const [isAtEnd, setIsAtEnd] = useState(false);

	const handleScroll = event => {
		const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
		const buffer = 20;
		if (
			contentOffset.y + layoutMeasurement.height >=
			contentSize.height - buffer
		) {
			setIsAtEnd(true);
			setShowScrollMessage(false);
		} else {
			setIsAtEnd(false);
			setShowScrollMessage(true);
		}
	};
	useFocusEffect(
		useCallback(() => {
			if (isTnC) {
				setIsAtEnd(true);
			} else {
				setIsAtEnd(false);
			}
		}, [isTnC])
	);
	return (
		<Modal
			visible={visible}
			animationType='slide'
			transparent={true}
			onRequestClose={onClose}
		>
			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
				<Pressable style={[styles.modalOverlay, isWeb && { outlineStyle: 'none' }]} onPress={onClose}>
					<View style={styles.modalContainer}>
						<Text style={styles.title}>
							{getStaticTranslation('tnc.title', 'Terms and Conditions')}
						</Text>
						{showScrollMessage && (
							<View style={styles.headerContainer}>
								<Text style={styles.headerText}>
									{getStaticTranslation(
										'tnc.continue',
										'Scroll down to continue'
									)}
								</Text>
								<DoubleArrowDownSvg />
							</View>
						)}
						<ScrollView
							style={[
								styles.scrollContainer,
								{
									borderTopLeftRadius: showScrollMessage ? 0 : 12,
									borderTopRightRadius: showScrollMessage ? 0 : 12,
								},
							]}
							contentContainerStyle={styles.scrollContent}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
							onScroll={e => {
								handleScroll(e);
							}}
							scrollEventThrottle={16}
						>
							<Pressable>
								<Text style={styles.subTitle}>
									{getStaticTranslation(
										'tnc.sub title',
										'Mediverse Terms and Conditions'
									)}
								</Text>
								<Text style={styles.lastUpdated}>
									{getStaticTranslation(
										'tnc.last update',
										'Last Updated: 08/10/2022'
									)}
								</Text>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation('tnc.special note', 'Special note')}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulBullet}>
													{getStaticTranslation('tnc.bullet', '\u2022')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.special desc1',
														'You must be 18 years old or above to use this website.'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulBullet}>
													{getStaticTranslation('tnc.bullet', '\u2022')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.special desc2',
														'Please read these terms and conditions carefully before using this website.'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulBullet}>
													{getStaticTranslation('tnc.bullet', '\u2022')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.special desc3',
														'The website does not provide medical advice, diagnosis or treatment.'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulBullet}>
													{getStaticTranslation('tnc.bullet', '\u2022')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.special desc7',
														'The information provided by this app is for informational purposes only and should not be considered medical advice. Always consult a healthcare professional before making health decisions.'
													)}
												</Text>
											</View>
										</View>
									</View>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.special desc4',
											'This Website (including its text, graphics, images, and other material contained on, or linked through) is for informational purposes only. This Website is not exhaustive and cannot always reflect all the most recent research in all areas of medicine.'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.special desc5',
											'This Website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition and/or medical symptoms. Never disregard professional medical advice or delay in seeking it because of something you have read on Website! If you think you may have a medical emergency, call your doctor or the emergency services immediately.'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.special desc6',
											'Mediverse does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on Website. Mediverse does not endorse any product, service, or treatment advertised on the Mediverse Website. Reliance on any information provided by this Website is solely at your own risk. Some of the content on this Website may be provided by third parties. Mediverse is not in a position to verify this content. Mediverse does not warrant that any such third-party content is true, accurate or complete.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation('tnc.tnc', 'Terms and conditions')}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation('tnc.tnc desc1', 'The terms "')}
										<Text style={styles.textBold}>
											{getStaticTranslation('tnc.tnc desc2', 'Mediverse')}
										</Text>
										{getStaticTranslation(
											'tnc.tnc desc3',
											'" refers to Mediverse Sdn. Bhd. The terms "'
										)}
										<Text style={styles.textBold}>
											{getStaticTranslation('tnc.tnc desc4', 'you')}
										</Text>
										{getStaticTranslation(
											'tnc.tnc desc 5',
											'" refers to the individual accessing and/or using the Website. By accessing and/or using the Website in any way, you confirm that you agree to these Terms and Conditions. Please read these Terms and Conditions carefully. If you do not accept any and/or all of these Terms and Conditions, do not use the Website. You may not amend these Terms and Conditions. Mediverse reserves the right (at its sole discretion) to change or otherwise revise and/or update these Terms and Conditions at any time and without prior notice. Your continued access and/or use of the Website signifies your acceptance of the amended Terms and Conditions. Be sure to return to this page periodically to review the most current version of the Terms and Conditions.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.account',
											'Your registration account and use of the website'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.account desc1',
											"You must provide accurate and complete registration information any time you register to use Website. You are responsible for the security of your passwords and for any use of your account. You must immediately notify Mediverse of any unauthorized use of your password or account by sending us an email via the 'Contact Us' links located at the bottom of every page of the Website. Your use of Website and any content accessed through Website must comply with all applicable laws, regulations and ordinances, including any laws regarding the export of data or software. You may not access Website other than by the interfaces provided by Mediverse or interfere with or disrupt the proper operation of Website."
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.password',
											'Password and use of your personal information'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.password desc1',
											'Mediverse has several tools that allow you to record and store information. You are responsible for taking all reasonable steps to ensure that no unauthorized person shall have access to your Mediverse passwords or accounts. It is your sole responsibility to:'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.1', '1.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.password desc2',
														'control the dissemination and use of sign-in name, screen name and passwords;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.2', '2.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.password desc3',
														'authorize, monitor, and control access to and use of your Mediverse account and password;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.3', '3.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.password desc4',
														"promptly inform Mediverse if you believe your account or password has been compromised or if there is any other reason you need to deactivate a password. To send us an email, use the 'Contact Us' links located at the bottom of every page of our Website."
													)}
												</Text>
											</View>
										</View>
									</View>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.password desc5',
											"You consent to Mediverse, its licensors and all other persons or entities involved in the operation of Website transmitting, monitoring, retrieving, storing, and using your information in connection with the operation of Website. Mediverse cannot and does not assume any responsibility or liability for any information you submit, or your or third parties' use or misuse of information transmitted or received using Mediverse tools and services in breach of these Terms and Conditions. When you provide your information through the Website, you warrant to Mediverse that you have the right to do so, for example, it doesn’t relate to and identify another individual, unless you have their consent to do so."
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.password desc6',
											'The personal information you submit to Mediverse is governed by the Mediverse'
										)}{' '}
										<Text onPress={handlePolicy} style={styles.textUnderline}>
											{getStaticTranslation('tnc.privacy', 'Privacy Policy')}
										</Text>
										{getStaticTranslation(
											'tnc.password desc7',
											'. The Privacy Policy is incorporated by reference into these Terms and Conditions. To the extent there is an ambiguity, inconsistency or conflict between these Terms and Conditions and the Mediverse '
										)}
										<Text onPress={handlePolicy} style={styles.textUnderline}>
											{getStaticTranslation('tnc.privacy', 'Privacy Policy')}
										</Text>
										{getStaticTranslation(
											'tnc.password desc8',
											', these Terms and Conditions shall prevail. If you create, transmit, or display health or other information while using Website, you may provide only information that you own or have the right to use. When you provide information through the Website, you give Mediverse a licence to use and distribute it in connection with Website and other Mediverse services. However, Mediverse may only use the personal data you provide as permitted by the Mediverse '
										)}
										<Text onPress={handlePolicy} style={styles.textUnderline}>
											{getStaticTranslation('tnc.privacy', 'Privacy Policy')}
										</Text>
										{getStaticTranslation(
											'tnc.password desc9',
											' and applicable law.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation('tnc.use', 'Use of this website')}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.use desc1',
											'As a condition of your use of this Website, you warrant that:'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.i', 'i.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.use desc2',
														'you are at least 18 years of age,'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.ii', 'ii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.use desc3',
														'you will use this Website in accordance with these Terms and Conditions,'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.iii', 'iii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.use desc4',
														'all information supplied by you on this Website is true, accurate, current and complete, and'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.iv', 'iv.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.use desc5',
														' if you have a Website account, you will safeguard your account information and will supervise and be completely responsible for any use of your account by anyone other than you.'
													)}
												</Text>
											</View>
										</View>
									</View>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.use desc6',
											'Mediverse retains the right at our sole discretion to deny access to anyone to this Website and the services Mediverse offers, at any time and for any reason, including, but not limited to, for violation of these Terms and Conditions.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.copyright',
											'Ownership, copyright and trademark notices'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.copyright desc1',
											'Except as otherwise indicated, the Website, and all text, images, marks, logos and other content contained herein, including, without limitation, the logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof are the proprietary property of Mediverse or its licensors and are protected by Malaysian copyright laws. All content of this Website are: ©2022 Mediverse Sdn. Bhd. All rights reserved. Mediverse is not responsible for content on websites operated by parties other than Mediverse.'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.copyright desc2',
											'Mediverse and the Mediverse logo and all other product or service names or slogans displayed on the Website are common law trademarks of Mediverse and/or its suppliers or licensors, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Mediverse or the applicable trademark holder. In addition, the look and feel of the Website may not be copied, imitated or used, in whole or in part, without the prior written permission of Mediverse. All other trademarks, registered trademarks, product names and company names or logos mentioned in the Website are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by Mediverse.'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.copyright desc3',
											'If you are aware of an infringement of our brand, please let us know by e-mailing us at '
										)}
										<Text style={styles.textUnderline} onPress={handlePress}>
											{' '}
											{getStaticTranslation(
												'tnc.email',
												'mediverse.doc@gmail.com'
											)}
										</Text>{' '}
										{getStaticTranslation(
											'tnc.copyright desc4',
											'. We only address messages concerning brand infringement at this email address.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation('tnc.licence', 'Limited Licence')}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.licence desc1',
											'Mediverse grants you a personal, non-commercial, limited, revocable, non-exclusive, non-sublicensable licence to access and use the Website and to view, copy and print portions of the Website content solely for the purpose of being provided with some possible conditions based on the symptoms that you have entered into the Website system or for any other purpose clearly stated on a Website, all in accordance with these Terms and Conditions. Any use of the Website that is not for one of these purposes or otherwise in accordance with these Terms and Conditions or as otherwise authorized by us in writing is expressly prohibited. Such licence is subject to these Terms and Conditions, and specifically conditioned upon the following:'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.i', 'i.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc2',
														'you may only view, copy and print portions of the Website for your own informational, personal and non-commercial use;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.ii', 'ii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc3',
														' you may not modify or otherwise make derivative uses of the Website or any portion thereof;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.iii', 'iii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc4',
														"any displays or printouts of Website content must be marked '(c) 2022, Mediverse Sdn Bhd Limited All rights reserved.';"
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.iv', 'iv.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc5',
														'you may not remove or modify any copyright, trademark, or other proprietary notices that have been placed on the Website content;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.v', 'v.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc6',
														' you may not use any data mining, robots or similar data gathering or extraction methods;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.vi', 'vi.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc7',
														'you may not use the Website other than for its intended purpose; and'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.vii', 'vii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.licence desc8',
														'you may not reproduce, prepare derivative works from, distribute or display the Website or any site content (except for page caching), except as provided herein.'
													)}
												</Text>
											</View>
										</View>
									</View>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.licence desc9',
											'Except as expressly permitted above, any use of any portion of the Website or Website site content without the prior written permission of Mediverse is strictly prohibited and will terminate the licence granted herein. Any such unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Terms and Conditions may be construed as conferring any licence to intellectual property rights, whether by estoppel, implication or otherwise. This licence is revocable at any time. If you violate any of these Terms and Conditions, this licence shall automatically terminate and you must immediately destroy any displays or printouts of Website content you have made of any portion of the Website. You represent and warrant that your use of the Website and any content herein will be consistent with this licence and will not infringe or violate the rights of any other party or breach any contract or legal duty to any other parties, or violate any applicable law. Any special rules for the use of certain software and other items accessible on the Website may be included elsewhere within Website and are hereby incorporated into these Terms and Conditions by reference.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.activity',
											'Prohibited Activities'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.activity desc1',
											"The licence to use the Website only extends to the uses expressly described herein. The content and information on this Website as well as the infrastructure used to provide such content and information, is proprietary to Mediverse or Mediverse's suppliers and providers. You agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, licence, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from or through this Website. Additionally, you agree not to:"
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.i', 'i.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc2',
														'use this Website or its contents for any commercial purpose;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.ii', 'ii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc3',
														'access, monitor, reproduce, upload, republish, distribute, transmit, display or copy any content or information of this Website using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.iii', 'iii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc4',
														'violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.iv', 'iv.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc5',
														'take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure, adversely affects the performance of the Website, or infringes on our copyright or the copyright of our suppliers;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.v', 'v.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc6',
														'deep-link to any portion of this Website for any purpose without our express written permission;'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.vi', 'vi.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc7',
														"'frame', 'mirror' or otherwise incorporate any part of this Website into any other website without our prior written authorization;"
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.vii', 'vii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc8',
														'sell, offer for sale, transfer, or licence any portion of the Website in any form to any third parties; or'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.vii', 'viii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.activity desc9',
														'post or transmit any false, inaccurate, misleading, unlawful, threatening, libelous, defamatory, obscene, indecent, inflammatory, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law; or for any other purpose that is unlawful or prohibited by these Terms and Conditions.'
													)}
												</Text>
											</View>
										</View>
									</View>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.indemnification',
											'Indemnification'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.indemnification desc1',
											'You shall defend and/or indemnify Mediverse, its respective suppliers, licensors and/or any of its officers, directors, employees, independent contractors and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by any person or third parties as a result of:'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.a', 'a.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.indemnification desc2',
														'your breach of these Terms and Conditions and/or the documents referenced herein,'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.b', 'b.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.indemnification desc3',
														'your violation of any law or the rights of a third party, and/or'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.c', 'c.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.indemnification desc4',
														'your use of this Website.'
													)}
												</Text>
											</View>
										</View>
									</View>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation('tnc.risk', 'Use at your own risk')}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.risk desc1',
											"Use of the website and reliance on its content at any time is at your own risk. The website (including without limitation the website content) is provided on an 'as is' and 'as available' basis."
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.risk desc2',
											'When using this Website, information will be transmitted over a medium that may be beyond the control and jurisdiction of Mediverse and its licensors. Accordingly, Mediverse assumes no liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of Website.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.warranties',
											'Exclusion of warranties'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.warranties desc1',
											'Neither mediverse nor any of Mediverse’s licensors make any express warranties, and each of them excludes to the fullest extent permitted by law all warranties, whether express or implied warranties (whether statutory or otherwise).'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.warranties desc2',
											'Neither Mediverse nor any of Mediverse’s licensors make any warranty that:'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.1', '1.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.warranties desc3',
														'the website content or the website satisfies government regulations requiring disclosure of information, or'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.2', '2.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.warranties desc4',
														'the accuracy, reliability, completeness or timeliness of the website, the website content, software, text, graphics, links, or communications provided on or through the use of the website or mediverse.'
													)}
												</Text>
											</View>
										</View>
									</View>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.liability',
											'Limitation of liability'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.1', '1.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.liability desc1',
														'Mediverse or any of its licensors do not exclude or limit their liability for:'
													)}
												</Text>
												<View style={styles.liContainer}>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.a', 'a.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc2',
																	'death or personal injury caused by their negligence, or'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.b', 'b.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc3',
																	'fraudulent misrepresentation.'
																)}
															</Text>
														</View>
													</View>
												</View>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.liability desc4',
														'Without prejudice to the above, Mediverse or any of its licensors shall not be liable to you for any personal injury, including death, caused by your use of the Website (or any of the Website content) that in is in breach of these Terms and Conditions. Any claims arising out of or in connection with your use of Website (or any of the Website content) in accordance with these Terms and Conditions must be brought within twelve (12) calendar months of the date of the event giving rise to any such claim. Remedies under these Terms and Conditions are exclusive and are limited to those expressly provided for in these Terms and Conditions.'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.2', '2.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.liability desc5',
														'Subject to paragraph 1 above, Mediverse or any of its licensors shall only be liable to you for direct loss. Mediverse or any of its licensors shall not be liable to you for:'
													)}
												</Text>
												<View style={styles.liContainer}>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.i', 'i.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc6',
																	'any indirect or consequential loss;'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.ii', 'ii.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc7',
																	'any loss of use;'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.iii', 'iii.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc8',
																	'any loss of profits or contracts;'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.iv', 'iv.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc9',
																	'any loss of business;'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.v', 'v.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc10',
																	'any loss of income, revenues or anticipated savings'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.vi', 'vi.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc11',
																	'any loss of data; and/or'
																)}
															</Text>
														</View>
													</View>
													<View style={styles.li}>
														<View style={styles.ul}>
															<Text style={styles.ulNumber}>
																{getStaticTranslation('tnc.vii', 'vii.')}
															</Text>
														</View>
														<View style={styles.ulData}>
															<Text style={styles.ulText}>
																{getStaticTranslation(
																	'tnc.liability desc12',
																	'any loss of goodwill'
																)}
															</Text>
														</View>
													</View>
												</View>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.liability desc13',
														'whether arising from tort (including, without limitation, negligence or breach of statutory duty), breach of contract or otherwise.'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.3', '3.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.liability desc14',
														'Subject to paragraph 1 above, the aggregate liability of Mediverse or any of its licensors for any acts, omissions, or defaults whether in contract, tort (including, without limitation, negligence or breach of statutory duty), or howsoever arising under these Terms and Conditions shall not exceed RM100 or its current equivalent.'
													)}
												</Text>
											</View>
										</View>
									</View>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.search',
											'Searches, and links to other websites'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.search desc1',
											'Mediverse may provide links to third-party websites. Such hyperlinks are provided for your reference and convenience only. Mediverse does not control such third party websites. Mediverse does not recommend and does not endorse the content on any third-party websites. Mediverse is not responsible for the content and privacy practices of linked third-party sites framed within Website and does not make any representations or warranties regarding their content or accuracy. The inclusion of such links does not imply any association with the operators of such third-party websites. Your use of third-party websites is at your own risk and subject to the terms and conditions of use for such sites.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation(
											'tnc.general',
											'Jurisdiction & general'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.general desc1',
											'Mediverse Sdn Bhd is a company incorporated in Malaysia (Company Registration Number: 202201012902 (1458599-T)). Mediverse makes no claims that the Website (including any of the Website content) is appropriate or may be downloaded outside of the Malaysia. Access and/or use of the Website may not be legal by certain persons or in certain countries. If you access and/or use Website originates from outside the Malaysia, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction.'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.general desc2',
											'If your access and/or use of the Website originates from the Malaysia, you expressly agree:'
										)}
									</Text>
									<View style={styles.liContainer}>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.i', 'i.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.general desc3',
														'that these Terms and Conditions shall be governed by the laws of Malaysia'
													)}
												</Text>
											</View>
										</View>
										<View style={styles.li}>
											<View style={styles.ul}>
												<Text style={styles.ulNumber}>
													{getStaticTranslation('tnc.ii', 'ii.')}
												</Text>
											</View>
											<View style={styles.ulData}>
												<Text style={styles.ulText}>
													{getStaticTranslation(
														'tnc.general desc4',
														'that exclusive jurisdiction for any dispute arising out of or in connection with Mediverse, or in any way or form relating to your use of Website, resides in the courts of Malaysia. You further agree and expressly consent to the exercise of personal jurisdiction in the courts of Mediverse in connection with any such dispute including (without limitation) any claim involving Mediverse or its affiliates, subsidiaries, employees, contractors, officers, directors, telecommunication providers, and content providers.'
													)}
												</Text>
											</View>
										</View>
									</View>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.general desc5',
											'If any provision of these Terms and Conditions is found to be invalid, unlawful or unenforceable by any court having competent jurisdiction, that part shall be struck out and the remainder of such provision shall remain in effect.'
										)}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.general desc6',
											'No delay, neglect or forbearance by either Mediverse or you in enforcing rights under this Contract shall be a waiver of or prejudice those rights. No waiver of any term of these Terms and Conditions shall be effective unless it is agreed by both parties in writing and no waiver of any default shall constitute a waiver of any subsequent default.'
										)}
									</Text>
								</View>
								<View style={styles.introContainer}>
									<Text style={styles.containerTitle}>
										{getStaticTranslation('tnc.complete', 'Complete agreement')}
									</Text>
									<Text style={styles.text}>
										{getStaticTranslation(
											'tnc.complete desc1',
											'These Terms and Conditions constitute the entire agreement between you and Mediverse with respect to the use of the Website and all related Website content.'
										)}
									</Text>
								</View>
							</Pressable>
						</ScrollView>

						<Pressable
							disabled={isTnC ? false : isAtEnd ? false : true}
							style={[
								styles.confirmButton,
								{
									backgroundColor: isTnC
										? theme.palette.green[200]
										: isAtEnd
											? theme.palette.green[200]
											: theme.palette.grey[200],
								},
							]}
							onPress={() => {
								dispatch(setIsTnC(true));
								onClose();
							}}
						>
							<Text
								style={[
									styles.confirmButtonText,
									{
										color: isTnC
											? theme.palette.green[700]
											: isAtEnd
												? theme.palette.green[700]
												: theme.palette.grey[600],
									},
								]}
							>
								{getStaticTranslation('tnc.accept', 'Accept and Proceed')}
							</Text>
						</Pressable>
					</View>
				</Pressable>
			</View>
		</Modal>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		modalOverlay: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: `${theme.palette.primary}50`,
			width: '100%',
			maxWidth: isWeb ? 500 : width,
			// outlineStyle: 'none',
		},
		modalContainer: {
			height: '90%',
			padding: 15,
			width: '90%',
			maxWidth: isWeb ? 450 : width,
			marginHorizontal: 20,
			backgroundColor: theme.palette.lightGreen[100],
			borderRadius: 10,
			alignItems: 'center',
			shadowColor: theme.palette.primary,
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			elevation: 5,
			paddingVertical: 20,
		},
		title: {
			fontSize: fontSize[22],
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.black[100],
		},
		headerContainer: {
			backgroundColor: theme.palette.lightGreen[300],
			paddingTop: 10,
			paddingBottom: 5,

			width: '100%',
			borderTopLeftRadius: 12,
			borderTopRightRadius: 12,
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		headerText: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[500],
			color: theme.palette.secondary,
			textAlign: align.center,
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
			backgroundColor: theme.palette.secondary,
			borderBottomLeftRadius: 12,
			borderBottomRightRadius: 12,
			marginBottom: 10,
		},
		scrollContent: {
			flexGrow: 1,
			height: isWeb ? height * 0.3 : 'auto',
			paddingVertical: 10,
			paddingHorizontal: 15,
			marginVertical: 5,
			gap: 10,
			width: '100%',
		},
		subTitle: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.primary,
			textTransform: 'uppercase',
		},
		lastUpdated: {
			fontFamily: fontFamily.italic[400],
			fontSize: fontSize[14],
			color: theme.palette.black[100],
		},
		introContainer: {
			gap: 10,
			marginVertical: 10,
		},
		containerTitle: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[600],
			textAlign: align.justify,
			textTransform: 'uppercase',
			lineHeight: 18,
		},
		liContainer: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			width: '100%',
			gap: 5,
		},
		introText: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[600],
			textAlign: align.justify,
			fontSize: fontSize[14],
		},
		text: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[400],
			textAlign: align.justify,
			fontSize: fontSize[14],
		},
		textBold: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[600],
			textAlign: align.justify,
			fontSize: fontSize[14],
		},
		textUnderline: {
			color: theme.palette.green[200],
			fontFamily: fontFamily.poppins[600],
			textAlign: align.justify,
			fontSize: fontSize[14],
			textDecorationLine: 'underline',
		},
		li: {
			display: 'flex',
			flexDirection: 'row',
		},
		ul: {
			display: 'flex',
			flexDirection: 'column',
		},
		ulData: {
			flex: 1,
			width: '100%',
		},
		ulBullet: {
			alignSelf: 'flex-start',
			justifyContent: 'flex-start',
			color: theme.palette.black[100],
			marginHorizontal: 10,
		},
		ulNumber: {
			alignSelf: 'flex-start',
			justifyContent: 'flex-start',
			color: theme.palette.black[100],
			marginHorizontal: 10,
			fontSize: fontSize[14],
			color: theme.palette.black[100],
			textAlign: align.justify,
			fontFamily: fontFamily.poppins[400],
			width: 25,
		},

		ulText: {
			// display: 'flex',
			color: theme.palette.black[100],
			textAlign: align.justify,
			fontFamily: fontFamily.poppins[400],
			marginRight: 50,
			fontSize: fontSize[14],
			width: '100%',
		},
		confirmButton: {
			width: '80%',
			paddingVertical: 12,
			borderRadius: 10,
			alignItems: 'center',
			marginTop: 10,
		},
		confirmButtonText: {
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
	});
};
export default TNCModal;
