import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { theme, fontFamily, getFontSize, getAlign } from "../theme";
import { ArrowDownSvg, ArrowUpSvg, DisclaimerSvg } from "./Svg";
import { Pressable } from "react-native";
import { Platform } from "react-native";
import { useWindowDimensions } from "react-native";
import { useTranslation } from "./TranslationProvider";

const Disclaimer = ({ isEdu = false }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(isEdu ? false : true);
  const styles = cssStyles(isWeb, isAndroid, isIos, width);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <View style={styles.container}>
      <Pressable style={styles.header} onPress={toggleExpand}>
        <DisclaimerSvg />
        <Text style={styles.headerText}>
          {getStaticTranslation("checker.disclaimer", "Disclaimer")}
        </Text>
        {isExpanded ? (
          <ArrowUpSvg color={theme.palette.grey[500]} />
        ) : (
          <ArrowDownSvg color={theme.palette.grey[500]} />
        )}
      </Pressable>
      {isExpanded && (
        <Text style={styles.bodyText1}>
          <Text style={styles.bodyText2}>
            {getStaticTranslation(
              "checker.disclaimer desc",
              "Mediverse Symptom Checker and any content accessed through Mediverse is for informational purposes only, and is not intended to constitute professional medical advice, diagnosis or treatment. Do not ignore or delay obtaining professional medical advice by reason of information accessed through Mediverse. If you think you may have any medical emergency, immediately call your doctor or dial number for local emergency response service to seek immediate medical assistance. Mediverse aims to provide the latest information but this may not always be possible due to the rapid advancement of medical guidelines. The use of Mediverse is subject to terms and conditions. By select any condition, you will be agreeing to the terms of service."
            )}
            {"\n\n"}
          </Text>
          <Text style={styles.bodyText3}>
            {getStaticTranslation(
              "checker.disclaimer desc2",
              "The information provided by this app is for informational purposes only and should not be considered medical advice. Always consult a healthcare professional before making health decisions."
            )}
          </Text>
        </Text>
      )}
    </View>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      backgroundColor: theme.palette.secondary,
      borderRadius: 10,
      elevation: 1,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 1,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingVertical: 10,
      paddingHorizontal: 15,
      borderRadius: 10,
      backgroundColor: theme.palette.secondary,
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    headerText: {
      // flex: 1,
      paddingLeft: 10,
      paddingTop: 5,
      textAlign: align.left,
      color: theme.palette.green[700],
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[700],
    },
    bodyText1: {
      color: theme.palette.black[100],
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[400],
      textAlign: align.justify,
      padding: 15,
    },
    bodyText2: {
      flex: 1,
      color: theme.palette.black[100],
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[400],
      textAlign: align.justify,
    },
    bodyText3: {
      flex: 1,
      color: theme.palette.black[100],
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[400],
      textAlign: align.justify,
    },
  });
};

export default Disclaimer;
