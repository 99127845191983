import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Pressable,
	ImageBackground,
	ScrollView,
	TextInput,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileHeader from '../../components/ProfileHeader';
import LightBackground from '../../components/LightBackground';
import { fontFamily, getFontSize, theme } from '../../theme';
import {
	ArrowRightSvg,
	CalendarSvg,
	CameraSvg,
	DeleteSvg,
	ProfileBgSvg,
} from '../../components/Svg';
import { Country } from 'country-state-city';
import { Dropdown } from 'react-native-element-dropdown';
import 'moment-timezone';
import DobPicker from '../../components/DobPicker';
import _ from 'lodash';
import { errorToast, successToast } from '../../components/Toast';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { api } from '../../helpers/helper';
import { setMode, setUser } from '../../redux/reducer';
import { auth } from '../../config/firebase';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import DeleteAccountModal from '../../components/DeleteAccountModal';
const EditProfileScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.app);
	const [countries, setCountries] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const genders = [
		{ label: 'Male', value: 'male' },
		{ label: 'Female', value: 'female' },
	];
	const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		photoUrl: user?.photoUrl || '',
		displayName: user?.displayName || '',
		dialCode: user?.dialCode || '',
		phoneNumber: user?.phoneNumber || '',
		gender: user?.gender || '',
		dob: user?.dob ? user?.dob : '',
		country: user?.country || '',
	});
	const [isFocused, setIsFocused] = useState({
		displayName: false,
		dialCode: false,
		phoneNumber: false,
		gender: false,
		dob: false,
		country: false,
	});

	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		const countriesData = Country.getAllCountries().map(country => ({
			name: country.name,
			code: country.isoCode,
			dialCode: country.phonecode.startsWith('+')
				? country.phonecode
				: `+${country.phonecode}`,
		}));
		setCountries(countriesData);
		if (user?.dialCode) {
			const initialCountry = countriesData.find(
				country => country.dialCode === user.dialCode
			);
			if (initialCountry) {
				setData(prevData => ({
					...prevData,
					country: initialCountry.name,
				}));
			}
		} else if (user?.country) {
			const initialCountry = countriesData.find(
				country => country.name === user.name
			);
			setData(prevData => ({
				...prevData,
				dialCode: initialCountry?.dialCode,
			}));
		}
		if (user?.gender) {
			setData(prevData => ({
				...prevData,
				gender: user.gender,
			}));
		}
	}, [user?.dialCode, user?.gender, user?.country]);

	const handleInputFocus = textinput => {
		setIsFocused({
			[textinput]: true,
		});
	};
	const handleInputBlur = textinput => {
		setIsFocused({
			[textinput]: false,
		});
	};
	const getGenderLabel = value => {
		const gender = genders.find(gender => gender.value === value);
		return gender ? gender.label : '';
	};
	// const onSelectImage = async () => {
	//   try {
	//     const image = await ImagePicker.launchImageLibraryAsync({
	//       mediaTypes: ImagePicker.MediaTypeOptions.Images,
	//     });

	//     const uri =
	//       image.assets && image.assets.length > 0
	//         ? image.assets[0].uri
	//         : image.uri;
	//     const result = await ImageManipulator.manipulateAsync(
	//       uri,
	//       [{ resize: { width: 500 } }],
	//       {
	//         format: ImageManipulator.SaveFormat.JPEG,
	//         base64: true,
	//       }
	//     );
	//     setData({ ...data, photoUrl: `data:image/jpg;base64,${result.base64}` });
	//   } catch (e) {
	//     errorToast(e.message);
	//   }
	// };
	const onSelectImage = async () => {
		try {
			// Request media library permissions
			if (Platform.OS !== 'web') {
				const { status } =
					await ImagePicker.requestMediaLibraryPermissionsAsync();
				if (status !== 'granted') {
					errorToast('Permission to access the media library is required!');
					return;
				}
			}

			// Launch the image library
			const image = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images,
			});

			// Check if the user canceled the image picker
			if (image.canceled) {
				return;
			}

			// Get the URI of the selected image
			const uri =
				image.assets && image.assets.length > 0
					? image.assets[0].uri
					: image.uri;

			// Manipulate the image
			const result = await ImageManipulator.manipulateAsync(
				uri,
				[{ resize: { width: 500 } }],
				{
					format: ImageManipulator.SaveFormat.JPEG,
					base64: true,
				}
			);

			// Update your state with the new image
			setData({ ...data, photoUrl: `data:image/jpg;base64,${result.base64}` });
		} catch (e) {
			errorToast(e.message);
		}
	};

	const save = async () => {
		setLoading(true);
		try {
			const profile = await api('users/profile', 'put', data).then(
				res => res.result
			);
			if (profile) {
				successToast(
					getStaticTranslation(
						'profile.update success',
						'Profile updated successfully'
					)
				);
				dispatch(setUser(profile));
			}
		} catch (e) {
			errorToast(e.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<LightBackground>
			<View style={styles.container}>
				<ProfileHeader step={1} />
			</View>
			<View style={styles.header}>
				<ProfileBgSvg width={width} height={height / 4.4} />
				<Pressable style={styles.profilePic} onPress={onSelectImage}>
					{data?.photoUrl || auth?.currentUser?.photoURL ? (
						<ImageBackground
							source={{
								uri:
									data?.photoUrl ||
									auth?.currentUser?.photoURL ||
									'data:image/png',
							}}
							style={styles.profileImage}
							resizeMode='cover'
							imageStyle={{ borderRadius: !isWeb ? 100 : '100%' }}
							borderRadius={!isWeb ? 100 : '100%'}
						>
							<CameraSvg color={theme.palette.secondary} />
						</ImageBackground>
					) : (
						<View style={styles.noImage}>
							<CameraSvg color={theme.palette.black[100]} />
						</View>
					)}
				</Pressable>
			</View>
			<ScrollView
				style={styles.scrollContainer}
				contentContainerStyle={styles.scrollContent}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			>
				<View style={styles.inputContainer}>
					<Text style={styles.label}>
						{getStaticTranslation('profile.username', 'Username')}
					</Text>
					<TextInput
						placeholder={getStaticTranslation(
							'profile.eg username',
							'Eg. John Doe'
						)}
						style={[
							styles.input,
							isFocused.displayName && styles.border,
							isWeb && { outlineStyle: 'none' },
						]}
						value={data.displayName}
						onChangeText={text =>
							setData({ ...data, displayName: text.slice(0, 20) })
						}
						placeholderTextColor={theme.palette.grey[300]}
						onFocus={() => handleInputFocus('name')}
						onBlur={() => handleInputBlur('name')}
					/>
				</View>

				<View style={styles.inputContainer}>
					<Text style={styles.label}>
						{getStaticTranslation('profile.phone number', 'Phone Number')}
					</Text>
					<View style={styles.inputSubContainer}>
						<Dropdown
							style={[
								isFocused.dialCode
									? [styles.dropDown, styles.border]
									: styles.dropDown,
								{ width: '30%' },
							]}
							data={countries}
							labelField='dialCode'
							valueField='dialCode'
							placeholder={data?.dialCode || 'Eg. +60'}
							placeholderStyle={[
								styles.placeholderStyle,
								{
									color: data?.dialCode
										? theme.palette.primary
										: theme.palette.grey[300],
								},
							]}
							selectedTextStyle={styles.selectedTextStyle}
							inputSearchStyle={styles.inputSearchStyle}
							searchPlaceholder={getStaticTranslation(
								'profile.search',
								'Search...'
							)}
							onFocus={() => handleInputFocus('dialCode')}
							onBlur={() => handleInputBlur('dialCode')}
							search
							searchField={'dialCode'}
							containerStyle={styles.containerStyle}
							itemTextStyle={styles.itemTextStyle}
							itemContainerStyle={styles.itemContainerStyle}
							closeModalWhenSelectedItem
							onChange={item =>
								setData({
									...data,
									dialCode: item.dialCode,
									country: item.name,
								})
							}
						/>
						<TextInput
							label={getStaticTranslation(
								'profile.phone number',
								'Phone Number'
							)}
							placeholder={getStaticTranslation(
								'profile.eg number',
								'Eg. 12 345 6789'
							)}
							value={data.phoneNumber}
							keyboardType='phone-pad'
							onChangeText={text => {
								setData({ ...data, phoneNumber: text });
							}}
							style={[
								isFocused.phoneNumber
									? [styles.input, styles.border]
									: styles.input,
								{ width: '65%' },
								isWeb && { outlineStyle: 'none' },
							]}
							placeholderTextColor={theme.palette.grey[300]}
						/>
					</View>
				</View>

				<View style={styles.inputContainer}>
					<Text style={styles.label}>
						{getStaticTranslation('profile.gender', 'Gender')}
					</Text>
					<Dropdown
						style={[
							isFocused.gender
								? [styles.dropDown, styles.border]
								: styles.dropDown,
							{ width: '100%' },
						]}
						data={genders}
						labelField='label'
						valueField='value'
						placeholder={
							getGenderLabel(data.gender) ||
							getStaticTranslation('profile.eg gender', 'Eg. Male')
						}
						placeholderStyle={[
							styles.placeholderStyle,
							{
								color: data.gender
									? theme.palette.primary
									: theme.palette.grey[300],
							},
						]}
						selectedTextStyle={styles.selectedTextStyle}
						inputSearchStyle={styles.inputSearchStyle}
						searchPlaceholder={getStaticTranslation(
							'profile.search',
							'Search...'
						)}
						onFocus={() => handleInputFocus('gender')}
						onBlur={() => handleInputBlur('gender')}
						containerStyle={{ borderRadius: 10 }}
						itemTextStyle={styles.itemTextStyle}
						itemContainerStyle={styles.itemContainerStyle}
						closeModalWhenSelectedItem
						onChange={item => setData({ ...data, gender: item.value })}
					/>
				</View>

				<View style={styles.inputContainer}>
					<Text style={styles.label}>
						{getStaticTranslation('profile.dob', 'Date of Birth')}
					</Text>
					<Pressable
						onPress={() => {
							setDatePickerVisibility(true);
						}}
						style={
							isFocused.dob
								? [styles.dateInput, styles.border]
								: styles.dateInput
						}
					>
						<Text
							style={[
								styles.dateText,
								{
									color: data?.dob
										? theme.palette.black[100]
										: theme.palette.grey[300],
								},
							]}
						>
							{data.dob
								? data.dob
								: getStaticTranslation('profile.eg dob', 'DD/MM/YYYY')}
						</Text>
						<CalendarSvg />
					</Pressable>
					<DobPicker
						isVisible={isDatePickerVisible}
						onConfirm={date => {
							const dob = date.date.format('DD/MM/YYYY');
							setData({ ...data, dob: dob });
							setDatePickerVisibility(false);
						}}
						onCancel={() => {
							setDatePickerVisibility(false);
						}}
						initialDate={data?.dob}
					/>
				</View>

				<View style={styles.inputContainer}>
					<Text style={styles.label}>
						{getStaticTranslation('profile.country', 'Country')}
					</Text>

					<Dropdown
						style={[
							isFocused.country
								? [styles.dropDown, styles.border]
								: styles.dropDown,
							{ width: '100%' },
						]}
						data={countries}
						labelField='name'
						valueField='name'
						placeholder={
							data.country ||
							getStaticTranslation('profile.eg country', 'Eg. Malaysia')
						}
						placeholderStyle={[
							styles.placeholderStyle,
							{
								color: data.dialCode
									? theme.palette.primary
									: theme.palette.grey[300],
							},
						]}
						selectedTextStyle={styles.selectedTextStyle}
						inputSearchStyle={styles.inputSearchStyle}
						searchPlaceholder={getStaticTranslation(
							'profile.search',
							'Search...'
						)}
						onFocus={() => handleInputFocus('country')}
						onBlur={() => handleInputBlur('country')}
						search
						searchField={'name'}
						itemTextStyle={styles.itemTextStyle}
						itemContainerStyle={styles.itemContainerStyle}
						closeModalWhenSelectedItem
						onChange={item =>
							setData({ ...data, dialCode: item.dialCode, country: item.name })
						}
						dropdownPosition='top'
					/>
				</View>
				<View style={styles.deleteButton}>
					<Pressable
						style={[styles.list1, { backgroundColor: theme.palette.red[300] }]}
						onPress={() => setModalVisible(true)}
					>
						{/* <View style={styles.list1Container}> */}
						<DeleteSvg color={theme.palette.secondary} />
						<Text style={styles.list1Text}>
							{getStaticTranslation('profile.delete', 'Delete Account')}
						</Text>
						{/* </View> */}
					</Pressable>
				</View>
			</ScrollView>
			<View style={styles.buttonContainer}>
				<Pressable
					style={[
						styles.button,
						{
							backgroundColor: !data.displayName
								? theme.palette.grey[200]
								: theme.palette.green[200],
						},
					]}
					disabled={!data.displayName}
					onPress={save}
				>
					<Text
						style={[
							styles.buttonText,
							{
								color: !data.displayName
									? theme.palette.grey[600]
									: theme.palette.green[700],
							},
						]}
					>
						{getStaticTranslation('profile.save', 'Save')}
					</Text>
				</Pressable>
			</View>
			<DeleteAccountModal
				visible={modalVisible}
				onClose={event => {
					setModalVisible(false);
				}}
			/>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			display: 'flex',
			flexDirection: 'column',
			zIndex: 9999,
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		profilePic: {
			position: 'absolute',
			width: height < 500 ? 80 : 130,
			height: height < 500 ? 80 : 130,
		},
		profileImage: {
			width: '100%',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		noImage: {
			backgroundColor: theme.palette.grey[200],
			width: '100%',
			height: '100%',
			borderRadius: !isWeb ? 100 : '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			gap: 10,
			padding: 15,
			height: isWeb ? height * 0.5 : 'auto',
			paddingHorizontal: 25,
		},
		label: {
			fontFamily: fontFamily.poppins[500],
			color: theme.palette.black[100],
			fontSize: fontSize[18],
		},
		inputContainer: {
			display: 'flex',
			flexDirection: 'column',
			gap: 5,
		},
		input: {
			backgroundColor: theme.palette.secondary,
			padding: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: '100%',
			height: 50,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
		},
		border: {
			borderWidth: 2,
			borderColor: theme.palette.green[700],
		},
		inputSubContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			gap: 10,
		},
		dropDown: {
			height: 50,
			width: '20%',
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
			paddingHorizontal: 15,
			color: 'red',
		},
		placeholderStyle: {
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.grey[200],
		},
		selectedTextStyle: {
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[400],
		},
		itemTextStyle: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			marginVertical: -5,
		},
		itemContainerStyle: {
			gap: 0,
			color: 'green',
		},
		inputSearchStyle: {
			borderRadius: 10,
			height: 40,
			fontSize: fontSize[16],
			color: 'pink',
		},
		containerStyle: {
			width: '50%',
			borderRadius: 10,
		},
		dateInput: {
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary,
			padding: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			height: 50,
			justifyContent: 'space-between',
		},
		dateText: {
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			color: theme.palette.black[100],
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingBottom: 20,
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
		deleteButton: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
      marginTop: 10
		},
		list1: {
			width: '90%',
			backgroundColor: theme.palette.red[300],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},

		list1Text: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[18],
			color: theme.palette.secondary,
		},
	});
};
export default EditProfileScreen;
