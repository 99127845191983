import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Pressable,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { ArrowLeftSvg, HomeSvg } from './Svg';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { setMode } from '../redux/reducer';
import LabBackHomeModal from './LabBackHomeModal';
import { useTranslation } from './TranslationProvider';

const LabHeader = ({
	step,
	resultId,
	company,
	scenarios,
	scanLoad,
	isHome,
	isReport,
}) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, step);
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const [isResult, setIsResult] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const data = [
		{
			step: 1,
			title: getStaticTranslation('lab.lab', 'A.I. Laboratory'),
			previousPath: 'Lab',
		},
		{ step: 2, title: getStaticTranslation('lab.result', 'Results') },
		{
			step: 3,
			title: getStaticTranslation('lab.result details', 'Result Details'),
			previousPath: 'LabResult',
		},
	];
	const currentStepData = data.find(d => d.step === step);
	useEffect(() => {
		if (step === 1) {
			dispatch(setMode('dark'));
		} else {
			dispatch(setMode('light'));
		}
	}, [step]);

	return (
		<View style={styles.header}>
			{step !== 2 && (
				<Pressable
					onPress={() => {
						if (step === 1) {
							dispatch(setMode('dark'));
							if (resultId || scanLoad) {
								setIsResult(true);
							}
							setModalVisible(true);
						} else if (step === 3) {
							if (isHome) {
								dispatch(setMode('dark'));
								navigation.navigate('HomeTabs');
							} else if (isReport) {
								dispatch(setMode('dark'));
								navigation.navigate('HomeTabs', { screen: 'Report' });
							} else {
								navigation.navigate(currentStepData.previousPath, {
									data: { scenarios, resultId, company },
								});
							}
						}
					}}
				>
					<ArrowLeftSvg
						color={
							step === 1 ? theme.palette.secondary : theme.palette.green[700]
						}
						width={12}
						height={19}
					/>
				</Pressable>
			)}
			<Text style={styles.title}>{currentStepData.title}</Text>
			{step !== 1 && !isHome && !isReport && (
				<Pressable
					onPress={() => {
						setIsResult(true);
						setModalVisible(true);
					}}
				>
					<HomeSvg
						color={
							step === 1 ? theme.palette.secondary : theme.palette.green[700]
						}
					/>
				</Pressable>
			)}

			<LabBackHomeModal
				visible={modalVisible}
				onClose={() => setModalVisible(false)}
				isResult={isResult}
				onPress={() => {
					if (step === 1) {
						navigation.navigate(currentStepData.previousPath);
					} else {
						dispatch(setMode('dark'));
						navigation.navigate('Lab');
					}
				}}
				step={step}
			/>
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, step) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		header: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		title: {
			flex: 1,
			textAlign: align.center,
			color: step === 1 ? theme.palette.secondary : theme.palette.black[100],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingLeft: step === 2 ? 20 : 5,
		},
	});
};

export default LabHeader;
