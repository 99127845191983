import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  useWindowDimensions,
  Pressable,
  Linking,
} from "react-native";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import {
  DownloadSvg,
  RedTriageSvg,
  SadSvg,
  VitalsSvg,
} from "../../components/Svg";
import { useSelector } from "react-redux";
import { api, baseUrl } from "../../helpers/helper";
import moment from "moment";
import Condition from "../../components/Condition";
import _ from "lodash";
import Skeleton from "../../components/Skeleton";
import RiskCard from "../../components/RiskCard";
import AnimatedNumberComponent from "../../components/AnimatedNumber";
import InvitationModal from "../../components/InvitationModal";
import { useTranslation } from "../../components/TranslationProvider";
import { errorToast } from "../../components/Toast";
import { useFocusEffect } from "@react-navigation/native";

const OverviewScreen = ({ navigation, route }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width } = useWindowDimensions();
  const { getStaticTranslation, getTranslation } = useTranslation();
  const styles = cssStyles(isWeb, isAndroid, isIos, width);
  const { user } = useSelector((state) => state.app);
  const [latestResult, setLatestResult] = useState(null);
  const [details, setDetails] = useState(null);
  const [conditions, setConditions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const [labScenarios, setLabScenarios] = useState(null);
	
  useFocusEffect(
  	useCallback(() => {
    setLoading(true);
    getData();
  }, [user])
  );

  const getData = async () => {
    await api("results/latest/result")
      .then((res) => {
        if (res?.result) {
          setLatestResult(res.result);
          if (res.result?.condition !== null) {
            setDetails(res.result?.condition.result.details);
            setConditions(res.result?.condition.result.conditions);
          }
          if (res.result?.lab !== null) {
            setLabScenarios(res.result?.lab.result.scenarios);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        errorToast(error.message);
      });
  };

  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };

  const handlePress = () => {
    const url = `${baseUrl}/api/results/${latestResult.lab._id}/lab/pdf`;
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };
  return (
    <ScrollView
      style={styles.container}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      <Text style={styles.title}>
        {getStaticTranslation("overview.checker", "A.I. Symptom Checker")}
      </Text>
      {loading ? (
        <View style={styles.loadingContainer} onLayout={handleLayout}>
          <Skeleton height={40} width={containerWidth} borderRadius={20} />
          <Skeleton height={100} width={containerWidth} borderRadius={12} />
          <Skeleton height={200} width={containerWidth} borderRadius={12} />
        </View>
      ) : latestResult?.condition ? (
        <>
          <View style={styles.latestContainer}>
            {width >= 360 && (
              <Text style={styles.latestText}>
                {getStaticTranslation("overview.last check", "Last checked on")}{" "}
              </Text>
            )}
            <Text style={styles.latestDate}>
              {moment(latestResult.condition.createdAt).format(
                "DD MMMM YYYY, hh:mm A"
              )}
            </Text>
          </View>
          <View style={styles.vitalsContainer}>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.label}>
                  {getStaticTranslation("overview.blood", "Blood Pressure")}
                </Text>
                {details.bloodPressure ? (
                  <Text style={styles.value}>
                    <AnimatedNumberComponent
                      value={details.bloodPressure?.systolic}
                      styles={styles.valueRed}
                    />
                    <Text style={styles.valueRed}>/</Text>
                    <AnimatedNumberComponent
                      value={details.bloodPressure?.diastolic}
                      styles={styles.valueRed}
                    />
                    <Text style={styles.unitRed}>
                      {getStaticTranslation("overview.mmHg", "mmHg")}
                    </Text>
                  </Text>
                ) : (
                  <Text style={[styles.value, styles.valueRed]}>-</Text>
                )}
              </View>
              <View style={styles.column}>
                <Text style={styles.label}>
                  {getStaticTranslation("overview.pulse", "Pulse Rate")}
                </Text>
                {details.pulseRate ? (
                  <Text style={styles.value}>
                    <AnimatedNumberComponent
                      value={details.pulseRate}
                      styles={styles.valueOrange}
                    />
                    <Text style={styles.unitOrange}>
                      {getStaticTranslation("overview.bpm", "BPM")}
                    </Text>
                  </Text>
                ) : (
                  <Text style={[styles.value, styles.valueOrange]}>-</Text>
                )}
              </View>
            </View>
            <View style={styles.separator} />
            <View style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.label}>
                  {getStaticTranslation("overview.temp", "Body Temp.")}
                </Text>
                {details.temperature ? (
                  <Text style={styles.value}>
                    <AnimatedNumberComponent
                      value={details.temperature}
                      styles={styles.valueGreen}
                    />
                    <Text style={styles.unitGreen}>
                      {getStaticTranslation("overview.celsius", "°C")}
                    </Text>
                  </Text>
                ) : (
                  <Text style={[styles.value, styles.valueGreen]}>-</Text>
                )}
              </View>
              <View style={styles.column}>
                <Text style={styles.label}>
                  {getStaticTranslation("overview.oximetry", "Pulse Oximetry")}
                </Text>
                {details.pulseOximetry ? (
                  <Text style={styles.value}>
                    <AnimatedNumberComponent
                      value={details.pulseOximetry}
                      styles={styles.valueBlue}
                    />
                    <Text style={styles.valueBlue}>
                      {getStaticTranslation("overview.percent", "%")}
                    </Text>
                  </Text>
                ) : (
                  <Text style={[styles.value, styles.valueBlue]}>-</Text>
                )}
              </View>
            </View>
          </View>
          <View style={styles.conditionContainer}>
            {_.map(
              _.take(_.orderBy(conditions, ["score"], ["desc"]), 4),
              (condition, index) => {
                return (
                  <View key={index}>
                    <View key={index} style={styles.condition}>
                      <Condition
                        name={getTranslation(
                          condition,
                          "name",
                          condition?.name
                        )}
                        evidence={condition.evidence}
                        score={condition.score}
                      />
                    </View>
                    {index <
                      _.take(_.orderBy(conditions, ["score"], ["desc"]), 4)
                        .length -
                        1 && <View style={styles.separator} />}
                  </View>
                );
              }
            )}
            <View style={styles.pressableContainer}>
              <Pressable
                style={styles.pressable}
                onPress={() => {
                  navigation.navigate("Report");
                }}
              >
                <Text style={styles.pressableText}>
                  {getStaticTranslation("overview.view", "View More")}
                </Text>
              </Pressable>
            </View>
          </View>
        </>
      ) : (
        <>
          <View style={styles.noVitalsContainar}>
            <View>
              <VitalsSvg color={theme.palette.lightGreen[300]} />
            </View>
            <View style={styles.noVitalsTextContainer}>
              <Text style={styles.subTitle}>
                {getStaticTranslation(
                  "overview.no vitals",
                  "No Vitals Data Available"
                )}
              </Text>
              <Text style={styles.desc}>
                {getStaticTranslation(
                  "overview.no vitals info",
                  "No vitals information provided."
                )}
              </Text>
            </View>
          </View>
          <View style={styles.noDataContainer}>
            <View>
              <SadSvg width={72} height={70} />
            </View>
            <Text style={styles.subTitle}>
              {getStaticTranslation("overview.no data", "No Data Available")}
            </Text>
            <Text style={styles.desc}>
              {getStaticTranslation(
                "overview.no data info",
                "No result available from A.I Symptom Checker."
              )}
            </Text>
            <Pressable
              style={styles.pressable}
              onPress={() => {
                navigation.navigate("Checker");
              }}
            >
              <Text style={styles.pressableText}>
                {getStaticTranslation(
                  "overview.go to checker",
                  "Go to Symptom Checker"
                )}
              </Text>
            </Pressable>
          </View>
        </>
      )}

      <Text style={styles.title}>
        {getStaticTranslation("overview.lab", "A.I. Lab")}
      </Text>
      {loading ? (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
          }}
          onLayout={handleLayout}
        >
          <Skeleton height={40} width={containerWidth} borderRadius={20} />
          <Skeleton height={100} width={containerWidth} borderRadius={12} />
          <Skeleton height={30} width={150} borderRadius={12} />
        </View>
      ) : latestResult?.lab ? (
        <>
          <View style={styles.latestContainer}>
            {width >= 360 && (
              <Text style={styles.latestText}>
                {getStaticTranslation(
                  "overview.last update",
                  "Last updated on"
                )}{" "}
              </Text>
            )}
            <Text style={styles.latestDate}>
              {moment(latestResult.lab.createdAt).format(
                "DD MMMM YYYY, hh:mm A"
              )}
            </Text>
          </View>

          <View style={styles.labRemindContainer}>
            <View style={styles.labRemind}>
              <View>
                {_.isEmpty(labScenarios) ? (
                  <VitalsSvg color={theme.palette.green[200]} />
                ) : (
                  <RedTriageSvg color={theme.palette.red[300]} />
                )}
              </View>
              <View style={styles.labRemindTextContainer}>
                <Text
                  style={[
                    styles.labRemindSubTitle,
                    {
                      color: _.isEmpty(labScenarios)
                        ? theme.palette.green[200]
                        : theme.palette.red[300],
                    },
                  ]}
                >
                  {_.isEmpty(labScenarios)
                    ? getStaticTranslation(
                        "overview.good",
                        "You're in good health!"
                      )
                    : getStaticTranslation(
                        "overview.warning",
                        "Medical attention needed!"
                      )}
                </Text>
                <Text style={styles.labRemindDesc}>
                  {_.isEmpty(labScenarios)
                    ? getStaticTranslation(
                        "overview.good desc",
                        "No possible health risk detected."
                      )
                    : getStaticTranslation(
                        "overview.warning desc",
                        "Several possible health risk detected."
                      )}
                </Text>
              </View>
            </View>
          </View>

          {_.map(labScenarios, (scenario, index) => {
            return (
              <View key={index}>
                <RiskCard
                  resultId={latestResult._id}
                  scenarios={labScenarios}
                  company={{}}
                  isHome={true}
                  scenario={scenario}
                />
              </View>
            );
          })}

          <View style={styles.pressableContainer}>
            <Pressable style={styles.pressableDownload} onPress={handlePress}>
              <DownloadSvg
                width={18}
                height={16}
                color={theme.palette.secondary}
              />
              <Text style={styles.pressableText}>
                {getStaticTranslation("overview.download", "Download Report")}
              </Text>
            </Pressable>
          </View>
        </>
      ) : (
        <View style={styles.noDataContainer}>
          <View>
            <SadSvg width={72} height={70} />
          </View>
          <Text style={styles.subTitle}>
            {getStaticTranslation("overview.no lab", "No Data Available")}
          </Text>
          <Text style={styles.desc}>
            {getStaticTranslation(
              "overview.no lab info",
              "No result available from A.I. Lab."
            )}
          </Text>
          <Pressable
            style={styles.pressable}
            onPress={() => {
              navigation.navigate("Lab");
            }}
          >
            <Text style={styles.pressableText}>
              {getStaticTranslation("overview.go to lab", "Go to A.I. Lab")}
            </Text>
          </Pressable>
        </View>
      )}

      <InvitationModal invitationCode={route.params?.data} />
    </ScrollView>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.palette.lightGreen[100],
      paddingHorizontal: isWeb ? "5%" : 20,
    },
    title: {
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[16],
      color: theme.palette.grey[700],
      marginHorizontal: isWeb ? "3%" : 10,
      marginVertical: 5,
    },
    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: 10,
    },
    latestContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      flexWrap: "wrap",
      backgroundColor: theme.palette.green[200],
      borderRadius: 20,
      paddingVertical: "2%",
      marginBottom: "5%",
    },
    latestText: {
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[14],
      color: theme.palette.secondary,
    },
    latestDate: {
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[16],
      color: theme.palette.secondary,
    },
    vitalsContainer: {
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: "5%",
      paddingVertical: "5%",
      marginBottom: "3%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 20,
    },
    column: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    label: {
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[14],
      color: theme.palette.black[100],
      width: "50%",
      alignItems: "center",
    },
    value: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "50%",
      textAlign: align.right,
      // lineHeight: 18,
    },
    valueRed: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[20],
      color: theme.palette.red[300],
    },
    unitRed: {
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[14],
      color: theme.palette.red[300],
    },
    valueOrange: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[20],
      color: theme.palette.orange[200],
    },
    unitOrange: {
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[14],
      color: theme.palette.orange[200],
    },
    valueGreen: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[20],
      color: theme.palette.green[200],
    },
    unitGreen: {
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[14],
      color: theme.palette.green[200],
    },
    valueBlue: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[20],
      color: theme.palette.blue[300],
    },
    unitBlue: {
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[14],
      color: theme.palette.blue[300],
    },
    conditionContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: "5%",
      paddingVertical: "5%",
      marginBottom: "3%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    condition: {
      marginBottom: "3%",
    },
    separator: {
      borderBottomColor: theme.palette.grey[100],
      borderBottomWidth: 1,
      marginVertical: 10,
    },
    noVitalsContainar: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: isWeb ? "15%" : "10%",
      paddingVertical: "3%",
      marginBottom: "3%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    noVitalsTextContainer: {
      flex: 1,
      display: "flex",
      judtifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      marginLeft: "5%",
    },
    subTitle: {
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[16],
      color: theme.palette.grey[300],
    },
    desc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[14],
      color: theme.palette.grey[300],
    },
    noDataContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: isWeb ? "5%" : "2%",
      paddingVertical: isWeb ? "10%" : "5%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      marginBottom: "2%",
    },
    labRemindContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: "2%",
      paddingVertical: "5%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      marginBottom: "2%",
    },
    labRemind: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
    },
    labRemindTextContainer: {
      display: "flex",
      judtifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    labRemindSubTitle: {
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[16],
      lineHeight: 18,
    },
    labRemindDesc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[14],
      color: theme.palette.black[100],
      lineHeight: 18,
    },
    pressableContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    pressable: {
      marginTop: "3%",
      backgroundColor: theme.palette.green[700],
      borderRadius: 10,
      padding: 6,
      paddingHorizontal: 20,
    },
    pressableDownload: {
      marginTop: "3%",
      backgroundColor: theme.palette.green[700],
      borderRadius: 10,
      padding: 6,
      paddingHorizontal: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
      marginBottom: "5%",
    },
    pressableText: {
      color: theme.palette.secondary,
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[14],
    },
  });
};

export default OverviewScreen;
