import {
	View,
	Text,
	Platform,
	StyleSheet,
	Pressable,
	Modal,
	TextInput,
	useWindowDimensions,
} from 'react-native';
import React, { useState } from 'react';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import _ from 'lodash';
import { auth } from '../config/firebase';
import { errorToast, successToast, warningToast } from './Toast';
import {
	sendPasswordResetEmail,
	fetchSignInMethodsForEmail,
	signOut,
} from 'firebase/auth';
import { logout } from '../redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from './TranslationProvider';

const ForgotPassword = ({ isChange }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width } = useWindowDimensions();
	const styles = cssStyles(isWeb, isAndroid, isIos, width);
	const { user } = useSelector(state => state.app);
	const dispatch = useDispatch();
	const [isModalVisible, setModalVisible] = useState(false);
	const [email, setEmail] = useState(isChange ? user?.email : '');
	const { getStaticTranslation } = useTranslation();
	const validateEmail = email => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};
	const send = async () => {
		try {
			const isEmail = validateEmail(email);
			if (!isEmail) {
				throw new Error(
					getStaticTranslation(
						'forgotPassword.invalid email',
						'Please enter a valid email.'
					)
				);
			}

			fetchSignInMethodsForEmail(auth, email)
				.then(async result => {
					if (result.length !== 0) {
						if (result[0] !== 'google.com') {
							await sendPasswordResetEmail(auth, email)
								.then(async () => {
									successToast(
										getStaticTranslation(
											'forgotPassword.success',
											'Password reset email sent! Please check your inbox.'
										)
									);
									if (isChange) {
										await signOut(auth);
										dispatch(logout());
									}
								})
								.catch(error => {
									errorToast(error.message);
								});
						} else {
							setModalVisible(false);
							warningToast(
								getStaticTranslation(
									'forgotPassword.warning',
									'You are login using google account.'
								)
							);
						}
					} else {
						errorToast(
							getStaticTranslation('forgotPassword.error', 'Email not found!')
						);
					}
				})
				.catch(error => {
					errorToast(error.message);
				});
		} catch (error) {
			errorToast(error.message);
		}
	};
	return (
		<View>
			<Pressable onPress={() => setModalVisible(!isModalVisible)}>
				<Text style={styles.forgot}>
					{getStaticTranslation('auth.forgot', 'Forgot Password?')}
				</Text>
			</Pressable>
			<Modal visible={isModalVisible} transparent={true} animationType='fade'>
				<View
					style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
				>
					<Pressable
						style={styles.modalOverlay}
						activeOpacity={1}
						onPressOut={() => setModalVisible(false)}
					>
						<Pressable style={styles.modalContainer}>
							<Text style={styles.modalTitle}>
								{getStaticTranslation(
									'forgotPassword.forgot',
									'Forgot password?'
								)}
							</Text>
							<Text style={styles.modalSubtitle}>
								{_.capitalize(
									getStaticTranslation(
										'forgotPassword.desc',
										"Type your email below and we'll send you a password reset link."
									)
								)}
							</Text>
							<TextInput
								style={[styles.input, isWeb && { outlineStyle: 'none' }]}
								placeholder={getStaticTranslation(
									'forgotPassword.email',
									'Email'
								)}
								value={email}
								onChangeText={setEmail}
								keyboardType='email-address'
								autoCapitalize='none'
								placeholderTextColor={theme.palette.grey[300]}
							/>
							<Pressable style={styles.sendButton} onPress={send}>
								<Text style={styles.sendButtonText}>
									{getStaticTranslation('forgotPassword.send', 'Send Email')}
								</Text>
							</Pressable>
							<View style={styles.resendContainer} >
								<Text style={styles.resendText}>
									{_.capitalize(
										getStaticTranslation(
											'forgotPassword.no receive',
											"Didn't receive?"
										)
									)}
								</Text>
								<Pressable onPress={send}>
									<Text style={styles.resendLink}>
										{getStaticTranslation('forgotPassword.resend', 'Resend')}
									</Text>
								</Pressable>
							</View>
						</Pressable>
					</Pressable>
				</View>
			</Modal>
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		forgot: {
			fontFamily: fontFamily.poppins[500],
			color: theme.palette.blue[100],
			fontSize: fontSize[16],
			marginTop: 5,
			textAlign: align.right,
			textTransform: 'capitalize',
		},
		modalOverlay: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: `${theme.palette.primary}50`,
			width: '100%',
			maxWidth: isWeb ? 500 : width,
		},
		modalContainer: {
			width: '85%',
			backgroundColor: theme.palette.lightGreen[100],
			maxWidth: isWeb ? 500 : width,
			borderRadius: 12,
			padding: 20,
			alignItems: 'center',
		},
		modalTitle: {
			fontSize: fontSize[22],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
			textTransform: 'capitalize',
		},
		modalSubtitle: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.grey[300],
			textAlign: align.center,
			marginBottom: 20,
		},
		input: {
			backgroundColor: theme.palette.secondary,
			padding: 10,
			paddingHorizontal: 20,
			borderRadius: 15,
			width: '100%',
			height: 50,
			marginBottom: 10,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
		},
		sendButton: {
			width: '100%',
			height: 50,
			backgroundColor: theme.palette.green[700],
			borderRadius: 15,
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: 10,
		},
		sendButtonText: {
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.secondary,
			textTransform: 'capitalize',
		},
		resendContainer: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		resendText: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.grey[700],
		},
		resendLink: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.blue[100],
			marginLeft: 5,
			textDecorationLine: 'underline',
			textTransform: 'capitalize',
		},
	});
};

export default ForgotPassword;
