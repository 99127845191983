import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Pressable,
	TextInput,
	FlatList,
	ScrollView,
} from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import StepBar from '../../components/StepBar';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import { ArrowRightSvg, RotateSvg, SearchSvg } from '../../components/Svg';
import Body from '../../components/Body';
import { api } from '../../helpers/helper';
import _ from 'lodash';
import { setChecker, setMode, setSide } from '../../redux/reducer';
import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { errorToast } from '../../components/Toast';

const SymptomSelectionScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { checker, side } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const [area, setArea] = useState(checker?.symptom?.area||'general');
	const [symptoms, setSymptoms] = useState([]);
	const [search, setSearch] = useState('');
	const [isFocus, setIsFocus] = useState(false);
	const scrollViewRef = useRef(null);
	const symptomListRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [containerWidth, setContainerWidth] = useState(0);
	const [areaOld, setAreaOld] = useState('');
	const [fullSymptoms, setFullSymptoms] = useState();
	useFocusEffect(
		useCallback(() => {
			if (checker?.symptom?.area) {
				setArea(checker?.symptom?.area)
			}
			dispatch(setMode('light'));
		}, [dispatch])
	);
	const scrollToSymptomList = () => {
		if (symptomListRef.current && scrollViewRef.current) {
			symptomListRef.current.measureLayout(scrollViewRef.current, (x, y) => {
				scrollViewRef.current.scrollTo({ y, animated: true });
			});
		}
	};
	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			setSearch('');
			if (checker?.symptom?.area) {
				setArea(checker?.symptom?.area)
			}
			dispatch(setSide('front'));
		});

		return unsubscribe;
	}, [navigation, checker?.symptom?.area]);

	useEffect(() => {
		if (checker?.gender) {
			if (area !== '') {
				setSearch('');
				setAreaOld(area);
				setLoading(true);
				getData(area)
			}
else {
	setSymptoms([]);
}
}
		
	}, [area, checker?.gender]);
	const getData = async (area) => {
		await api('symptoms', 'get', {
			area,
			gender: checker?.gender,
		})
			.then(res => {
				setFullSymptoms(res.result);
				setSymptoms(res.result);

				if (area !== 'general' || areaOld !== '') {
					scrollToSymptomList();
				}
			})
			.finally(() => setLoading(false)).catch((error) => {
				errorToast(error.message)
			})
	
	}
	//for select and search
	const handeSearch = value => {
		setSearch(value);
		if (value !== '') {
			const out = [];
			_.map(fullSymptoms, option => {
				let name = getTranslation(option, 'name', option?.name);
				if (_.includes(_.lowerCase(name), _.lowerCase(value))) {
					out.push(option);
				}
			});

			setSymptoms(out);
		} else {
			setSymptoms(fullSymptoms);
		}
	};
	// for search only which will include all symptoms
	// useEffect(() => {
	// 	if (search !== '') {
	// 		setLoading(true);
	// 		setArea('');
	// 		api('symptoms/search', 'get', {
	// 			search,
	// 		})
	// 			.then(res => {
	// 				setSymptoms(res.result);
	// 				scrollToSymptomList();
	// 			})
	// 			.finally(() => setLoading(false));
	// 	} else {
	// 		setArea(areaOld);
	// 		if (areaOld === '') {
	// 			setSymptoms([]);
	// 		}
	// 	}
	// }, [search]);

	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width);
	};

	return (
		<LightBackground>
			<View style={styles.container}>
				<StepBar step={4} />
				<Text style={styles.question}>
					{getStaticTranslation(
						'checker.desc symptom',
						'Select a symptom that bothers you the most'
					)}
				</Text>
				<ScrollView
					style={styles.scrollContainer}
					contentContainerStyle={styles.scrollContent}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					ref={scrollViewRef}
				>
					<View style={styles.buttonContainer}>
						<Pressable
							onPress={() => dispatch(setSide('front'))}
							style={[
								styles.button,
								{
									backgroundColor:
										side === 'front'
											? theme.palette.green[700]
											: theme.palette.secondary,
								},
							]}
						>
							<RotateSvg
								color={
									side === 'front'
										? theme.palette.secondary
										: theme.palette.green[700]
								}
							/>
							<Text
								style={[
									styles.buttonText,
									{
										color:
											side === 'front'
												? theme.palette.secondary
												: theme.palette.green[700],
									},
								]}
							>
								{getStaticTranslation('checker.frontside', 'Rotate Front')}
							</Text>
						</Pressable>
						<Pressable
							onPress={() => dispatch(setSide('back'))}
							style={[
								styles.button,
								{
									backgroundColor:
										side === 'back'
											? theme.palette.green[700]
											: theme.palette.secondary,
								},
							]}
						>
							<RotateSvg
								color={
									side === 'back'
										? theme.palette.secondary
										: theme.palette.green[700]
								}
							/>
							<Text
								style={[
									styles.buttonText,
									{
										color:
											side === 'back'
												? theme.palette.secondary
												: theme.palette.green[700],
									},
								]}
							>
								{getStaticTranslation('checker.backside', 'Rotate Back')}
							</Text>
						</Pressable>
					</View>

					<View style={styles.bodyContainer}>
						<Body area={area} setArea={setArea} />
					</View>

					<View style={styles.areaContainer}>
						<Text style={styles.areaText}>
							{getStaticTranslation(`checker.${area}`, _.startCase(area)) ||
								getStaticTranslation('checker.no select', 'No Selection')}
						</Text>
					</View>

					<View
						style={styles.symptomContainer}
						ref={symptomListRef}
						onLayout={handleLayout}
					>
						<Text style={styles.listTitle}>
							{getStaticTranslation(
								'checker.list symptoms',
								'List of Symptoms'
							)}
						</Text>
						<View
							style={
								isFocus
									? [styles.searchContainer, styles.border]
									: [styles.searchContainer]
							}
						>
							<TextInput
								// onChangeText={value => setSearch(value)}
								onChangeText={value => handeSearch(value)}
								value={search}
								style={[styles.searchInput,isWeb && { outlineStyle: 'none' }]}
								placeholder={getStaticTranslation('checker.search', 'Search')}
								placeholderTextColor={theme.palette.grey[300]}
								onFocus={() => setIsFocus(true)}
								onBlur={() => setIsFocus(false)}
							/>

							<Pressable style={styles.toggleIcon}>
								<SearchSvg />
							</Pressable>
						</View>
						{loading ? (
							<View style={styles.loadingContainer}>
								<Skeleton
									height={60}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={60}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={60}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={60}
									width={containerWidth}
									borderRadius={12}
								/>
							</View>
						)
							:
							symptoms.length > 0 ? (
							<FlatList
								data={symptoms}
								keyExtractor={(item, index) => item._id}
								renderItem={({ item }) => {
									return (
										<Pressable
											style={styles.symptomButton}
											onPress={() => {
												if (
													checker?.symptom &&
													checker.symptom._id !== item._id
												) {
													dispatch(setChecker({ severity: null }));
													dispatch(setChecker({ painScore: null }));
													dispatch(setChecker({ presentations: null }));
													dispatch(setChecker({ condition: null }));
												}

												dispatch(setChecker({ symptom: item }));

												navigation.navigate('Duration');
											}}
										>
											<Text style={styles.symptomText}>
												{getTranslation(item, 'name', item.name)}
											</Text>
											<View style={styles.arrowContainer}>
												<ArrowRightSvg color={theme.palette.secondary} />
											</View>
										</Pressable>
									);
								}}
								scrollEnabled={false}
								contentContainerStyle={{ paddingBottom: 20 }}
							/>
							)
								: (
							<View style={styles.noResultContainer}>
								<Text style={styles.noResultText}>
									{area
										? getStaticTranslation(`checker.${area}`, _.startCase(area))
										: getStaticTranslation('checker.no select', 'No Selection')}
								</Text>
								<Text style={styles.noResultDesc}>
									{getStaticTranslation(
										'checker.no select desc',
										'No symptoms result available.'
									)}
								</Text>
							</View>
						)}
					</View>
				</ScrollView>
			</View>
		</LightBackground>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		question: {
			color: theme.palette.green[700],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingBottom: isWeb ? 10 : 0,
			textAlign: align.center,
		},
		scrollContainer: {
			flex: 1,
		},
		scrollContent: {
			alignItems: 'center',
			flexGrow: 1,
			height: isWeb ? (height < 570 ? 300 : 500) : 'auto',
		},
		bodyContainer: {
			height: isWeb ? (height < 570 ? 350 : 550) : 400,
			width: '100%',
		},
		buttonContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			marginVertical: width < 360 ? 5 : 10,
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
			marginHorizontal: width>360? -10: 0
		},
		button: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			paddingVertical: width < 360 ? 5 : 10,
			paddingHorizontal: width < 360 ? 10 : 20,
			borderRadius: 10,
			width: isWeb?  "60%": "50%",
			height :"100%"
		},
		buttonText: {
			marginLeft: 10,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			flexWrap: "wrap"
		},
		areaContainer: {
			width: '80%',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary,
			borderRadius: 20,
			paddingVertical: isIos || isWeb ? 10 : 5,

			marginVertical: 20,
		},
		areaText: {
			textAlign: align.center,
			width: '100%',
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[500],
			color: theme.palette.green[700],
		},
		symptomContainer: {
			width: '100%',
		},
		listTitle: {
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
			marginBottom: 10,
			color: theme.palette.green[700],
			textAlign: align.left,
		},
		searchContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginVertical: 10,
			justifyContent: 'space-between',
			backgroundColor: theme.palette.secondary,
			width: '100%',
			borderRadius: 10,
		},
		border: {
			borderWidth: 2,
			borderColor: theme.palette.green[700],
		},
		searchInput: {
			backgroundColor: theme.palette.secondary,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: '80%',
			height: 50,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
		},
		toggleIcon: {
			color: theme.palette.grey[300],
			marginHorizontal: 10,
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 10,
		},
		symptomButton: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			backgroundColor: theme.palette.green[200],
			padding: 15,
			borderRadius: 15,
			marginVertical: 5,
		},
		symptomText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			textAlign: align.left,
			width: '90%',
		},
		arrowContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: 5,
			alignItems: 'center',
		},
		noResultContainer: {
			display: 'flex',
			alignItems: 'center',
			marginVertical: 10,
		},
		noResultText: {
			fontSize: fontSize[16],
			color: theme.palette.grey[400],
			fontFamily: fontFamily.poppins[500],
			textAlign: align.center,
		},
		noResultDesc: {
			fontSize: fontSize[14],
			color: theme.palette.grey[400],
			fontFamily: fontFamily.poppins[300],
			textAlign: align.center,
		},
	});
};

export default SymptomSelectionScreen;
