import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
  Pressable,
  Linking,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import LightBackground from "../../components/LightBackground";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import RiskCard from "../../components/RiskCard";
import _ from "lodash";
import {
  DownloadSvg,
  KeepUpSvg,
  RedTriageSvg,
  UploadSvg,
  VitalsSvg,
} from "../../components/Svg";
import LabHeader from "../../components/LabHeader";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../helpers/helper";
import UploadNewReportModal from "../../components/UploadNewReportModal";
import Skeleton from "../../components/Skeleton";
import { useTranslation } from "../../components/TranslationProvider";
import { useFocusEffect } from "@react-navigation/native";
import { setMode } from "../../redux/reducer";
import Disclaimer from "../../components/Disclaimer";

const LabResultScreen = ({ navigation, route }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width, height } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const [resultId, setResultId] = useState();
  const [scenarios, setScenarios] = useState();
  const [containerWidth, setContainerWidth] = useState(0);
  const [company, setCompany] = useState();
  const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("light"));
    }, [dispatch])
  );
  useEffect(() => {
    if (route.params.data) {
      setScenarios(route.params.data.scenarios);
      setResultId(route.params.data.resultId);
      setCompany(route.params.data.company);
      setLoading(false);
    }
  }, [route, navigation]);
  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width - 25);
  };

  const handleDownload = () => {
    if (resultId) {
      const url = `${baseUrl}/api/results/${resultId}/lab/pdf`;
      if (isWeb) {
        window.open(url, "_blank");
      } else {
        Linking.openURL(url);
      }
    }
  };

  return (
    <LightBackground>
      <View style={styles.container} onLayout={handleLayout}>
        <LabHeader step={2} resultId={resultId} scenarios={scenarios} />
        <View style={styles.formContainer}>
          <ScrollView
            style={styles.scrollContainer}
            contentContainerStyle={styles.scrollContent}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            {loading ? (
              <View style={styles.loadingContainer}>
                <Skeleton
                  height={40}
                  width={containerWidth}
                  borderRadius={12}
                />
                <Skeleton
                  height={100}
                  width={containerWidth}
                  borderRadius={12}
                />
                <Skeleton
                  height={150}
                  width={containerWidth}
                  borderRadius={12}
                />
                <Skeleton
                  height={150}
                  width={containerWidth}
                  borderRadius={12}
                />
                <Skeleton
                  height={100}
                  width={containerWidth}
                  borderRadius={12}
                />
              </View>
            ) : (
              <>
                <View style={styles.labRemindContainer}>
                  <View style={styles.labRemind}>
                    <View>
                      {_.isEmpty(scenarios) ? (
                        <VitalsSvg color={theme.palette.green[200]} />
                      ) : (
                        <RedTriageSvg color={theme.palette.red[300]} />
                      )}
                    </View>
                    <View style={styles.labRemindTextContainer}>
                      <Text
                        style={[
                          styles.labRemindSubTitle,
                          {
                            color: _.isEmpty(scenarios)
                              ? theme.palette.green[200]
                              : theme.palette.red[300],
                          },
                        ]}
                      >
                        {_.isEmpty(scenarios)
                          ? getStaticTranslation(
                              "lab.good",
                              "You're in good health!"
                            )
                          : getStaticTranslation(
                              "lab.warning",
                              "Medical attention needed!"
                            )}
                      </Text>
                      <Text style={styles.labRemindDesc}>
                        {_.isEmpty(scenarios)
                          ? getStaticTranslation(
                              "lab.good desc",
                              "No possible health risk detected."
                            )
                          : getStaticTranslation(
                              "lab.warning desc",
                              "Several possible health risk detected."
                            )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.disclaimerContainer}>
                  <Disclaimer />
                </View>
                {_.isEmpty(scenarios) ? (
                  <View style={styles.keepupContainer}>
                    <Text style={styles.noDataTitle}>
                      {getStaticTranslation("lab.keep up", "Keep Up!")}
                    </Text>
                    <View style={styles.keepupIcon}>
                      <KeepUpSvg
                        width={height < 570 ? 80 : 108}
                        height={height < 570 ? 80 : 101}
                      />
                    </View>
                    <Text style={styles.keepupTitle}>
                      {getStaticTranslation(
                        "lab.keep desc1",
                        "Congrats and Stay Healthy!"
                      )}
                    </Text>
                    <Text style={styles.keepupDesc}>
                      {getStaticTranslation(
                        "lab.keep desc2",
                        "The key to live happy life is maintaining your healthy lifestyle."
                      )}
                    </Text>
                  </View>
                ) : (
                  <>
                    {_.map(scenarios, (scenario, index) => {
                      return (
                        <View key={index}>
                          <RiskCard
                            resultId={resultId}
                            scenarios={scenarios}
                            company={company}
                            scenario={scenario}
                          />
                        </View>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </ScrollView>
        </View>

        <View style={styles.buttonContainer}>
          <Pressable
            style={[
              styles.button,
              { backgroundColor: theme.palette.green[700] },
            ]}
            onPress={handleDownload}
          >
            <DownloadSvg
              width={26}
              height={24}
              color={theme.palette.secondary}
            />
            <Text style={styles.buttonText}>
              {getStaticTranslation("lab.download", "Download Report")}
            </Text>
          </Pressable>
          <Pressable
            style={[
              styles.button,
              { backgroundColor: theme.palette.lightGreen[300] },
            ]}
            onPress={() => {
              setModalVisible(true);
            }}
          >
            <UploadSvg
              width={32}
              height={24}
              color={theme.palette.green[700]}
            />
            <Text style={styles.secondaryButtonText}>
              {getStaticTranslation("lab.upload", "Upload New Report")}
            </Text>
          </Pressable>
        </View>
      </View>
      <UploadNewReportModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        company={company}
      />
    </LightBackground>
  );
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 20,
      paddingVertical: 10,
      // display: 'flex',
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: 10,
    },
    formContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      // height: isAndroid || isIos ? '30%' : 'auto',
      gap: height < 570 ? 5 : 10,
    },
    scrollContainer: {
      flex: 1,
      // height: '100%',
      width: "100%",
      // display:"flex",
      paddingHorizontal: 5,
      paddingVertical: 10,
    },
    scrollContent: {
      flexGrow: 1,
      height: isWeb ? height * 0.5 : "auto",
    },
    labRemindContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: "2%",
      paddingVertical: "5%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      marginBottom: "2%",
    },
    labRemind: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      gap: height < 570 ? 5 : 10,
    },
    labRemindTextContainer: {
      display: "flex",
      judtifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    labRemindSubTitle: {
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[16],
      lineHeight: 18,
    },
    labRemindDesc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[14],
      color: theme.palette.black[100],
      lineHeight: 18,
    },
    buttonContainer: {
      // flex:1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      gap: 10,
      paddingVertical: 20,
    },
    button: {
      width: "100%",

      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 15,
      borderRadius: 10,
      gap: 10,
    },
    buttonText: {
      color: theme.palette.secondary,
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[16],
    },

    secondaryButtonText: {
      color: theme.palette.green[700],
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[16],
    },
    keepupContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      backgroundColor: theme.palette.secondary,
      marginTop: height < 570 ? 5 : 10,
      borderRadius: 12,
      paddingHorizontal: isWeb ? "5%" : "2%",
      paddingVertical: isWeb ? "10%" : "10%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    noDataTitle: {
      fontFamily: fontFamily.poppins[700],
      fontSize: fontSize[28],
      color: theme.palette.lightGreen[400],
      marginBottom: height < 570 ? 5 : 10,
    },
    keepupIcon: {
      display: "flex",
      alignItems: "center",
      marginBottom: height < 570 ? 10 : 30,
    },
    keepupTitle: {
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[20],
      color: theme.palette.grey[400],
    },
    keepupDesc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[18],
      color: theme.palette.grey[400],
      textAlign: align.center,
    },
    disclaimerContainer: {
      width: "100%",
      display: "flex",
    },
  });
};
export default LabResultScreen;
