import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
	Text,
	View,
	Platform,
	StyleSheet,
	useWindowDimensions,
	Linking,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import HomeScreen from '../screens/Home/HomeScreen';
import ProfileScreen from '../screens/Profile/ProfileScreen';
import CheckerScreen from '../screens/Checker/CheckerScreen';
import LabScreen from '../screens/Lab/LabScreen';
import ReportScreen from '../screens/Report/ReportScreen';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import {
	CheckerSvg,
	HomeSvg,
	LabSvg,
	ProfileSvg,
	ReportSvg,
} from '../components/Svg';
import { useTranslation } from '../components/TranslationProvider';

const Tab = createBottomTabNavigator();

const HomeTabs = ({ navigation, route }) => {
	const insets = useSafeAreaInsets();
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height, insets);
	const renderIcon = (SvgComponent, focused) => (
		<SvgComponent
			color={focused ? theme.palette.green[200] : theme.palette.secondary}
		/>
	);

	const renderLabel = (label, focused) => (
		<Text
			numberOfLines={1}
			ellipsizeMode='tail'
			style={[
				styles.tabLabelText,
				{ color: focused ? theme.palette.green[200] : theme.palette.secondary },
			]}
		>
			{label}
		</Text>
	);

	return (
		<Tab.Navigator
			initialRouteName='Home'
			screenOptions={({ route }) => ({
				headerShown: false,
				tabBarStyle: styles.container,
				tabBarLabelPosition: 'below-icon',
			})}
		>
			<Tab.Screen
				name='Checker'
				component={CheckerScreen}
				options={{
					tabBarIcon: ({ focused }) => renderIcon(CheckerSvg, focused),
					tabBarLabel: ({ focused }) =>
						renderLabel(
							getStaticTranslation('basic.checker', 'Checker'),
							focused
						),
				}}
			/>
			<Tab.Screen
				name='Lab'
				component={LabScreen}
				options={{
					tabBarIcon: ({ focused }) => renderIcon(LabSvg, focused),
					tabBarLabel: ({ focused }) =>
						renderLabel(getStaticTranslation('basic.lab', 'Lab'), focused),
				}}
			/>
			<Tab.Screen
				name='Home'
				component={HomeScreen}
				options={{
					tabBarIcon: ({ focused }) => (
						<View
							style={{
								padding: height < 500 ? 10 : 15,
								backgroundColor: focused
									? theme.palette.green[200]
									: theme.palette.secondary,
								borderRadius: 50,
							}}
						>
							<HomeSvg color={theme.palette.green[700]} />
						</View>
					),
					tabBarLabel: () => null,
				}} 
			/>
			<Tab.Screen
				name='Report'
				component={ReportScreen}
				options={{
					tabBarIcon: ({ focused }) => renderIcon(ReportSvg, focused),
					tabBarLabel: ({ focused }) =>
						renderLabel(
							getStaticTranslation('basic.report', 'Report'),
							focused
						),
				}}
			/>
			<Tab.Screen
				name='Profile'
				component={ProfileScreen}
				options={{
					tabBarIcon: ({ focused }) => renderIcon(ProfileSvg, focused),
					tabBarLabel: ({ focused }) =>
						renderLabel(
							getStaticTranslation('basic.profile', 'Profile'),
							focused
						),
				}}
			/>
		</Tab.Navigator>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height, insets) => {
	const fontSize = getFontSize();
	const align = getAlign()
	return StyleSheet.create({
		container: {
			backgroundColor: theme.palette.green[700],
			height: height < 500 ? 60 : isIos?height<700?80: 100: 80,
			borderTopLeftRadius: 20,
			borderTopRightRadius: 20,
			paddingHorizontal: 20,
			marginBottom: isIos ? height<700?insets.top: insets.bottom : 0,
			borderTopWidth: 0,
			elevation: 0,
			shadowOpacity: 0,
		},
		tabLabelText: {
			fontSize: fontSize[12],
			textAlign: align.center,
			textTransform: 'capitalize',
			overflow: 'hidden',
			marginTop: height < 500 ? -3 : -13,
			marginBottom: height < 500 ? 3 : 13,
			fontFamily: fontFamily.poppins[400],
		},
	});
};

export default HomeTabs;
