import {
	View,
	Text,
	Platform,
	StyleSheet,
	useWindowDimensions,
	Pressable,
	ScrollView,
	Linking,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../components/Background';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import Language from '../../components/Language';
import { api, baseUrl } from '../../helpers/helper';
import moment from 'moment';
import {
	ArrowRightSvg,
	DownloadSvg,
	HeartLineSvg,
	LogoSvg,
	SadSvg,
	SmallLogoSvg,
} from '../../components/Svg';
import { useFocusEffect } from '@react-navigation/native';
import Skeleton from '../../components/Skeleton';
import ReportLab from '../../components/ReportLab';
import { useTranslation } from '../../components/TranslationProvider';
import { setMode } from '../../redux/reducer';
import { errorToast } from '../../components/Toast';

const ReportScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { user } = useSelector(state => state.app);
	const dispatch = useDispatch();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);
	const [isCondition, setIsCondition] = useState(true);
	const [symptomReports, setSymptomReports] = useState();
	const [labReports, setLabReports] = useState();

	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('dark'));
		}, [dispatch])
	);
	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width);
	};

	// useFocusEffect(
	// 	useCallback(() => {
			useEffect(()=>{
			setLoading(true);
			if (isCondition) {
				api('results/type/conditions').then(async res => {
					if (res) {
						const symptom = await getData(res.result);
						setSymptomReports(symptom);
						setLoading(false);
					}
				}).catch(
					(error) => {
						errorToast(error.message)
					})
			} else {
				api('results/type/lab').then(async res => {
					if (res) {
						const lab = await getData(res.result);
						setLabReports(lab);
						setLoading(false);
					}
				}).catch(
					(error) => {
						errorToast(error.message)
					})
			}
		}, [isCondition, user])
	// );

	const getData = async data => {
		const sortedData = data.sort(
			(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
		);
		const grouped = sortedData.reduce((acc, item) => {
			const year = moment(item.createdAt).format('YYYY');
			if (!acc[year]) {
				acc[year] = [];
			}
			acc[year].push(item);
			return acc;
		}, {});

		// Sort years in descending order
		const sortedYears = Object.keys(grouped).sort((a, b) => b - a);

		const sortedGrouped = sortedYears.map(year => ({
			year,
			data: grouped[year],
		}));

		return sortedGrouped;
	};

	const handleDownload = id => {
		const url = `${baseUrl}/api/conditions/pdf/${id}`;
		if (Platform.OS === 'web') {
			window.open(url, '_blank');
		} else {
			Linking.openURL(url);
		}
	};
	return (
		<Background>
			<View style={styles.languageContainer}>
				<Language isAuth={false} />
			</View>
			<View style={styles.header}>
				<View style={{ paddingRight: 10 }}>
					{isWeb && width < 360 ? (
						<SmallLogoSvg width={49} height={30} />
					) : (
						<LogoSvg
							width={isAndroid ? 150 : isIos ? 173 : '100%'}
							height={isWeb ? '60%' : 30}
						/>
					)}
				</View>
				<Text style={styles.title}>
					{getStaticTranslation('report.report', 'Reports')}
				</Text>
			</View>
			<View style={styles.buttonContainer}>
				<Pressable
					onPress={() => setIsCondition(true)}
					style={[
						styles.button,
						{
							backgroundColor: isCondition
								? theme.palette.green[200]
								: theme.palette.secondary,
						},
					]}
				>
					<Text
						style={[
							styles.buttonText,
							{
								color: isCondition
									? theme.palette.secondary
									: theme.palette.green[700],
							},
						]}
					>
						{getStaticTranslation('report.checker', 'Symptom Checker')}
					</Text>
				</Pressable>
				<Pressable
					onPress={() => setIsCondition(false)}
					style={[
						styles.button,
						{
							backgroundColor: !isCondition
								? theme.palette.green[200]
								: theme.palette.secondary,
						},
					]}
				>
					<Text
						style={[
							styles.buttonText,
							{
								color: !isCondition
									? theme.palette.secondary
									: theme.palette.green[700],
							},
						]}
					>
						{getStaticTranslation('report.lab', 'A.I Laboratory')}
					</Text>
				</Pressable>
			</View>
			<View style={styles.contentContainer}>
				<ScrollView
					style={styles.container}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				>
					{loading ? (
						<View style={styles.loadingContainer} onLayout={handleLayout}>
							<Skeleton height={40} width={containerWidth} borderRadius={20} />
							<Skeleton height={100} width={containerWidth} borderRadius={12} />
							<Skeleton height={100} width={containerWidth} borderRadius={12} />
							<Skeleton height={100} width={containerWidth} borderRadius={12} />
							<Skeleton height={100} width={containerWidth} borderRadius={12} />
							<Skeleton height={100} width={containerWidth} borderRadius={12} />
						</View>
					) : (
						<>
							{isCondition ? (
								symptomReports && symptomReports.length > 0 ? (
									symptomReports.map((yearGroup, yearIndex) => (
										<View key={yearIndex}>
											<View style={styles.yearContainer}>
												<Text style={styles.yearText}>{yearGroup.year}</Text>
												<View style={styles.yearLine} />
											</View>
											{yearGroup.data.map((report, reportIndex) => (
												<View key={reportIndex} style={styles.reportContainer}>
													<View style={styles.waveContainer}>
														<HeartLineSvg />
													</View>
													<View
														style={[
															styles.dateContainer,
															{
																backgroundColor:
																	reportIndex % 2 === 0
																		? theme.palette.green[600]
																		: theme.palette.green[700],
															},
														]}
													>
														<Text style={styles.date}>
															{moment(report.createdAt)
																.format('DD MMM')
																.toUpperCase()}
														</Text>
														<Text style={styles.time}>
															{moment(report.createdAt).format('hh:mm A')}
														</Text>
													</View>
													<View style={styles.infoContainer}>
														<Pressable
															style={styles.info}
															onPress={() => {
																navigation.navigate('CheckerNavigator', {
																	screen: 'Result',
																	params: {
																		data: {
																			conditions: report.result.conditions,
																			reportId: report._id,
																			checker: report.result.details,
																			isReport: true,
																		},
																	},
																});
															}}
														>
															<Text style={styles.reportText}>
																{getStaticTranslation(
																	'report.symptom',
																	'Symptom Report'
																)}
															</Text>
															<ArrowRightSvg color={theme.palette.grey[500]} />
														</Pressable>
														<Pressable
															style={styles.downloadButton}
															onPress={() => {
																handleDownload(report._id);
															}}
														>
															<DownloadSvg
																width={21}
																height={18}
																color={theme.palette.blue[200]}
															/>
															<Text style={styles.downloadText}>
																{getStaticTranslation(
																	'report.download',
																	'Download Report'
																)}
															</Text>
														</Pressable>
													</View>
												</View>
											))}
										</View>
									))
								) : (
									<View style={styles.noDataContainer}>
										<View style={styles.icon}>
											<SadSvg width={102} height={100} />
										</View>
										<Text style={styles.subTitle}>
											{getStaticTranslation(
												'report.no data',
												'No Data Available'
											)}
										</Text>
										<Text style={styles.desc}>
											{getStaticTranslation(
												'report.no checker info',
												'No result available from A.I Symptom Checker.'
											)}
										</Text>
										<Pressable
											style={styles.pressable}
											onPress={() => {
												navigation.navigate('Checker');
											}}
										>
											<Text style={styles.pressableText}>
												{getStaticTranslation(
													'report.go to checker',
													'Go to Symptom Checker'
												)}
											</Text>
										</Pressable>
									</View>
								)
							) : labReports && labReports.length > 0 ? (
								labReports.map((yearGroup, yearIndex) => (
									<View key={yearIndex}>
										<View style={styles.yearContainer}>
											<Text style={styles.yearText}>{yearGroup.year}</Text>
											<View style={styles.yearLine} />
										</View>
										{yearGroup.data.map((report, reportIndex) => (
											<View key={reportIndex}>
												<ReportLab index={reportIndex} report={report} />
											</View>
										))}
									</View>
								))
							) : (
								<View style={styles.noDataContainer}>
									<View style={styles.icon}>
										<SadSvg width={102} height={100} />
									</View>
									<Text style={styles.subTitle}>
										{getStaticTranslation(
											'report.no data',
											'No Data Available'
										)}
									</Text>
									<Text style={styles.desc}>
										{getStaticTranslation(
											'report.no lab info',
											'No result available from A.I. Lab.'
										)}
									</Text>
									<Pressable 
										style={styles.pressable}
										onPress={() => {
											navigation.navigate('Lab');
										}}
									>
										<Text style={styles.pressableText}>
											{getStaticTranslation(
												'report.go to lab',
												'Go to A.I. Lab'
											)}
										</Text>
									</Pressable>
								</View>
							)}
						</>
					)}
				</ScrollView>
			</View>
		</Background>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		languageContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
			paddingTop: isAndroid || isIos ? 10 : 20,
			paddingRight: isAndroid || isIos ? 15 : 30,
			width: '100%',
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 10,
		},
		header: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			paddingHorizontal: isWeb ? '5%' : 30,
			paddingVertical: isAndroid || isIos ? 15 : 0,
			paddingTop: isWeb ? 10:0,
			maxWidth: isWeb ? (width > 500 ? 500 : width) : width,
		},
		title: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[700],
			fontSize: fontSize[20],
			lineHeight: 30,
		},
		buttonContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
			marginHorizontal: isWeb ? '5%' : 20,
		},
		button: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			width: '50%',
			paddingVertical: width < 360 ? 5 : 10,
			borderRadius: 10,
			height: "100%"
		},
		buttonText: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
			textAlign: align.center,
			width: '100%',
		},
		contentContainer: {
			flex: 1,
			backgroundColor: theme.palette.lightGreen[100],
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			marginTop: height < 570 ? 10 : 20,
		},
		container: {
			flex: 1,
			backgroundColor: theme.palette.lightGreen[100],
			paddingHorizontal: isWeb ? '5%' : 20,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			paddingVertical: 20,
			marginBottom: 10,
		},
		noDataContainer: {
			flex: 1,

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',

			paddingHorizontal: isWeb ? '5%' : '2%',
			paddingVertical: isWeb ? '10%' : '5%',
		},
		icon: {
			paddingBottom: 20,
		},
		subTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[20],
			color: theme.palette.grey[300],
			textAlign: align.center,
		},
		desc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[18],
			color: theme.palette.grey[300],
			textAlign: align.center,
		},

		pressable: {
			marginTop: '3%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 20,
		},
		pressableText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
		},
		yearContainer: {
			flexDirection: 'row',
			width: '100%',
			paddingHorizontal: 20,
			marginBottom: 20,
			gap: 15,
			alignItems: 'center',
			justifyContent: 'center',
		},
		yearText: {
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[800],
			color: theme.palette.green[700],
		},
		yearLine: {
			height: 1,
			width: '100%',
			flex: 1,
			backgroundColor: theme.palette.green[200],
			alignItems: 'center',
			justifyContent: 'center',
		},
		reportContainer: {
			flexDirection: 'row',
			backgroundColor: theme.palette.secondary,
			borderRadius: 10,
			marginBottom: 15,
			width: '100%',
			alignItems: 'center',
			overflow: 'hidden',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
		},
		dateContainer: {
			justifyContent: 'center',
			paddingVertical: 30,
			paddingHorizontal: 30,
			overflow: 'hidden',
			marginTop: -10,
			marginBottom: -10,

			borderTopRightRadius: 25,
			borderBottomRightRadius: 90,
		},
		date: {
			color: theme.palette.secondary,
			fontSize: fontSize[30],
			fontFamily: fontFamily.poppins[800],
		},
		time: {
			color: theme.palette.secondary,
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[500],
		},
		infoContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			paddingRight: 15,
			paddingLeft: 10,

			height: isWeb ? '100%' : 'auto',
		},
		info: {
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			maxWidth: '90%',
		},
		reportText: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
			marginRight: 10,
		},
		downloadButton: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: 10,
			flex: 1,
			marginBottom: -8,
			marginLeft: -5,
			maxWidth: '80%',
		},
		downloadText: {
			color: theme.palette.blue[200],
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[600],
			textDecorationLine: 'underline',
		},

		waveContainer: {
			position: 'absolute',
			bottom: 0,
			// left: 0,
			right: 0,
		},
	});
};
export default ReportScreen;
