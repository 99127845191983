import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	ScrollView,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import LabHeader from '../../components/LabHeader';
import _ from 'lodash';
import LabResultQnA from '../../components/LabResultQnA';
import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { setMode } from '../../redux/reducer';
import Disclaimer from '../../components/Disclaimer';

const LabResultDetailScreen = ({ navigation, route }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const [resultId, setResultId] = useState();
	const [scenarios, setScenarios] = useState();
	const [scenario, setScenario] = useState();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const [containerWidth, setContainerWidth] = useState(0);
	const [company, setCompany] = useState();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const [isHome, setIsHome] = useState();
	const [isReport, setIsReport] = useState();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch()
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	useEffect(() => {
		if (route.params.data) {
			setIsHome(route.params.data.isHome);
			setIsReport(route.params.data.isReport);
			setScenario(route.params.data.scenario);
			if (!route.params.data.isHome) {
				setScenarios(route.params.data.scenarios);
				setResultId(route.params.data.resultId);
				setCompany(route.params.data.company);
			}
			setLoading(false);
		}
	}, [route, navigation]);

	const handleLayout = event => {
		const { width } = event.nativeEvent.layout;
		setContainerWidth(width - 25);
	};
	const getColor = value => {
		if (value === 'high') {
			return {
				backgroundColor: `${theme.palette.red[300]}10`,
				color: theme.palette.red[300],
			};
		} else if (value === 'low') {
			return {
				backgroundColor: `${theme.palette.green[400]}10`,
				color: theme.palette.green[400],
			};
		} else if (value === 'positive') {
			return {
				backgroundColor: `${theme.palette.orange[500]}10`,
				color: theme.palette.orange[500],
			};
		} else {
			return {
				backgroundColor: `${theme.palette.blue[200]}10`,
				color: theme.palette.blue[200],
			};
		}
	};
	const getReferenceValue = (referenceType, reference, value) => {
		if (referenceType === 'numbersRange') {
			const { from, to } = reference;
			if (from && to) {
				return `> ${from} ${getStaticTranslation('lab.and', 'and')} < ${to}`;
			} else if (from) {
				return `> ${from}`;
			} else if (to) {
				return `< ${to}`;
			}
		}
		return _.startCase(referenceType.split(/(?=[A-Z])/).join(' '));
	};

	const getDiagnosis = text => {
		return text
			.trim()
			.split('\n\n')
			.map(item => {
				const [title, description] = item.includes('：')?item.split('：'): item.split(': ');
				return {

					title: title.trim(),
					description: description.trim(),
				};
			});
	};

	const getPossibe = data => {
		if (data) {
			const titleMatch = data.match(/\*\*(.+?)\*\*/);
			const title = titleMatch ? titleMatch[1].trim() : '';
			const possibleMatch = data.match(/-\s(.+?)(?=\n\n|\*)/s);
			const possible = possibleMatch
				? possibleMatch[0]
					.split('\n- ')
					.map(item => item.replace(/^- /, '').trim())
					.filter(item => item)
				: [];
			const descMatch = data
				.split(/\*\*(.+?)\*\*/)
				.pop()
				.split(/\*\*(.+?)\*\*/)[0]
				.trim();
			const descSplit = descMatch.match(/\*(.+?)\*/s);
			const desc = descSplit ? descSplit[1].trim() : '';

			return {
				title: _.startCase(_.lowerCase(title)),
				possible,
				desc,
			};
		}
	};

	const qna = data => {
		const result = [];
		const sections = data
			.split('```\n')
			.map(section => section.trim())
			.filter(section => section !== '');
		for (let i = 0; i < sections.length; i += 2) {
			const question = sections[i].replace(/```/g, '').trim();
			const answer = sections[i + 1] ? sections[i + 1].trim() : '';
			result.push({ question, answer });
		}
		return result;
	};

	return (
		<LightBackground>
			<View style={styles.container} onLayout={handleLayout}>
				<LabHeader
					step={3}
					resultId={resultId}
					scenarios={scenarios}
					company={company}
					isHome={isHome}
					isReport={isReport}
				/>
				<View style={styles.formContainer}>
					<ScrollView
						style={styles.scrollContainer}
						contentContainerStyle={styles.scrollContent}
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
					>
						{loading ? (
							<View style={styles.loadingContainer}>
								<Skeleton
									height={200}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={200}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={200}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={50}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={50}
									width={containerWidth}
									borderRadius={12}
								/>
								<Skeleton
									height={40}
									width={containerWidth}
									borderRadius={12}
								/>
							</View>
						) : (
							<>
								<View style={styles.testContainer}>
									<Text style={styles.testTitle}>
										{getStaticTranslation('lab.lab test', 'Laboratory Tests')}
									</Text>
									{_.map(scenario?.btTypes, (item, index) => {
										const referenceKey = Object.keys(
											item.btTypeInfo.reference
										)[0];

										return (
											<View style={styles.itemContainer} key={index}>
												<Text style={styles.btType}>
													{getTranslation(
														item.btTypeInfo,
														'name',
														item.btTypeInfo?.name
													)}
												</Text>
												<View style={styles.resultContainer}>
													{/* <Text style={styles.resultTitle}>Result</Text>
                          <Text style={styles.resutNumber}>3.63 mmol/l</Text> */}
													<View
														style={[
															styles.labelContainer,
															{
																backgroundColor: getColor(item.value)
																	.backgroundColor,
															},
														]}
													>
														<Text
															style={[
																styles.labelText,
																{ color: getColor(item.value).color },
															]}
														>
															{_.upperCase(
																getStaticTranslation(
																	`lab.${item.value}`,
																	item.value
																)
															)}
														</Text>
													</View>
												</View>
												<View style={styles.referenceContainer}>
													<View style={styles.referenceTitleContainer}>
														<Text style={styles.referenceTitle}>
															{getStaticTranslation(
																'lab.reference',
																'Reference Value'
															)}
														</Text>
													</View>
													<View style={styles.refValue}>
														<Text style={styles.referenceValue}>
															{getReferenceValue(
																item.btTypeInfo?.referenceType,
																item.btTypeInfo.reference[referenceKey]
															)}
														</Text>
													</View>
												</View>
												{index < scenario?.btTypes.length - 1 && (
													<View style={styles.separator} />
												)}
											</View>
										);
									})}
								</View>
								{scenario?.diagnosis && (
									<View style={styles.testContainer}>
										<Text style={styles.testTitle}>
											{getStaticTranslation('lab.diagnosis', 'Diagnosis')}
										</Text>
										{_.map(
											scenario?.diagnosis &&
											getDiagnosis(
												getTranslation(
													scenario,
													'diagnosis',
													scenario?.diagnosis
												)
											),
											(item, index) => {
												return (
													<View style={styles.itemContainer} key={index}>
														<View style={styles.diagnosisContainer}>
															<Text style={styles.diagnosisTitle}>
																{item.title}
															</Text>
														</View>
														<Text style={styles.diagnosisDescription}>
															{item.description}
														</Text>
														{index <
															getDiagnosis(scenario?.diagnosis).length - 1 && (
																<View style={styles.separator} />
															)}
													</View>
												);
											}
										)}
									</View>
								)}
								{scenario?.causes && (
									<View style={styles.testContainer}>
										<Text style={styles.testTitle}>
											{
												getPossibe(
													getTranslation(scenario, 'causes', scenario?.causes)
												)?.title
											}
										</Text>
										<View style={styles.causesContainer}>
											{_.map(
												getPossibe(
													getTranslation(scenario, 'causes', scenario?.causes)
												)?.possible,
												(item, index) => {

													return (
														<View style={styles.possibleContainer} key={index}>
															<Text style={styles.diagnosisTitle}>{item}</Text>
															{index <
																getPossibe(scenario?.causes).possible.length -
																1 && <View style={styles.separator} />}
														</View>
													);
												}
											)}
										</View>
										<Text style={styles.testDesc}>
											{
												getPossibe(
													getTranslation(scenario, 'causes', scenario?.causes)
												)?.desc
											}
										</Text>
									</View>
								)}
								<Disclaimer/>
								{scenario?.faq && (
									<>
										<View style={styles.qnaContainer}>
											<Text style={styles.qnaTitle}>
												{getStaticTranslation('lab.how', 'How can we')} {'\n'}
												<Text style={styles.qnaTitle2}>
													{getStaticTranslation('lab.help', 'help you?')}
												</Text>
											</Text>
										</View>
										{_.map(
											scenario?.faq &&
											qna(getTranslation(scenario, 'faq', scenario?.faq)),
											(item, index) => {
												return (
													<View key={index}>
														<LabResultQnA
															question={item.question}
															answer={item.answer}
														/>
													</View>
												);
											}
										)}
									</>
								)}

							</>
						)}
					</ScrollView>
				</View>
			</View>
		</LightBackground>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			flex: 1,
			paddingHorizontal: 20,
			paddingVertical: 10,
			// display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			height: '100%',
			paddingBottom: isWeb ? 15 : 10,
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 10,
		},
		formContainer: {
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			// height: isAndroid || isIos ? '30%' : 'auto',
			gap: 10,
		},
		scrollContainer: {
			flex: 1,
			// height: '100%',
			width: '100%',
			// display:"flex",
			// paddingHorizontal: 5,
			paddingVertical: 10,
			paddingBottom: isWeb ? '15%' : 50,
		},
		scrollContent: {
			flexGrow: 1,
			height: isWeb ? height * 0.6 : 'auto',
			gap: 10,
			paddingBottom: isWeb ? '15%' : 50,
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 5,
			width: '100%',
		},
		testContainer: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
			width: '100%',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: isWeb ? '5%' : '3%',
			paddingVertical: isWeb ? '10%' : '5%',
			gap: 10,
		},
		testTitle: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.black[100],
		},
		itemContainer: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
			flex: 1,
			width: '100%',
			gap: 5,
		},
		btType: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
		},
		resultContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
		},
		resultTitle: {
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.black[100],
		},
		resutNumber: {
			fontSize: fontSize[14],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
		},
		labelContainer: {
			borderRadius: 5,
			width: '35%',
			alignItems: 'center',
		},
		labelText: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
		},
		referenceContainer: {
			borderRadius: 15,
			backgroundColor: theme.palette.lightGreen[100],
			flex: 1,
			display: 'flex',
			width: '100%',
			gap: 10,
			paddingBottom: 10,
		},
		referenceTitleContainer: {
			width: width < 600 ? width * 0.5 : width * 0.15,
			borderRadius: 18,
			backgroundColor: theme.palette.green[200],
			paddingHorizontal: 20,
			paddingVertical: 10,
		},
		referenceTitle: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[14],
			color: theme.palette.secondary,
		},
		refValue: {
			flex: 1,
			width: '100%',
		},
		referenceValue: {
			width: '100%',
			paddingHorizontal: 15,
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			color: theme.palette.black[100],
			paddingVertical: 2,
		},
		diagnosisContainer: {
			backgroundColor: theme.palette.green[600],
			borderRadius: 20,
			paddingHorizontal: 20,
			paddingVertical: 5,
		},
		diagnosisTitle: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[14],
		},
		diagnosisDescription: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			textAlign: align.justify,
			paddingHorizontal: 10,
		},
		causesContainer: {
			width: '100%',
			borderRadius: 12,
			backgroundColor: theme.palette.green[600],
			paddingHorizontal: 20,
			paddingVertical: 10,
		},
		possibleContainer: {
			width: '100%',
		},
		testDesc: {
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			textAlign: align.justify,
			paddingHorizontal: 10,
		},
		qnaContainer: {
			marginTop: 25,
			paddingHorizontal: 10,
		},
		qnaTitle: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[40],
			// lineHeight: 40,
		},
		qnaTitle2: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[40],
			lineHeight: 40,
		},
	});
};
export default LabResultDetailScreen;
