import { Platform, useWindowDimensions } from 'react-native';

// src/theme.js
export const theme = {
	palette: {
		primary: '#000000',
		secondary: '#ffffff',
		lightGreen: {
			100: '#e5f8f5',
			200: '#befffd',
			300: '#80C5C3',
			400: '#66AAAA',
			500: '#6BD7AA',
		},
		green: {
			100: '#03c0b7',
			200: '#00c0b7',
			300: '#00a6a0',
			400: '#187E3E',
			500: '#018C52',
			600: '#007171',
			700: '#003139',
		},
		blue: {
			100: '#4285F4',
			200: '#1A73E8',
			300: '#0012B9',
		},
		lightRed: {
			100: '#DF9799',
			200: '#E66C6E',
			300: '#FF4B7C',
		},
		red: {
			100: '#FF0000',
			200: '#BF3034',
			300: '#D73333',
			400: '#941B22',
		},
		orange: {
			100: '#ffdd99',
			200: '#FF9900',
			300: '#F99C59',
			400: '#D76D09',
			500: '#F66A13',
		},
		lightYellow: {
			100: '#FFE380',
		},
		yellow: {
			100: '#FFC700',
			200: '#FFD600',
		},
		lime: {
			100: '#79F285',
		},
		grey: {
			100: '#e9e9e9',
			200: '#E5E5E5',
			300: '#aaaaaa',
			400: '#909090',
			500: '#797979',
			600: '#616161',
			700: '#494d4c',
		},
		black: {
			100: '#222222',
		},
	},
};

export const fontFamily = {
	poppins: {
		100: 'Poppins_100Thin',
		200: 'Poppins_200ExtraLight',
		300: 'Poppins_300Light',
		400: 'Poppins_400Regular',
		500: 'Poppins_500Medium',
		600: 'Poppins_600SemiBold',
		700: 'Poppins_700Bold',
		800: 'Poppins_800ExtraBold',
		900: 'Poppins_900Black',
	},
	italic: {
		100: 'Poppins_100Thin_Italic',
		200: 'Poppins_200ExtraLight_Italic',
		300: 'Poppins_300Light_Italic',
		400: 'Poppins_400Regular_Italic',
		500: 'Poppins_500Medium_Italic',
		600: 'Poppins_600Regular_Italic',
		700: 'Poppins_700Bold_Italic',
		800: 'Poppins_800ExtraBold_Italic',
		900: 'Poppins_900Black_Italic',
	},
};

export const getFontSize = () => {
	const { width } = useWindowDimensions();
	return {
		10: width < 360 ? 8 : 10,
		11: width < 360 ? 9 : 11,
		12: width < 360 ? 10 : 12,
		13: width < 360 ? 11 : 13,
		14: width < 360 ? 12 : 14,
		15: width < 360 ? 13 : 15,
		16: width < 360 ? 13 : 16,
		17: width < 360 ? 14 : 17,
		18: width < 360 ? 14 : 18,
		19: width < 360 ? 15 : 19,
		20: width < 360 ? 15 : 20,
		21: width < 360 ? 16 : 21,
		22: width < 360 ? 16 : 22,
		23: width < 360 ? 17 : 23,
		24: width < 360 ? 17 : 24,
		25: width < 360 ? 18 : 25,
		26: width < 360 ? 18 : 26,
		27: width < 360 ? 19 : 27,
		28: width < 360 ? 19 : 28,
		29: width < 360 ? 20 : 29,
		30: width < 360 ? 20 : 30,
		40: width < 360 ? 30 : 40,
		80: width < 360 ? 50 : 80,
	};
};


export const getAlign = () => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	return {
		left:  "left",
		center: "center",
		right: "right",
		justify: isAndroid||isIos? "left": "justify"
	}

}