import React, { useEffect, useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Modal,
	Pressable,
	TextInput,
	FlatList,
	Platform,
	useWindowDimensions,
} from 'react-native';
import { useSelector } from 'react-redux';
import { fontFamily, getFontSize, theme } from '../theme';
import { AddSvg, SearchSvg } from './Svg';
import { api } from '../helpers/helper';
import _ from 'lodash';
import { useTranslation } from './TranslationProvider';

const IllnessModal = ({ visible, onClose, onAdd, filter }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const [isFocus, setIsFocus] = useState(false);
	const [illness, setIllness] = useState();
	const [fullIllness, setFullIllness] = useState();

	const [search, setSearch] = useState('');
	useEffect(() => {
		setSearch('');
		getData();
	}, []);
	const getData = () => {
		api('known-illnesses').then(res => {
			setFullIllness(_.filter(res.result, { type: filter }));
			setIllness(_.filter(res.result, { type: filter }));
		});
	};
	const handeSearch = value => {
		setSearch(value);
		if (value !== '') {
			const out = [];
			_.map(fullIllness, option => {
				let name = getTranslation(option, 'name', option?.name);
				if (_.includes(_.lowerCase(name), _.lowerCase(value))) {
					out.push(option);
				}
			});

			setIllness(out);
		} else {
			setIllness(fullIllness);
		}
	};
	const renderItem = ({ item, index }) => (
		<Pressable
			style={styles.conditionItem}
			key={index}
			onPress={() => onAdd(item)}
		>
			<Text style={styles.conditionText}>
				{getTranslation(item, 'name', item.name)}
			</Text>

			<View style={styles.addIcon}>
				<AddSvg
					color1={theme.palette.green[700]}
					color2={theme.palette.secondary}
					width={20}
					height={20}
				/>
			</View>
		</Pressable>
	);

	return (
		<Modal
			visible={visible}
			animationType='slide'
			transparent={true}
			onRequestClose={onClose}
		>
			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
				<Pressable
					style={[styles.modalOverlay, isWeb && { outlineStyle: 'none' }]}
					onPress={onClose}
				>
					<View style={styles.modalContainer}>
						<View style={styles.modalHeader}>
							<Text style={styles.modalTitle}>
								{getStaticTranslation(
									'checker.illness',
									'Underlying Medical Conditions'
								)}
							</Text>
						</View>
						<View style={styles.headerContainer}>
							<Pressable
								style={
									isFocus
										? [
												styles.searchContainer,
												styles.border,
												{
													marginTop: 0,
													width: isAndroid || isIos ? '80%' : '100%',
												},
												isWeb && { outlineStyle: 'none' },
										  ]
										: [
												styles.searchContainer,
												{
													marginTop: 0,
													width: isAndroid || isIos ? '80%' : '100%',
												},
												isWeb && { outlineStyle: 'none' },
										  ]
								}
							>
								<TextInput
									onChangeText={handeSearch}
									value={search}
									style={[
										styles.searchInput,
										isWeb && { outlineStyle: 'none' },
									]}
									placeholder={getStaticTranslation('checker.search', 'Search')}
									placeholderTextColor={theme.palette.grey[300]}
									onFocus={() => setIsFocus(true)}
									onBlur={() => setIsFocus(false)}
								/>

								<Pressable style={styles.toggleIcon}>
									<SearchSvg />
								</Pressable>
							</Pressable>
						</View>

						<FlatList
							data={illness}
							renderItem={renderItem}
							keyExtractor={item => item._id}
							contentContainerStyle={styles.listContainer}
							showsVerticalScrollIndicator={false}
							ListEmptyComponent={() => (
								<View style={styles.emptyContainer}>
									<Text style={styles.emptyText}>
										{getStaticTranslation(
											'checker.no result',
											'No results found'
										)}
									</Text>
								</View>
							)}
						/>
					</View>
				</Pressable>
			</View>
		</Modal>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	return StyleSheet.create({
		modalOverlay: {
			flex: 1,
			justifyContent: 'flex-end',
			alignItems: 'center',
			backgroundColor: `${theme.palette.primary}50`,
			// outlineStyle: 'none',
		},
		modalContainer: {
			width: '100%',
			minHeight: '90%',
			maxHeight: '90%',
			maxWidth: isWeb ? 500 : width,
			backgroundColor: theme.palette.lightGreen[100],
			borderTopLeftRadius: 10,
			borderTopRightRadius: 10,
			alignItems: 'center',
		},

		modalHeader: {
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center',
			paddingVertical: 10,
			paddingTop: 25,
		},
		modalTitle: {
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.green[700],
		},
		headerContainer: {
			paddingHorizontal: isWeb ? 20 : 10,
			marginBottom: 10,
			width: isWeb ? width : 'auto',
			maxWidth: isWeb ? 500 : width,
		},
		searchContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: 10,
			justifyContent: 'space-between',
			backgroundColor: theme.palette.secondary,
			width: '100%',
			borderRadius: 10,
			// outlineStyle: 'none'
		},
		border: {
			borderWidth: 2,
			borderColor: theme.palette.green[700],
		},
		searchInput: {
			backgroundColor: theme.palette.secondary,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: '100%',
			height: 50,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
		},
		toggleIcon: {
			color: theme.palette.grey[300],
			marginHorizontal: 10,
		},
		listContainer: {
			width: isWeb ? width : 'auto',
			maxWidth: isWeb ? 500 : width,
			backgroundColor: theme.palette.secondary,
			paddingHorizontal: isWeb ? 20 : 10,
			minHeight: isWeb ? '100%' : '90%',
		},
		emptyContainer: {
			backgroundColor: theme.palette.secondary,
			minHeight: isWeb ? '100%' : '90%',
			justifyContent: 'center',
			alignItems: 'center',
			width: width,
		},
		emptyText: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.grey[500],
		},
		conditionItem: {
			width: '100%',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingVertical: 10,
			paddingHorizontal: 10,
			borderBottomWidth: 1,
			borderBottomColor: theme.palette.grey[100],
		},
		conditionText: {
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.black[100],
			paddingHorizontal: 8,
			paddingTop: 5,
			width: '80%',
		},
		addIcon: {
			paddingRight: isWeb ? 0 : 8,
		},
	});
};

export default IllnessModal;
