import React from 'react';
import { Platform, StatusBar, View } from 'react-native';
import { theme } from '../theme';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

const isIos = Platform.OS === 'ios';

const CustomStatusBar = ({ barStyle, backgroundColor }) => {
	const insets = useSafeAreaInsets();

	return (
		<>
			{isIos ? (
				<View style={{ height: insets.top, backgroundColor: backgroundColor }}>
					<StatusBar
						barStyle={barStyle}
						hidden={false}
						backgroundColor={backgroundColor}
						translucent={true}
					/>
				</View>
			) : (
				<StatusBar
					barStyle={barStyle}
					hidden={false}
					backgroundColor={backgroundColor}
					translucent={true}
				/>
			)}
		</>
	);
};

export const StatusBarCustom = () => {
	const { mode } = useSelector(state => state.app);
	return (
		<CustomStatusBar
			barStyle={mode === 'dark' ? 'light-content' : 'dark-content'}
			backgroundColor={
				mode === 'dark'
					? theme.palette.green[700]
					: theme.palette.lightGreen[100]
			}
		/>
	);
};
