import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	StyleSheet,
	Pressable,
	ScrollView,
} from 'react-native';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LightBackground from '../../components/LightBackground';
import StepBar from '../../components/StepBar';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import _ from 'lodash';
import { AddSvg, FileAddSvg, MinusSvg } from '../../components/Svg';
import { setChecker, setMode } from '../../redux/reducer';
import IllnessModal from '../../components/IlnessModal';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';

const FamilyHistoryScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation, getTranslation } = useTranslation();
	const { checker } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('light'));
		}, [dispatch])
	);
	const removeCondition = id => {
		if (!checker.familyIllness) return;
		const updatedConditions = { ...checker.familyIllness };
		delete updatedConditions[id];
		dispatch(setChecker({ familyIllness: updatedConditions }));
	};

	const handleAddCondition = item => {
		const familyIllness = checker?.familyIllness || {};
		if (!familyIllness[item._id]) {
			const updatedConditions = { ...familyIllness, [item._id]: item };
			dispatch(setChecker({ familyIllness: updatedConditions }));
		}
		setModalVisible(false);
	};
	return (
		<LightBackground>
			<View style={styles.container}>
				<StepBar step={3} />
				<Text style={styles.desc}>
					{getStaticTranslation(
						'checker.desc family',
						'Add or make changes of your family history if any.'
					)}
				</Text>

				<Text style={styles.question}>
					{getStaticTranslation(
						'checker.question family',
						'Do you have any known conditions that run in your family?'
					)}
				</Text>
				<View style={styles.familyIllnessContainer}>
					{!checker?.familyIllness ||
					Object.keys(checker?.familyIllness).length === 0 ? (
						<View style={styles.noData}>
							<View>
								<FileAddSvg />
							</View>
							<View style={styles.noDataTextContainer}>
								<Text style={styles.subTitle}>
									{getStaticTranslation('checker.no data', 'No Data Available')}
								</Text>
								<Text style={styles.desc}>
									{getStaticTranslation(
										'checker.no family info',
										'No family history added.'
									)}
								</Text>
							</View>
						</View>
					) : (
						<ScrollView
							style={styles.familyIllnessScrollContainer}
							contentContainerStyle={styles.scrollContent}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
						>
							{_.map(
								Object.entries(checker.familyIllness),
								([id, illness], index) => (
									<View key={id}>
										<View style={styles.conditionItem} key={illness._id}>
											<Text
												style={styles.conditionText}
												numberOfLines={1}
												ellipsizeMode='tail'
											>
												{getTranslation(illness, 'name', illness.name)}
											</Text>
											<Pressable onPress={() => removeCondition(id)}>
												<MinusSvg color={theme.palette.red[300]} />
											</Pressable>
										</View>
										{index < Object.keys(checker.familyIllness).length - 1 && (
											<View style={styles.separator} />
										)}
									</View>
								)
							)}
						</ScrollView>
					)}
					<View style={styles.pressableContainer}>
						<Pressable
							style={styles.iconPressable}
							onPress={() => setModalVisible(true)}
						>
							<AddSvg
								color1={theme.palette.secondary}
								color2={theme.palette.green[700]}
								width={16}
								height={16}
							/>
							<Text style={styles.pressableText}>
								{getStaticTranslation('checker.add condition', 'Add Condition')}
							</Text>
						</Pressable>
					</View>
				</View>
			</View>
			<View style={styles.buttonContainer}>
				<Pressable
					style={[
						styles.button,
						{
							backgroundColor: theme.palette.green[200],
						},
					]}
					onPress={() => navigation.navigate('Symptom')}
				>
					<Text
						style={[
							styles.buttonText,
							{
								color: theme.palette.green[700],
							},
						]}
					>
						{getStaticTranslation('checker.next', 'Next')}
					</Text>
				</Pressable>
			</View>

			<IllnessModal
				visible={modalVisible}
				onClose={() => setModalVisible(false)}
				onAdd={handleAddCondition}
				filter={'familyHistory'}
			/>
		</LightBackground>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		container: {
			paddingHorizontal: 20,
			paddingVertical: 10,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		desc: {
			color: theme.palette.black[100],
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
			paddingVertical: isWeb ? 10 : 0,
			textAlign: align.justify,
		},
		question: {
			color: theme.palette.green[700],
			fontSize: fontSize[20],
			fontFamily: fontFamily.poppins[700],
			paddingBottom: 10,
			textAlign: align.center,
		},
		familyIllnessContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary,
			borderRadius: 12,
			paddingHorizontal: '5%',
			paddingVertical: '5%',
			marginBottom: '3%',
			elevation: 3,
			shadowColor: theme.palette.primary,
			shadowOffset: { width: 0, height: 3 },
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			width: '100%',
		},
		noData: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			width: '100%',
			flexGrow: 1,
		},
		noDataTextContainer: {
			display: 'flex',
			judtifyContent: 'flex-start',
			alignItems: 'flex-start',
			flexDirection: 'column',
			marginLeft: '5%',
		},
		subTitle: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
			color: theme.palette.grey[300],
		},
		desc: {
			fontFamily: fontFamily.poppins[300],
			fontSize: fontSize[14],
			color: theme.palette.grey[300],
		},
		familyIllnessScrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			height: isWeb ? height * 0.3 : 'auto',
		},
		conditionItem: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		conditionText: {
			fontSize: fontSize[16],
			color: theme.palette.black[100],
			fontFamily: fontFamily.poppins[400],
			width: '90%',
		},
		separator: {
			borderBottomColor: theme.palette.grey[100],
			borderBottomWidth: 1,
			marginVertical: 10,
		},
		pressableContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		iconPressable: {
			marginTop: '5%',
			backgroundColor: theme.palette.green[700],
			borderRadius: 10,
			padding: 6,
			paddingHorizontal: 25,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		pressableText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[14],
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		button: {
			width: '80%',
			marginTop: '3%',
			backgroundColor: theme.palette.green[200],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '5%',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
	});
};

export default FamilyHistoryScreen;
