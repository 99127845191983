import React, { useEffect, useRef, useState } from 'react';
import { View, Animated, StyleSheet } from 'react-native';
import { theme } from '../theme';
import { SmallLogoSvg } from './Svg';

const Loading = () => {
  const animations = useRef([0, 1, 2, 3, 4].map(() => new Animated.Value(0))).current;
  const [dummyState, setDummyState] = useState(0);

  useEffect(() => {
    animations.forEach((animation, index) => {
      animation.addListener(({ value }) => {
        setDummyState(Math.random());
      });
    });

    const createAnimation = (value, duration, delay = 0) =>
      Animated.timing(value, {
        toValue: 1,
        duration,
        delay,
        useNativeDriver: false, 
      });

    const animationsSequence = animations.map((animation, index) =>
      Animated.sequence([
        createAnimation(animation, 200, index * 200),
        Animated.timing(animation, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
      ])
    );

    const loopAnimation = Animated.loop(Animated.stagger(200, animationsSequence));
    loopAnimation.start();

    
    return () => {
      animations.forEach((animation) => {
        animation.removeAllListeners();
      });
    };
  }, [animations]);

  return (
    <View style={styles.container}>
      <SmallLogoSvg width={60} height={60} />
      <View style={styles.dotContainer}>
        {animations.map((animation, index) => (
          <Animated.View
            key={index}
            style={[
              styles.dot,
              {
                transform: [
                  {
                    scale: animation.interpolate({
                      inputRange: [0, 1],
                      outputRange: [1, 1.5],
                    }),
                  },
                ],
                backgroundColor: animation.interpolate({
                  inputRange: [0, 1],
                  outputRange: [
                    theme.palette.green[600],
                    theme.palette.green[200],
                  ],
                }),
              },
            ]}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 15,
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginHorizontal: 5,
  },
});

export default Loading;
