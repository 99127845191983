import {
    View,
    Text,
    Platform,
    useWindowDimensions,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    CloseSvg,
    PdfSvg,
    UploadSvg,
} from '../../components/Svg';
import LabHeader from '../../components/LabHeader';
import Background from '../../components/Background';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import * as DocumentPicker from 'expo-document-picker';
import { errorToast } from '../../components/Toast';
import * as Progress from 'react-native-progress';
import { api } from '../../helpers/helper';
import _ from 'lodash';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { setMode } from '../../redux/reducer';

const UploadScreen = ({ navigation, route }) => {
    const dispatch = useDispatch();
    const isWeb = Platform.OS === 'web';
    const isAndroid = Platform.OS === 'android';
    const isIos = Platform.OS === 'ios';
    const { width, height } = useWindowDimensions();
    const { getStaticTranslation } = useTranslation()
    const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
    const [containerWidth, setContainerWidth] = useState(0);
    const [file, setFile] = useState();
    const [progress, setProgress] = useState(0);
    const [company, setCompany] = useState();
    const [resultId, setResultId] = useState();
    const [scenarios, setScenarios] = useState();
    const [scanLoad, setScanLoad] = useState(false)
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('dark'));
		}, [dispatch])
	);
    useEffect(() => {
        if (route.params.data) {
            setCompany(route.params.data);
            setResultId();
            setScenarios();
            setProgress();
            setFile()
            // setLoading(false);
        }
    }, [route, navigation]);

    const handleLayout = event => {
        const { width } = event.nativeEvent.layout;
        setContainerWidth(width - 25);
    };
    const pickPDF = async () => {
        setResultId();
        setScenarios();
        setProgress();

        const picker = await DocumentPicker.getDocumentAsync({
            type: 'application/pdf',
            multiple: false,
            copyToCacheDirectory: true
        });
        if (picker.assets) {
            if (picker.assets[0].mimeType !== 'application/pdf') {
                errorToast(getStaticTranslation('lab.fileType error', 'File type must be in PDF format'));
            } else {
                if (picker.assets[0].size < 10 * 1024 * 1024) {
                    setFile(picker.assets[0]);
                } else {
                    errorToast(getStaticTranslation('lab.fileSize error', 'File size must be less than 10MB'));
                }
            }
        } else if (picker) {
            if (picker.mimeType !== 'application/pdf') {
                errorToast(getStaticTranslation('lab.fileType error', 'File type must be in PDF format'));
            } else {
                if (picker.size < 10 * 1024 * 1024) {
                    setFile(picker);
                } else {
                    errorToast(getStaticTranslation('lab.fileSize error', 'File size must be less than 10MB'));
                }
            }
        }
    };
    const scan = async () => {
        setScanLoad(true)
        setProgress(0);
        if (isWeb && !file.file) {
            errorToast(getStaticTranslation('lab.no file', 'No file selected'));
            return;
        }
        if ((isAndroid || isIos) && !file) {
            errorToast(getStaticTranslation('lab.no file', 'No file selected'));
            return;
        }
        const data = isWeb ? file.file : file;
        const formData = new FormData();
        formData.append('_companyId', company?._id);
        if (isWeb) {
            formData.append("file", data)

        } else {
            formData.append('file', {
                uri: file.uri,
                type: isWeb ? file.type : file.mimeType,
                name: file.name
            });
        }
        try {
            await api(`extract/upload/scenarios`, 'POST', formData, setProgress, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(res => {
                    setResultId(_.get(res, 'result._resultId'));
                    setScenarios(_.get(res, 'result.scenarios'));

                })
                .catch(err => {
                    errorToast(err.message);
                })
            setScanLoad(false)
        } catch (e) {
            errorToast(e.message);
        }
    };

    return (
        <Background>
            <View style={styles.headerContainer}>
                <LabHeader step={1} company={company} scanLoad={scanLoad} />
            </View>
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image
                        source={{ uri: company?.logoUrl||"data:image/png" }}
                        resizeMode='cover'
                        style={styles.image}
                    />
                </View>
                <Text style={styles.desc}>
                    {getStaticTranslation('lab.upload desc1', 'Upload your ')}{company?.name}{getStaticTranslation('lab.upload desc2', "'s lab test result to allow A.I. to analyze possible health issues and risk evaluation.")}
                </Text>
            </View>

            <View style={styles.resultContainer} onLayout={handleLayout}>
                <ScrollView
                    style={styles.scrollContainer}
                    contentContainerStyle={styles.scrollContent}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                >
                    <Pressable
                        style={styles.uploadContainer}
                        onPress={() => {
                            pickPDF();
                        }}
                    >
                        <View style={styles.uploadIcon}>
                            <UploadSvg width={94} height={70} color={theme.palette.lightGreen[300]} />
                        </View>
                        <Text style={styles.subTitle}>{getStaticTranslation('lab.choose', 'Choose a file or drag and drop')}</Text>
                        <Text style={styles.uploadDesc}>
                            {getStaticTranslation('lab.choose desc1', 'PDF format, maximum file size:')}
                            <Text style={styles.uploadMb}>  {getStaticTranslation('lab.choose desc2', '10MB')}</Text>
                        </Text>
                        <Pressable
                            style={styles.pressable}
                            onPress={() => {
                                pickPDF();
                            }}
                        >
                            <Text style={styles.pressableText}> {getStaticTranslation('lab.browse', 'Browse File')}</Text>
                        </Pressable>
                    </Pressable>
                    {file && (
                        <View style={styles.pdfContainar}>
                            <View style={styles.pdfInnerContainar}>
                                <View style={styles.pdfIconContainer}>
                                    <PdfSvg />
                                </View>
                                <View style={styles.pdfTextContainer}>
                                    <View style={styles.pdfTitleContainer}>
                                        <Text
                                            style={styles.pdfTitle}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {file?.name}
                                        </Text>

                                        <Pressable
                                            onPress={() => {
                                                setProgress(0);
                                                setFile();
                                                setResultId();
                                                setScenarios();
                                            }}
                                        >
                                            <CloseSvg />
                                        </Pressable>
                                    </View>
                                    <Text style={styles.pdfDesc}>
                                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                                    </Text>
                                </View>
                            </View>
                            {progress >= 0 && (
                                <View style={styles.progressContainer}>
                                    <Progress.Bar
                                        progress={resultId && scenarios ? 100 : progress / 100}
                                        width={null}
                                        color={theme.palette.green[200]}
                                        unfilledColor={theme.palette.grey[200]}
                                        borderWidth={0}
                                        height={6}
                                        borderRadius={3}
                                        style={styles.progressBar}
                                    />
                                    <Text style={styles.percentage}>{resultId && scenarios ? 100 :progress}%</Text>
                                </View>
                            )}
                        </View>
                    )}

                </ScrollView>
                <View style={styles.buttonContainer}>
                    <Pressable
                        disabled={!file || scanLoad}
                        style={[
                            styles.button,
                            {
                                backgroundColor: !file || scanLoad
                                    ? theme.palette.grey[200]
                                    : theme.palette.green[200],
                            },
                        ]}
                        onPress={() => {
                            if (resultId && scenarios) {
                                navigation.navigate('LabResult', {
                                    data: {
                                        resultId,
                                        scenarios,
                                        company
                                    }

                                });
                            } else {
                                scan()
                            }
                        }
                        }
                    >
                        <Text
                            style={[
                                styles.buttonText,
                                {
                                    color: !file || scanLoad
                                        ? theme.palette.grey[600]
                                        : theme.palette.green[700],
                                },
                            ]}
                        >
                            {resultId && scenarios ? getStaticTranslation('lab.view', 'View Result') : getStaticTranslation('lab.submit', "Submit")}
                        </Text>
                    </Pressable>
                </View>
            </View>
        </Background >
    );
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
    const fontSize = getFontSize();
    const align=getAlign()
    return StyleSheet.create({
        headerContainer: {
            paddingHorizontal: 20,
            paddingVertical: 10,
            display: 'flex',
            flexDirection: 'column',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            paddingHorizontal: 15,
            paddingVertical: 10,
            
        },
        scrollContainer: {
            flex: 1,
            height: '100%',
            width: '100%',
            
        },
        scrollContent: {
            flexGrow: 1,
            height: isWeb ? height * 0.2 : 'auto',
            gap: 10,
            paddingBottom: isWeb ? '15%' : 50,
            
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 12,
        },
        image: {
            width: 160,
            height: 65,
            borderRadius: 12,
        },
        desc: {
            color: `${theme.palette.secondary}80`,
            fontSize: fontSize[14],
            textAlign: align.justify,
            fontFamily: fontFamily.poppins[300],
        },
        resultContainer: {
            flex: 1,
            display: 'flex',
            paddingVertical: 20,
            flexDirection: 'column',
            gap: 10,
            alignItems: 'center',
            paddingHorizontal: 20,
            paddingVertical: 25,
            backgroundColor: theme.palette.lightGreen[100],
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            marginBottom: isIos?30:0
        },
        uploadContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: theme.palette.secondary,
            borderStyle: 'dashed',
            borderWidth: 2,
            borderColor: theme.palette.lightGreen[300],
            borderRadius: 12,
            paddingHorizontal: isWeb ? '5%' : '2%',
            paddingVertical: isWeb ? '10%' : '10%',
        },
        uploadIcon: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 30,
        },
        subTitle: {
            fontFamily: fontFamily.poppins[600],
            fontSize: fontSize[18],
            color: theme.palette.green[700],
        },
        uploadDesc: {
            fontFamily: fontFamily.poppins[400],
            fontSize: fontSize[16],
            color: theme.palette.black[100],
        },
        uploadMb: {
            fontFamily: fontFamily.poppins[500],
            fontSize: fontSize[16],
            color: theme.palette.black[100],
        },
        pressable: {
            marginTop: '3%',
            backgroundColor: theme.palette.green[700],
            borderRadius: 10,
            padding: 6,
            paddingHorizontal: 20,
        },
        pressableText: {
            color: theme.palette.secondary,
            fontFamily: fontFamily.poppins[500],
            fontSize: fontSize[14],
        },
        pdfContainar: {
            // flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.secondary,
            borderRadius: 12,
            paddingHorizontal: isWeb ? '15%' : '10%',
            paddingVertical: '8%',
            marginBottom: '3%',
            elevation: 3,
            shadowColor: theme.palette.primary,
            shadowOffset: { width: 0, height: 3 },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
        },
        pdfInnerContainar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // backgroundColor: "red",
            flexDirection: 'row',
            width: '100%',
            // gap: 10
        },
        pdfTextContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10%',
            // backgroundColor: "blue",
        },
        pdfIconContainer: { display: 'flex' },
        pdfTitleContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: 10,
        },
        pdfTitle: {
            fontFamily: fontFamily.poppins[500],
            fontSize: fontSize[14],
            color: theme.palette.black[100],
            width: '90%',
        },
        pdfDesc: {
            fontFamily: fontFamily.poppins[400],
            fontSize: fontSize[14],
            color: theme.palette.black[100],
        },
        buttonContainer: {
            // flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
        },
        button: {
            width: '80%',
            marginTop: '3%',
            backgroundColor: theme.palette.green[200],
            borderRadius: 10,
            padding: 10,
            paddingHorizontal: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5%',
        },
        buttonText: {
            color: theme.palette.green[700],
            fontFamily: fontFamily.poppins[600],
            fontSize: fontSize[18],
        },
        progressContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: 10,
        },
        progressBar: {
            flex: 1,
            borderRadius: 3,
        },
        percentage: {
            color: theme.palette.black[100],
            fontFamily: fontFamily.poppins[400],
            fontSize: fontSize[14],
        },
    });
};
export default UploadScreen;
