import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  useWindowDimensions,
} from "react-native";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import moment from "moment";
import { useSelector } from "react-redux";
import Skeleton from "../../components/Skeleton";
import { useTranslation } from "../../components/TranslationProvider";
import { api } from "../../helpers/helper";
import { errorToast } from "../../components/Toast";
import { useFocusEffect } from "@react-navigation/native";

const AnnouncementScreen = () => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width } = useWindowDimensions();
  const { getStaticTranslation, getTranslation } = useTranslation();
  const styles = cssStyles(isWeb, isAndroid, isIos, width);
  const { user } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(true);
  const [latestData, setLatestData] = useState();
  const [containerWidth, setContainerWidth] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [groupedData, setGroupedData] = useState();
	
  // useFocusEffect(
  // 	useCallback(() => {
  useEffect(() => {
    setLoading(true);
    api("announcement")
      .then((res) => {
        if (res?.result) {
          getData(res.result);
        }
      })
      .catch((error) => {
        errorToast(error.message);
      });
  }, [user]);
  // );

  const getData = async (data) => {
    const sortedData = data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setLatestData(sortedData[0]);
    const restData = sortedData.slice(1);

    const grouped = restData.reduce((acc, data) => {
      const year = moment(data.createdAt).format("YYYY");
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(data);
      return acc;
    }, {});
    setGroupedData(grouped);
    setLoading(false);
  };

  const handleTimelineLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setTimelineWidth(width);
  };
  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };
  return (
    <ScrollView
      style={styles.container}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      {loading ? (
        <View style={styles.loadingContainer} onLayout={handleLayout}>
          <Skeleton height={250} width={containerWidth} borderRadius={12} />
          {[...Array(2)].map((_, index) => (
            <View key={index} style={styles.loadingTimeline}>
              <Skeleton height={50} width={40} borderRadius={12} />
              <Skeleton
                height={120}
                width={containerWidth - 50}
                borderRadius={12}
              />
            </View>
          ))}
        </View>
      ) : (
        <>
          {!latestData && !groupedData ? (
            <View style={styles.noDataContainar}>
              <View style={styles.noData}>
                <View>
                  <FileAddSvg />
                </View>
                <View style={styles.noDataTextContainer}>
                  <Text style={styles.subTitle}>
                    {getStaticTranslation(
                      "announcement.no data",
                      "No Announcement Available"
                    )}
                  </Text>
                  <Text style={styles.desc}>
                    {getStaticTranslation(
                      "announcement.no data info",
                      "There is no announcement available for these moment"
                    )}
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <>
              <View style={styles.latestContainer}>
                <View style={styles.dateContainer}>
                  <View>
                    <Text style={styles.dateText}>
                      {moment(latestData.createdAt).format("DD")}
                    </Text>
                    <Text style={styles.monthText}>
                      {moment(latestData.createdAt).format("MMM")}
                    </Text>
                    <Text style={styles.yearText}>
                      {moment(latestData.createdAt).format("YYYY")}
                    </Text>
                  </View>
                  <Text style={styles.latestTime}>
                    {moment(latestData.createdAt).format("hh:mm A")}
                  </Text>
                </View>
                <View style={styles.latestContent}>
                  <Text style={styles.latestTitle}>
                    {getTranslation(latestData, "title", latestData.title)}
                  </Text>
                  <View style={styles.separator} />
                  <Text style={styles.latestDesc}>
                    {getTranslation(latestData, "desc", latestData.desc)}
                  </Text>
                </View>
              </View>

              {groupedData &&
                Object.keys(groupedData)
                  .sort((a, b) => b - a)
                  .map((year, index) => (
                    <View key={index}>
                      <View style={styles.yearContainer}>
                        <View style={styles.line} />
                        <Text style={styles.timelineYearText}>{year}</Text>
                        <View style={styles.line} />
                      </View>
                      {groupedData[year].map((data, idx) => {
                        const showDateContainer =
                          idx === 0 ||
                          moment(data.createdAt).format("DD MMM") !==
                            moment(groupedData[year][idx - 1].createdAt).format(
                              "DD MMM"
                            );
                        return (
                          <View key={idx} style={styles.timelineContainer}>
                            <View style={styles.timeline}>
                              {showDateContainer && (
                                <View
                                  style={styles.timelineDateContainer}
                                  onLayout={handleTimelineLayout}
                                >
                                  <Text style={styles.timelineDateText}>
                                    {moment(data.createdAt).format("DD")}
                                  </Text>
                                  <Text style={styles.timelineMonthText}>
                                    {moment(data.createdAt).format("MMM")}
                                  </Text>
                                </View>
                              )}
                              {!showDateContainer && (
                                <View
                                  style={[
                                    styles.emptyTimelineDateContainer,
                                    { width: timelineWidth },
                                  ]}
                                />
                              )}
                              <View style={styles.timelineLineContainer}>
                                <View style={styles.timelineLine} />
                              </View>
                            </View>
                            <View style={styles.timelineContent}>
                              <View style={styles.timelineDetail}>
                                <Text style={styles.timelineTitle}>
                                  {getTranslation(data, "title", data.title)}
                                </Text>
                                <Text style={styles.timelineDesc}>
                                  {getTranslation(data, "desc", data.desc)}
                                </Text>
                                <Text style={styles.timelineTime}>
                                  {moment(data.createdAt).format("hh:mm A")}
                                </Text>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  ))}
            </>
          )}
        </>
      )}
    </ScrollView>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.palette.lightGreen[100],
      paddingHorizontal: isWeb ? "5%" : 20,
    },
    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: 10,
    },
    loadingTimeline: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
    },
    latestContainer: {
      backgroundColor: theme.palette.green[100],
      borderRadius: 10,
      padding: 15,
      marginVertical: 10,
      flexDirection: "row",
      gap: 10,
    },
    dateContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      backgroundColor: `${theme.palette.secondary}40`,
      borderRadius: 12,
      padding: 10,
    },
    dateText: {
      fontSize: fontSize[20],
      fontFamily: fontFamily.poppins[700],
      color: theme.palette.secondary,
      lineHeight: 22,
    },
    monthText: {
      fontSize: fontSize[25],
      fontFamily: fontFamily.poppins[700],
      color: theme.palette.secondary,
      lineHeight: 27,
    },
    yearText: {
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[300],
      color: theme.palette.secondary,
      lineHeight: 22,
    },
    latestContent: {
      flex: 1,
    },
    latestTitle: {
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
      color: theme.palette.secondary,
    },
    separator: {
      borderBottomColor: `${theme.palette.secondary}30`,
      borderBottomWidth: 1,
      marginTop: isWeb || isIos ? 5 : 0,
    },
    latestDesc: {
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[400],
      color: theme.palette.secondary,
      textAlign: align.justify,
      marginVertical: 5,
    },
    yearContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginVertical: 10,
    },
    line: {
      flex: 1,
      height: 1,
      backgroundColor: `${theme.palette.green[100]}30`,
    },
    timelineYearText: {
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[800],
      color: theme.palette.green[700],
      textAlign: align.center,
      marginHorizontal: 10,
    },
    latestTime: {
      fontSize: fontSize[12],
      color: "white",
    },
    timelineContainer: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: 10,
      marginBottom: 20,
    },
    timelineLineContainer: {
      flex: 1,
      alignItems: "center",
      marginTop: -2,
      marginBottom: -25,
    },
    timeline: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    timelineDateContainer: {
      backgroundColor: `${theme.palette.green[200]}20`,
      borderRadius: 10,
      paddingHorizontal: 10,
      paddingVertical: 15,
      alignItems: "center",
    },
    timelineDateText: {
      fontSize: fontSize[18],
      fontFamily: fontFamily.poppins[800],
      color: theme.palette.green[700],
      lineHeight: 20,
    },
    timelineMonthText: {
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[700],
      color: theme.palette.green[700],
      lineHeight: 18,
    },
    emptyTimelineDateContainer: {
      marginHorizontal: 4.5,
    },
    timelineLine: {
      width: 2,
      flex: 1,
      borderStyle: "dashed",
      borderWidth: 1,
      borderColor: theme.palette.green[200],
      marginVertical: 5,
    },
    timelineContent: {
      flex: 1,
      flexDirection: "row",
      backgroundColor: "white",
      borderRadius: 10,
      padding: 10,
      paddingHorizontal: 15,
    },
    timelineDetail: {
      flex: 1,
    },
    timelineTitle: {
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
      color: theme.palette.black[100],
    },
    timelineDesc: {
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[400],
      color: theme.palette.grey[300],
      marginVertical: 2,
    },
    timelineTime: {
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[300],
      color: theme.palette.grey[300],
      width: "100%",
      textAlign: align.right,
    },
    noDataContainar: {
      // flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: isWeb ? "15%" : "10%",
      paddingVertical: "5%",
      marginBottom: "3%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    noData: {
      // flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
    },
    noDataTextContainer: {
      // flex: 1,
      display: "flex",
      judtifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      // marginLeft: '5%',
    },
    subTitle: {
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[16],
      color: theme.palette.grey[300],
    },
    desc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[14],
      color: theme.palette.grey[300],
    },
  });
};

export default AnnouncementScreen;
