// src/screens/ProfileScreen.js
import React, { useCallback, useEffect, useState } from 'react';
import {
	View,
	Text,
	StyleSheet,
	Pressable,
	Platform,
	useWindowDimensions,
	Image,
	ScrollView,
	Linking,
} from 'react-native';
import { fontFamily, getAlign, getFontSize, theme } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setMode } from '../../redux/reducer';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase';
import Background from '../../components/Background';
import Language from '../../components/Language';
import {
	EditSvg,
	LogoSvg,
	ManSvg,
	WomenSvg,
	PersonSvg,
	QuestionSvg,
	SmallLogoSvg,
	LogoutSvg,
	OrgSvg,
	ArrowRightSvg,
	HealthSvg,
	PasswordSvg,
	TermsSvg,
	PrivacySvg,
	DeleteSvg,
} from '../../components/Svg';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';
// import { GoogleSignin, statusCodes } from '@react-native-google-signin/google-signin';
import { successToast } from '../../components/Toast';


const ProfileScreen = ({ navigation }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const { user } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();

	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('dark'));
		}, [dispatch])
	);
	const handleLogout = async () => {
		try {
			await signOut(auth);
			dispatch(logout());
			successToast(
				getStaticTranslation('auth.signout success', 'Sign out successfully')
			);
		} catch (error) {
			handleLogoutError(error);
		}
	};
	const handleLogoutError = error => {
		if (error.code) {
			switch (error.code) {
				case 'auth/network-request-failed':
					errorToast(
						getStaticTranslation(
							'auth.network fail',
							'Network error. Please check your connection.'
						)
					);
					break;
				case 'auth/internal-error':
					errorToast(
						getStaticTranslation(
							'auth.internal error',
							'An internal error occurred. Please try again later.'
						)
					);
					break;
				case statusCodes.SIGN_IN_REQUIRED:
					errorToast(
						getStaticTranslation(
							'auth.sign in require',
							'Sign-in required. Please sign in again.'
						)
					);
					break;
				case statusCodes.SIGN_IN_CANCELLED:
					errorToast(
						getStaticTranslation(
							'auth.sign in cancel',
							'Sign-in was cancelled.'
						)
					);
					break;
				case statusCodes.SIGN_IN_FAILED:
					errorToast(
						getStaticTranslation(
							'auth.sign in fail',
							'Sign-in failed. Please try again.'
						)
					);
					break;
				case statusCodes.PLAY_SERVICES_NOT_AVAILABLE:
					errorToast(
						getStaticTranslation(
							'auth.google play service error',
							'Google Play services not available or outdated.'
						)
					);
					break;
				case statusCodes.IN_PROGRESS:
					errorToast(
						getStaticTranslation(
							'auth.in process',
							'Sign-in is already in progress.'
						)
					);
					break;
				default:
					errorToast(
						getStaticTranslation(
							'auth.logout error',
							'An unknown error occurred during logout.'
						)
					);
					break;
			}
		} else {
			errorToast(
				getStaticTranslation(
					'auth.logout error',
					'An unknown error occurred during logout.'
				)
			);
		}
	};
	const handlePolicy = () => {
		const url = `https://mediverse.my/privacy-policy-cookies`;
		if (Platform.OS === 'web') {
			window.open(url, '_blank');
		} else {
			Linking.openURL(url);
		}
	};

	return (
		<Background>
			<View style={styles.languageContainer}>
				<Language isAuth={false} />
			</View>
			<View style={styles.header}>
				<View style={{ paddingRight: 10 }}>
					{isWeb && width < 360 ? (
						<SmallLogoSvg width={49} height={30} />
					) : (
						<LogoSvg
							width={isAndroid ? 150 : isIos ? 173 : '100%'}
							height={isWeb ? '60%' : 30}
						/>
					)}
				</View>
				<Text style={styles.title}>
					{getStaticTranslation('profile.profile', 'Profile')}
				</Text>
			</View>
			<View style={styles.contentContainer}>
				<View style={styles.infoContainer}>
					<View
						style={[
							styles.gender,
							{
								paddingHorizontal:
									user?.gender === 'male' || user?.gender === 'female'
										? 15
										: 20,
								backgroundColor:
									user?.gender === 'male'
										? theme.palette.blue[200]
										: user?.gender === 'female'
										? theme.palette.lightRed[300]
										: theme.palette.green[200],
							},
						]}
					>
						{user?.gender === 'male' ? (
							<ManSvg color={theme.palette.secondary} width={24} height={24} />
						) : user?.gender === 'female' ? (
							<WomenSvg
								color={theme.palette.secondary}
								width={24}
								height={24}
							/>
						) : (
							<QuestionSvg />
						)}
					</View>
					<View style={styles.profilePicContainer}>
						<View
							style={[
								styles.profilePic,
								{
									paddingVertical:
										user?.photoUrl || auth?.currentUser?.photoURL
											? 0
											: isWeb
											? height < 500
												? '20%'
												: '28%'
											: '33%',
									paddingTop:
										user?.photoUrl || auth?.currentUser?.photoURL
											? 0
											: isWeb
											? height < 500
												? '20%'
												: '28%'
											: '33%',
									paddingHorizontal:
										user?.photoUrl || auth?.currentUser?.photoURL ? 0 : 50,
								},
							]}
						>
							{user?.photoUrl || auth?.currentUser?.photoURL ? (
								<Image
									source={{
										uri:
											user.photoUrl ||
											auth?.currentUser?.photoURL ||
											'data:image/png',
									}}
									style={styles.profileImage}
									resizeMode='cover'
								/>
							) : (
								<PersonSvg />
							)}
						</View>
					</View>
					<View style={styles.profileContainer}>
						<View style={styles.nameContainer}>
							<Text style={styles.name} ellipsizeMode='tail' numberOfLines={2}>
								{user?.displayName}
							</Text>
							<Text style={styles.email} ellipsizeMode='tail' numberOfLines={1}>
								{user?.email}
							</Text>
						</View>
						<View style={styles.editContainer}>
							<Pressable
								style={styles.editButton}
								onPress={() =>
									navigation.navigate('ProfileNavigator', {
										screen: 'EditProfile',
									})
								}
							>
								<EditSvg />
								<Text style={styles.edit}>
									{getStaticTranslation('profile.edit', 'Edit Profile')}
								</Text>
							</Pressable>
						</View>
					</View>
				</View>
				<View style={styles.buttonContainer}>
					<ScrollView
						style={styles.scrollContainer}
						contentContainerStyle={styles.scrollContent}
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
					>
						<Pressable
							style={[
								styles.list1,
								{ backgroundColor: theme.palette.lightGreen[300] },
							]}
							onPress={() =>
								navigation.navigate('ProfileNavigator', {
									screen: 'Organization',
								})
							}
						>
							<View style={styles.list1Container}>
								<OrgSvg />
								<Text style={styles.list1Text}>
									{getStaticTranslation('profile.org', 'Organization Details')}
								</Text>
							</View>
							<ArrowRightSvg color={theme.palette.secondary} />
						</Pressable>
						<Pressable
							style={[
								styles.list1,
								{ backgroundColor: theme.palette.lightGreen[300] },
							]}
							onPress={() =>
								navigation.navigate('ProfileNavigator', {
									screen: 'EditIllness',
								})
							}
						>
							<View style={styles.list1Container}>
								<HealthSvg />
								<Text style={styles.list1Text}>
									{getStaticTranslation('profile.health', 'Health Details')}
								</Text>
							</View>
							<ArrowRightSvg color={theme.palette.secondary} />
						</Pressable>
						<Pressable
							style={[
								styles.list1,
								{ backgroundColor: theme.palette.lightGreen[300] },
							]}
							onPress={() =>
								navigation.navigate('ProfileNavigator', {
									screen: 'EditPassword',
								})
							}
						>
							<View style={styles.list1Container}>
								<PasswordSvg />
								<Text style={styles.list1Text}>
									{getStaticTranslation('profile.password', 'Change Password')}
								</Text>
							</View>
							<ArrowRightSvg color={theme.palette.secondary} />
						</Pressable>

						<View style={styles.seperator} />
						<Pressable
							style={[
								styles.list2,
								{ backgroundColor: theme.palette.green[700] },
							]}
							onPress={() =>
								navigation.navigate('ProfileNavigator', {
									screen: 'TnC',
								})
							}
						>
							<View style={styles.list2Container}>
								<TermsSvg />
								<Text style={styles.list2Text}>
									{getStaticTranslation('profile.tnc', 'Terms and Conditions')}
								</Text>
							</View>
							<ArrowRightSvg color={theme.palette.secondary} />
						</Pressable>
						<Pressable
							style={[
								styles.list2,
								{ backgroundColor: theme.palette.green[700] },
							]}
							onPress={handlePolicy}
						>
							<View style={styles.list2Container}>
								<PrivacySvg />
								<Text style={styles.list2Text}>
									{getStaticTranslation('profile.policy', 'Privacy Policy')}
								</Text>
							</View>
							<ArrowRightSvg color={theme.palette.secondary} />
						</Pressable>
						<View style={styles.seperator} />
						<View style={styles.pressableContainer}>
						<Pressable
							style={[
								styles.pressableDownload,
								{ backgroundColor: theme.palette.red[300] },
							]}
							onPress={handleLogout}
						>
							{/* <View style={styles.list2Container}> */}
								<LogoutSvg />
								<Text style={styles.list2Text}>
									{getStaticTranslation('profile.logout', 'Logout Account')}
								</Text>
							{/* </View> */}
						</Pressable>
						</View>
					</ScrollView>
				</View>
			</View>
		</Background>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align = getAlign();
	return StyleSheet.create({
		languageContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
			paddingTop: isAndroid || isIos ? 10 : 20,
			paddingRight: isAndroid || isIos ? 15 : 30,
			width: '100%',
		},
		header: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			paddingHorizontal: isWeb ? '5%' : 30,
			paddingVertical: isAndroid || isIos ? 10 : 0,
			paddingTop: isWeb && 10,
			maxWidth: isWeb ? (width > 500 ? 500 : width) : width,
		},
		title: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[700],
			fontSize: fontSize[20],
			lineHeight: 30,
		},
		contentContainer: {
			flex: 1,
			backgroundColor: theme.palette.lightGreen[100],
			borderTopLeftRadius: 20,
			borderTopRightRadius: 20,
		},
		infoContainer: {
			flex: 1,
			flexDirection: 'row',
			position: 'relative',
			zIndex: 1,
			maxHeight: isWeb ? (height < 500 ? height * 0.25 : height * 0.19) : 174,
		},
		profilePicContainer: {
			backgroundColor: theme.palette.green[600],
			borderTopLeftRadius: 20,
			overflow: 'hidden',
			borderTopRightRadius: 90,
			borderBottomRightRadius: 90,
			paddingRight: 25,
			zIndex: 2,
			width: '45%',
		},
		profilePic: {
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: theme.palette.grey[200],
			borderTopLeftRadius: 20,
			overflow: 'hidden',
			borderTopRightRadius: 90,
			borderBottomRightRadius: 90,
		},
		profileImage: {
			width: isWeb ? width * 0.4 : '100%',
			height: isWeb ? (height < 500 ? height * 0.25 : height * 0.19) : '100%',
		},
		gender: {
			flex: 1,
			position: 'absolute',
			top: '10%',
			left: width >= 500 ? 500 * 0.35 : width * 0.3,
			zIndex: 3,
			padding: 15,
			borderRadius: 50,
		},
		profileContainer: {
			flex: 1,
			flexDirection: 'column',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1,
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			gap: 10,
			padding: 5,
			height: isWeb ? height * 0.5 : 'auto',
		},
		nameContainer: {
			flex: 1,
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'center',
			left: '50%',
			width: '45%',
			gap: 5,
		},
		name: {
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[16],
			color: theme.palette.green[700],
		},
		email: {
			fontFamily: fontFamily.poppins[400],
			fontSize: fontSize[14],
			color: theme.palette.green[700],
		},
		editContainer: {
			flex: 1,
			backgroundColor: theme.palette.green[700],
			borderTopRightRadius: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-end',
		},
		edit: {
			textAlign: align.center,
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[16],
		},
		editButton: {
			flex: 1,
			width: '70%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			gap: 15,
			alignItems: 'center',
			paddingBottom: 20,
		},
		buttonContainer: {
			zIndex: 1,
			marginTop: -25,
			flex: 1,
			backgroundColor: theme.palette.secondary,
			borderTopLeftRadius: 20,
			borderTopRightRadius: 20,
			gap: 10,
			paddingVertical: 20,
			paddingHorizontal: 15,
		},
		pressableContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},

		pressableDownload: {
			width: '80%',
			backgroundColor: theme.palette.red[300],
			borderRadius: 10,
			padding: 10,
			paddingHorizontal: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'row',
			gap: 10,
		},
		pressableText: {
			color: theme.palette.secondary,
			fontFamily: fontFamily.poppins[600],
			fontSize: fontSize[18],
		},
		list1: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			gap: 10,
			borderRadius: 12,
			paddingHorizontal: 25,
			paddingVertical: 15,
			alignItems: 'center',
		},
		list1Container: {
			display: 'flex',
			flexDirection: 'row',
			gap: 15,
			alignItems: 'center',
		},
		list1Text: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[18],
			color: theme.palette.green[700],
		},
		seperator: {
			height: 1,
			backgroundColor: theme.palette.black[100],
			marginVertical: 10,
		},
		list2: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			gap: 10,
			borderRadius: 12,
			paddingHorizontal: 25,
			paddingVertical: 15,
			alignItems: 'center',
		},
		list2Container: {
			display: 'flex',
			flexDirection: 'row',
			gap: 15,
			alignItems: 'center',
		},
		list2Text: {
			fontFamily: fontFamily.poppins[500],
			fontSize: fontSize[18],
			color: theme.palette.secondary,
		},
	});
};

export default ProfileScreen;
