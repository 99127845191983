import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  Linking,
  StyleSheet,
  Pressable,
} from "react-native";
import React, { useState } from "react";
import {
  ArrowDownSvg,
  ArrowUpSvg,
  DownloadSvg,
  HeartLineSvg,
  RedTriageSvg,
  VitalsSvg,
} from "./Svg";
import { fontFamily, getFontSize, theme } from "../theme";
import moment from "moment";
import { baseUrl } from "../helpers/helper";
import _ from "lodash";
import RiskCard from "./RiskCard";
import { useTranslation } from "./TranslationProvider";
import Disclaimer from "./Disclaimer";

const ReportLab = ({ index, report }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width, height } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
  const handleDownload = (id) => {
    const url = `${baseUrl}/api/results/${id}/lab/pdf`;
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };
  return (
    <View style={styles.container}>
      <View key={index} style={styles.reportContainer}>
        <View style={styles.waveContainer}>
          <HeartLineSvg />
        </View>
        <View
          style={[
            styles.dateContainer,
            {
              backgroundColor:
                index % 2 === 0
                  ? theme.palette.green[600]
                  : theme.palette.green[700],
            },
          ]}
        >
          <Text style={styles.date}>
            {moment(report.createdAt).format("DD MMM").toUpperCase()}
          </Text>
          <Text style={styles.time}>
            {moment(report.createdAt).format("hh:mm A")}
          </Text>
        </View>

        <View style={styles.infoContainer}>
          <Pressable
            style={styles.info}
            onPress={() => setIsExpanded(!isExpanded)}
          >
            <Text
              style={styles.reportText}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {report.result.originalFilename}
            </Text>
            {isExpanded ? (
              <ArrowUpSvg color={theme.palette.grey[500]} />
            ) : (
              <ArrowDownSvg color={theme.palette.grey[500]} />
            )}
          </Pressable>

          <Pressable
            style={styles.downloadButton}
            onPress={() => {
              handleDownload(report._id);
            }}
          >
            <DownloadSvg
              width={21}
              height={18}
              color={theme.palette.blue[200]}
            />
            <Text style={styles.downloadText}>
              {getStaticTranslation("report.download", "Download Report")}
            </Text>
          </Pressable>
        </View>
      </View>
      {isExpanded && (
        <View style={styles.subContainer}>
          <View style={styles.disclaimerContainer}>
            <Disclaimer />
          </View>
          <View style={styles.labRemindContainer}>
            <View style={styles.labRemind}>
              <View>
                {_.isEmpty(report.result.scenarios) ? (
                  <VitalsSvg color={theme.palette.green[200]} />
                ) : (
                  <RedTriageSvg color={theme.palette.red[300]} />
                )}
              </View>
              <View style={styles.labRemindTextContainer}>
                <Text
                  style={[
                    styles.labRemindSubTitle,
                    {
                      color: _.isEmpty(report.result.scenarios)
                        ? theme.palette.green[200]
                        : theme.palette.red[300],
                    },
                  ]}
                >
                  {_.isEmpty(report.result.scenarios)
                    ? getStaticTranslation(
                        "report.good",
                        "You're in good health!"
                      )
                    : getStaticTranslation(
                        "report.warning",
                        "Medical attention needed!"
                      )}
                </Text>
                <Text style={styles.labRemindDesc}>
                  {_.isEmpty(report.result.scenarios)
                    ? getStaticTranslation(
                        "report.good desc",
                        "No possible health risk detected."
                      )
                    : getStaticTranslation(
                        "report.warning desc",
                        "Several possible health risk detected."
                      )}
                </Text>
              </View>
            </View>
          </View>
          {_.map(report.result.scenarios, (scenario, index) => {
            return (
              <View key={index} style={[!isWeb && styles.riskCardContainer]}>
                <RiskCard
                  resultId={report._id}
                  scenarios={report.result.scenarios}
                  company={{}}
                  isReport={true}
                  scenario={scenario}
                />
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
  const fontSize = getFontSize();
  return StyleSheet.create({
    container: {
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      marginBottom: 15,
    },
    subContainer: {
      paddingHorizontal: 10,
      paddingVertical: 10,
    },
    reportContainer: {
      flexDirection: "row",
      backgroundColor: theme.palette.secondary,
      borderRadius: 10,
      //
      width: "100%",
      alignItems: "center",
      overflow: "hidden",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    dateContainer: {
      justifyContent: "center",
      paddingVertical: 30,
      paddingHorizontal: 30,
      overflow: "hidden",
      marginTop: -10,
      marginBottom: -10,

      borderTopRightRadius: 25,
      borderBottomRightRadius: 90,
    },
    date: {
      color: theme.palette.secondary,
      fontSize: fontSize[30],
      fontFamily: fontFamily.poppins[800],
    },
    time: {
      color: theme.palette.secondary,
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[500],
    },
    infoContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingRight: 15,
      paddingLeft: 10,

      height: isWeb ? "100%" : "auto",
    },
    info: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    reportText: {
      fontSize: fontSize[16],
      fontFamily: fontFamily.poppins[600],
      color: theme.palette.black[100],
      width: "95%",
    },
    downloadButton: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 10,
      flex: 1,
      marginBottom: -8,
      marginLeft: -5,
      maxWidth: "80%",
    },
    downloadText: {
      color: theme.palette.blue[200],
      fontSize: fontSize[14],
      fontFamily: fontFamily.poppins[600],
      textDecorationLine: "underline",
      flexWrap: "wrap", // Allows wrapping to a new line when content is too long
    },

    waveContainer: {
      position: "absolute",
      bottom: 0,
      // left: 0,
      right: 0,
    },

    labRemindContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary,
      borderRadius: 12,
      paddingHorizontal: "2%",
      paddingVertical: "5%",
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      marginBottom: "2%",
    },
    labRemind: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      gap: 10,
    },
    labRemindTextContainer: {
      display: "flex",
      judtifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      flexWrap: "wrap",
      maxWidth: "90%",
    },
    labRemindSubTitle: {
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[16],
      lineHeight: 18,
    },
    labRemindDesc: {
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[14],
      color: theme.palette.black[100],
      lineHeight: 18,
    },
    riskCardContainer: {
      borderRadius: 12,
      elevation: 3,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      marginBottom: "2%",
    },
    disclaimerContainer: {
      borderRadius: 12,
      elevation: 1,
      shadowColor: theme.palette.primary,
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 3.84,
      marginBottom: "2%",
    },
  });
};

export default ReportLab;
