import {
	View,
	Text,
	Platform,
	useWindowDimensions,
	Modal,
	Pressable,
	StyleSheet,
} from 'react-native';
import React from 'react';
import { fontFamily, getAlign, getFontSize, theme } from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from '../redux/reducer';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from './TranslationProvider';

const UploadNewReportModal = ({ visible, onClose, company }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const { language } = useSelector(state => state.app);
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const handleConfirm = async () => {
		dispatch(setMode('dark'));

		onClose();
		navigation.navigate('Upload', { data: company });
	};

	return (
		<Modal
			visible={visible}
			animationType='slide'
			transparent={true}
			onRequestClose={onClose}
		>
			<Pressable style={[styles.modalOverlay, isWeb && { outlineStyle: 'none' }]} onPress={onClose}>
				<View style={styles.modalContainer}>
					<Text style={styles.title}>
						{getStaticTranslation('lab.upload new', 'Upload New Report?')}
					</Text>
					<Text style={styles.warningText}>
						{language === 'zh' ? (
							<>
								{getStaticTranslation(
									'lab.upload new desc',
									' Analyze again from the start? View back previous result in'
								)}{' '}
								<Text style={styles.span}>
									{getStaticTranslation('lab.report', 'Report.')}
								</Text>
								{getStaticTranslation('lab.view report')}
							</>
						) : (
							<>
								{getStaticTranslation(
									'lab.upload new desc',
									'Analyze again from the start? View back previous result in'
								)}{' '}
								<Text style={styles.span}>
									{getStaticTranslation('lab.report', 'Report.')}
								</Text>
							</>
						)}
					</Text>
					<Pressable style={styles.confirmButton} onPress={handleConfirm}>
						<Text style={styles.confirmButtonText}>
							{getStaticTranslation('lab.confirm', 'Confirm')}
						</Text>
					</Pressable>
					<Pressable style={styles.cancelButton} onPress={onClose}>
						<Text style={styles.cancelButtonText}>
							{getStaticTranslation('lab.cancel', 'Cancel')}
						</Text>
					</Pressable>
				</View>
			</Pressable>
		</Modal>
	);
};
const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
	const fontSize = getFontSize();
	const align=getAlign()
	return StyleSheet.create({
		modalOverlay: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: `${theme.palette.primary}50`,
			maxWidth: isWeb ? 500 : width,
		},
		modalContainer: {
			width: '80%',
			maxWidth: isWeb ? 500 : width,
			padding: 20,
			backgroundColor: theme.palette.lightGreen[100],
			borderRadius: 10,
			alignItems: 'center',
			shadowColor: theme.palette.primary,
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 3.84,
			elevation: 5,
			paddingVertical: 30,
		},
		title: {
			fontSize: fontSize[22],
			fontFamily: fontFamily.poppins[700],
			color: theme.palette.black[100],
		},
		warningText: {
			fontSize: fontSize[16],
			textAlign: align.center,
			marginBottom: 20,
			fontFamily: fontFamily.poppins[400],
			color: theme.palette.black[100],
		},
		span: {
			fontSize: fontSize[16],
			textAlign: align.center,
			marginBottom: 20,
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.black[100],
		},
		confirmButton: {
			width: '100%',
			padding: 15,
			borderRadius: 10,
			backgroundColor: theme.palette.green[700],
			alignItems: 'center',
			marginBottom: 10,
		},
		confirmButtonText: {
			color: theme.palette.secondary,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
		cancelButton: {
			width: '100%',
			padding: 15,
			borderRadius: 10,
			backgroundColor: theme.palette.lightGreen[300],
			alignItems: 'center',
		},
		cancelButtonText: {
			color: theme.palette.green[700],
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
	});
};
export default UploadNewReportModal;
