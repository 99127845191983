import { View, StyleSheet, Platform } from 'react-native';
import React from 'react';
import { ARXLogo, LogoSvg } from './Svg';
import { theme } from '../theme';
import Background from './Background';

export default function AppLoading() {
  const isWeb = Platform.OS === 'web';
  const isAndroid = Platform.OS === 'android';
  const isIos = Platform.OS === 'ios';
  return (
    <Background>
      <View style={styles.logoContainer}>
        <LogoSvg
          width={isAndroid || isIos ? '280' : '78%'}
          height={isAndroid || isIos ? '49' : '23%'}
        />
      </View>
      <View style={styles.footerContainer}>
        <ARXLogo color={theme.palette.secondary} />
      </View>
    </Background>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.green[700],
  },
  logoContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
});
