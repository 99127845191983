import {
  View,
  Text,
  Platform,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
  TextInput,
  Pressable,
} from "react-native";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LightBackground from "../../components/LightBackground";
import StepBar from "../../components/StepBar";
import { fontFamily, getAlign, getFontSize, theme } from "../../theme";
import { setChecker, setMode } from "../../redux/reducer";
import { useTimer } from "../../components/TimerProvider";
import { api } from "../../helpers/helper";
import { useTranslation } from "../../components/TranslationProvider";
import { useFocusEffect } from "@react-navigation/native";
import { errorToast } from "../../components/Toast";

const VitalScreen = ({ navigation }) => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const { checker, userActivity } = useSelector((state) => state.app);
  const styles = cssStyles(isWeb, isAndroid, isIos, width);
  const dispatch = useDispatch();
  const { elapsedSeconds, resetTimer } = useTimer();
  const [isFocus, setIsFocused] = useState({
    systolic: false,
    dispatch: false,
    pulseRate: false,
    temperature: false,
    pulseOximetry: false,
  });
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("light"));
    }, [dispatch])
  );
  const handleInputFocus = (textinput) => {
    setIsFocused({
      [textinput]: true,
    });
  };
  const handleInputBlur = (textinput) => {
    setIsFocused({
      [textinput]: false,
    });
  };

  const numberPattern = /^-?\d+(\.\d+)?$/;

  const handleChange = (name, value) => {
    if (!numberPattern.test(value) && value !== "") {
      errorToast(
        getStaticTranslation(
          "checker.vital error",
          "Please enter valid numbers for all vitals."
        )
      );
    }

    if (value === "") {
      if (name === "systolic" || name === "diastolic") {
        dispatch(setChecker({ bloodPressure: null }));
      } else {
        dispatch(setChecker({ [name]: null }));
      }
    } else {
      // Set individual limits for each type of vital
      let numericValue;
      switch (name) {
        case "systolic":
        case "diastolic":
          numericValue = !isNaN(parseFloat(value)) && isFinite(value)
            ? Math.min(Math.max(Number(value), 0), 300).toString()
            : '0';
      
          if (Number(value) > 500) {
            errorToast(
              getStaticTranslation(
                "checker.bloodPressure error",
                "Systolic Or Diastolic values should not exceed 300."
              )
            );
          }
          break;
        case "pulseRate":
          numericValue = !isNaN(parseFloat(value)) && isFinite(value)
            ? Math.min(Math.max(Number(value), 0), 300).toString()
            : '0';
      
          if (Number(value) > 300) {
            errorToast(
              getStaticTranslation(
                "checker.pulseRate error",
                "Pulse Rate values should not exceed 300."
              )
            );
          }
          break;
        case "temperature":
          numericValue = !isNaN(parseFloat(value)) && isFinite(value)
            ? Math.min(Math.max(Number(value), 0), 100).toString()
            : '0';
      
          if (Number(value) > 100) {
            errorToast(
              getStaticTranslation(
                "checker.temperature error",
                "Body temperature should not exceed 100."
              )
            );
          }
          break;
        case "pulseOximetry":
          numericValue = !isNaN(parseFloat(value)) && isFinite(value)
            ? Math.min(Math.max(Number(value), 0), 100).toString()
            : '0';
      
          if (Number(value) > 100) {
            errorToast(
              getStaticTranslation(
                "checker.pulseOximetry error",
                "Pulse Oximetry should not exceed 100."
              )
            );
          }
          break;
        default:
          numericValue = value; // For any other cases, use the value as is
      }

      if (name === "systolic" || name === "diastolic") {
        dispatch(
          setChecker({
            bloodPressure: { ...checker.bloodPressure, [name]: numericValue },
          })
        );
      } else {
        dispatch(setChecker({ [name]: numericValue }));
      }
    }
  };

  const handleFinish = async () => {
    const { systolic, diastolic } = checker?.bloodPressure || {};
    const { pulseRate, temperature, pulseOximetry } = checker || {};

    // Define limits for each value
    const SYSTOLIC_LIMIT = 500;
    const DIASTOLIC_LIMIT = 500;
    const PULSE_RATE_LIMIT = 300;
    const TEMPERATURE_LIMIT = 100;
    const PULSE_OXIMETRY_LIMIT = 100;

    if (
      (systolic &&
        (!numberPattern.test(systolic) || systolic > SYSTOLIC_LIMIT)) ||
      (diastolic &&
        (!numberPattern.test(diastolic) || diastolic > DIASTOLIC_LIMIT)) ||
      (pulseRate &&
        (!numberPattern.test(pulseRate) || pulseRate > PULSE_RATE_LIMIT)) ||
      (temperature &&
        (!numberPattern.test(temperature) ||
          temperature > TEMPERATURE_LIMIT)) ||
      (pulseOximetry &&
        (!numberPattern.test(pulseOximetry) ||
          pulseOximetry > PULSE_OXIMETRY_LIMIT))
    ) {
      errorToast(
        getStaticTranslation(
          "checker.vital error",
          "Please enter valid numbers within the allowed range for all vitals."
        )
      );
      return;
    }

    if (userActivity) {
      await api("user-activities", "POST", {
        _id: userActivity._id,
        checker: true,
        timeTaken: elapsedSeconds,
        paymentMethod: "Corporate Billing",
      })
        .then(() => {
          resetTimer();
          navigation.navigate("Result");
        })
        .catch((error) => {
          errorToast(error.message);
        });
    }
  };
  return (
    <LightBackground>
      <View style={styles.container}>
        <StepBar step={8} />
        <Text style={styles.question}>
          {getStaticTranslation(
            "checker.question vital",
            "It would be helpful if the information below can be provided."
          )}
        </Text>
        <ScrollView
          style={styles.scrollContainer}
          contentContainerStyle={styles.scrollContent}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <View style={styles.formContainer}>
            <View style={styles.outerContainer}>
              <Text style={styles.label}>
                {" "}
                {getStaticTranslation(
                  "checker.blood",
                  "Blood Pressure (mmHg)"
                )}{" "}
              </Text>
              <View style={styles.innerContainer}>
                <View
                  style={
                    isFocus.systolic
                      ? [styles.fieldContainer, styles.border]
                      : [styles.fieldContainer]
                  }
                >
                  <TextInput
                    onChangeText={(value) => {
                      handleChange("systolic", value);
                    }}
                    value={checker?.bloodPressure?.systolic || ""}
                    style={[styles.input, isWeb && { outlineStyle: "none" }]}
                    placeholder="100"
                    keyboardType="numeric"
                    placeholderTextColor={theme.palette.grey[300]}
                    onFocus={() => handleInputFocus("systolic")}
                    onBlur={() => handleInputBlur("systolic")}
                  />
                </View>
                <Text style={styles.slash}>/</Text>
                <View
                  style={
                    isFocus.diastolic
                      ? [styles.fieldContainer, styles.border]
                      : [styles.fieldContainer]
                  }
                >
                  <TextInput
                    onChangeText={(value) => {
                      handleChange("diastolic", value);
                    }}
                    value={checker?.bloodPressure?.diastolic || ""}
                    style={[styles.input, isWeb && { outlineStyle: "none" }]}
                    placeholder="60"
                    keyboardType="numeric"
                    placeholderTextColor={theme.palette.grey[300]}
                    onFocus={() => handleInputFocus("diastolic")}
                    onBlur={() => handleInputBlur("diastolic")}
                  />
                </View>
              </View>
            </View>
            <View style={styles.outerContainer}>
              <Text style={styles.label}>
                {" "}
                {getStaticTranslation("checker.pulse", "Pulse Rate (BPM)")}{" "}
              </Text>
              <View style={styles.innerContainer}>
                <View
                  style={
                    isFocus.pulseRate
                      ? [styles.fieldContainer, styles.border]
                      : [styles.fieldContainer]
                  }
                >
                  <TextInput
                    onChangeText={(value) => {
                      handleChange("pulseRate", value);
                    }}
                    value={checker?.pulseRate || ""}
                    style={[styles.input, isWeb && { outlineStyle: "none" }]}
                    placeholder="90"
                    keyboardType="numeric"
                    placeholderTextColor={theme.palette.grey[300]}
                    onFocus={() => handleInputFocus("pulseRate")}
                    onBlur={() => handleInputBlur("pulseRate")}
                  />
                </View>
              </View>
            </View>
            <View style={styles.outerContainer}>
              <Text style={styles.label}>
                {" "}
                {getStaticTranslation("checker.temp", "Temperature (°C)")}{" "}
              </Text>
              <View style={styles.innerContainer}>
                <View
                  style={
                    isFocus.temperature
                      ? [styles.fieldContainer, styles.border]
                      : [styles.fieldContainer]
                  }
                >
                  <TextInput
                    onChangeText={(value) => {
                      handleChange("temperature", value);
                    }}
                    value={checker?.temperature || ""}
                    style={[styles.input, isWeb && { outlineStyle: "none" }]}
                    placeholder="37.1"
                    keyboardType="numeric"
                    placeholderTextColor={theme.palette.grey[300]}
                    onFocus={() => handleInputFocus("temperature")}
                    onBlur={() => handleInputBlur("temperature")}
                  />
                </View>
              </View>
            </View>

            <View style={styles.outerContainer}>
              <Text style={styles.label}>
                {getStaticTranslation("checker.oximetry", "Pulse Oximetry (%)")}{" "}
              </Text>
              <View style={styles.innerContainer}>
                <View
                  style={
                    isFocus.pulseOximetry
                      ? [styles.fieldContainer, styles.border]
                      : [styles.fieldContainer]
                  }
                >
                  <TextInput
                    onChangeText={(value) => {
                      handleChange("pulseOximetry", value);
                    }}
                    value={checker?.pulseOximetry || ""}
                    style={[styles.input, isWeb && { outlineStyle: "none" }]}
                    placeholder="98"
                    keyboardType="numeric"
                    placeholderTextColor={theme.palette.grey[300]}
                    onFocus={() => handleInputFocus("pulseOximetry")}
                    onBlur={() => handleInputBlur("pulseOximetry")}
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <Pressable
            style={[
              styles.button,
              {
                backgroundColor: theme.palette.green[200],
              },
            ]}
            onPress={handleFinish}
          >
            <Text
              style={[
                styles.buttonText,
                {
                  color: theme.palette.green[700],
                },
              ]}
            >
              {checker?.bloodPressure?.systolic ||
              checker?.bloodPressure?.diastolic ||
              checker?.pulseRate ||
              checker?.temperature ||
              checker?.pulseOximetry
                ? getStaticTranslation("checker.submit", "Submit")
                : getStaticTranslation("checker.skip", "Skip")}
            </Text>
          </Pressable>
        </View>
      </View>
    </LightBackground>
  );
};
const cssStyles = (isWeb, isAndroid, isIos, width) => {
  const fontSize = getFontSize();
  const align = getAlign();
  return StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    question: {
      color: theme.palette.green[700],
      fontSize: fontSize[20],
      fontFamily: fontFamily.poppins[700],
      paddingBottom: isWeb ? 10 : 0,
      textAlign: align.center,
    },
    scrollContainer: {
      flex: 1,
    },
    scrollContent: {
      alignItems: "center",
      flexGrow: 1,
      // height: isWeb ? 500 : 'auto',
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      // alignItems: 'center',
      width: "100%",
    },
    outerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 20,
    },
    label: {
      width: "40%",
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[16],
      color: theme.palette.black[100],
      textAlign: align.left,
    },
    innerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "60%",
      gap: 10,
    },
    fieldContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginVertical: 5,
      justifyContent: "space-between",
      backgroundColor: theme.palette.secondary,
      borderRadius: 10,
    },
    border: {
      borderWidth: 2,
      borderColor: theme.palette.green[700],
    },
    input: {
      backgroundColor: theme.palette.secondary,
      paddingHorizontal: 10,
      borderRadius: 10,
      width: "80%",
      height: 50,
      // outlineStyle: 'none',
      fontFamily: fontFamily.poppins[400],
    },
    slash: {
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[16],
      color: theme.palette.black[100],
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      width: "80%",
      marginTop: "3%",
      backgroundColor: theme.palette.green[200],
      borderRadius: 10,
      padding: 10,
      paddingHorizontal: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "5%",
    },
    buttonText: {
      color: theme.palette.green[700],
      fontFamily: fontFamily.poppins[600],
      fontSize: fontSize[18],
    },
  });
};

export default VitalScreen;
