import { View, StyleSheet, KeyboardAvoidingView, Platform, SafeAreaView } from 'react-native';
import React from 'react';
import { theme } from '../theme';
import { BgLogoSvg } from './Svg';

const LightBackground = ({ children }) => {

	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const styles = cssStyles(isWeb, isAndroid, isIos);
	return (
		<SafeAreaView style={styles.background}>
			<View style={styles.logo}>
				<BgLogoSvg />
			</View>
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
				style={{ flex: 1 }}
				keyboardVerticalOffset={Platform.select({
					ios: () => 0,
					android: () => -85,
					web: () => 0,
				})()}
			>
				{children}
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};
const cssStyles = (isWeb, isAndroid, isIos) => {
	return StyleSheet.create({
		background: {
			flex: 1,
			width: '100%',
			height: '100%',
			display: 'flex',
			backgroundColor: theme.palette.lightGreen[100],
			marginBottom: isIos? 50:0
		},
		logo: {
			position: 'absolute',
			bottom: 0,
			right: 0,
		},
	});
};
export default LightBackground;
