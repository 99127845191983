import { View, Platform, StyleSheet, useWindowDimensions } from 'react-native';
import React from 'react';
import { theme } from '../theme';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const Wrapper = ({ children }) => {
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const insets = useSafeAreaInsets();
	const styles = cssStyles(isWeb, isAndroid, isIos, insets, width, height);
	return (
		<View style={styles.bgContainer}>
			<View style={styles.webContainer}>{children}</View>
		</View>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, insets, width, height) => {
	return StyleSheet.create({
		bgContainer: {
			backgroundColor: theme.palette.primary,
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		webContainer: {
			width: '100%',
			maxWidth: isWeb ? 500 : width,
			height: height,
			backgroundColor: theme.palette.green[700],
		},
	});
};

export default Wrapper;
