import React, { useCallback, useEffect, useState } from 'react';
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	Pressable,
	Platform,
	useWindowDimensions,
} from 'react-native';
import { theme, fontFamily, getFontSize, getAlign } from '../../theme';
import {
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	signInWithCredential,
	signInWithEmailAndPassword,
	signInWithPopup,
	updateProfile,
} from 'firebase/auth';
import { auth } from '../../config/firebase';
import Icon from 'react-native-vector-icons/Ionicons';
import { errorToast, successToast } from '../../components/Toast';
import { ARXLogo, GoogleSvg, LogoSvg } from '../../components/Svg';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated, setMode } from '../../redux/reducer';
import Background from '../../components/Background';
import Language from '../../components/Language';
import ForgotPassword from '../../components/ForgotPassword';
import { useTranslation } from '../../components/TranslationProvider';
import { useFocusEffect } from '@react-navigation/native';

const AuthScreen = ({ navigation }) => {
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isSignUp, setIsSignUp] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const {language}=useSelector(state=>state.app)
	const [isFocused, setIsFocused] = useState({
		name: false,
		email: false,
		password: false,
		confirmPassword: false,
	});
	const isWeb = Platform.OS === 'web';
	const isAndroid = Platform.OS === 'android';
	const isIos = Platform.OS === 'ios';
	const { width, height } = useWindowDimensions();
	const { getStaticTranslation } = useTranslation();
	const styles = cssStyles(isWeb, isAndroid, isIos, width, height, isSignUp);
	const validateEmail = email => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};
	useFocusEffect(
		useCallback(() => {
			dispatch(setMode('dark'));
		}, [dispatch])
	);

	const validatePassword = password => {
		const re =
			/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/
		return re.test(password);
	};
	const handleFirebaseAuthError = (error) => {
		switch (error.code) {
			case 'auth/email-already-in-use':
				errorToast(getStaticTranslation('auth.email in use', "This email is already in use."));
				break;
			case 'auth/invalid-email':
				errorToast(getStaticTranslation('auth.invalid email', "This email address is invalid."));
				break;
			case 'auth/operation-not-allowed':
				errorToast(getStaticTranslation('auth.operation not allow', "Operation not allowed. Please enable email/password authentication in Firebase console."));
				break;
			case 'auth/weak-password':
				errorToast(getStaticTranslation('auth.weak password', "The password is too weak."));
				break;
			case 'auth/user-disabled':
				errorToast(getStaticTranslation('auth.user disable', "The user account has been disabled by an administrator."));
				break;
			case 'auth/user-not-found':
				errorToast(getStaticTranslation('auth.user not found', "There is no user corresponding to the given email."));
				break;
			case 'auth/wrong-password':
				errorToast(getStaticTranslation('auth.wrong password', "The password is invalid for the given email."));
				break;
			case 'auth/too-many-requests':
				errorToast(getStaticTranslation('auth.too many request', "Too many unsuccessful login attempts. Please try again later."));
				break;
			case 'auth/popup-closed-by-user':
				errorToast(getStaticTranslation('auth.pop up close', "You have closed the popup before completing the sign in."));
				break;
			case 'auth/cancelled-popup-request':
				errorToast(getStaticTranslation('auth.pop up cancel', "You have canceled the popup request."));
				break;
			case 'auth/network-request-failed':
				errorToast(getStaticTranslation('auth.network fail', "Network error. Please try again."));
				break;
			// case statusCodes.SIGN_IN_CANCELLED:
			// 	errorToast(getStaticTranslation('auth.pop up close', "You have closed the popup before completing the sign in."));
			// 	break;
			// case statusCodes.IN_PROGRESS:
			// 	errorToast(getStaticTranslation('auth.in process', "Sign-in operation already in progress"));
			// 	break;
			// case statusCodes.PLAY_SERVICES_NOT_AVAILABLE:
			// 	errorToast(getStaticTranslation('auth.google play service error', "Google play services not available or outdated"));
			// 	break;
			default:
				errorToast(error.message);
				break;
		}
	};
	const socialLogin = async () => {
			const provider = new GoogleAuthProvider();
			provider.setCustomParameters({
				prompt: 'select_account',
			});

			try {
				await signInWithPopup(auth, provider);
				dispatch(setIsAuthenticated(true));
				successToast(getStaticTranslation('auth.google login success', 'Google login successfully'));
			} catch (e) {
				handleFirebaseAuthError(e);
			}
	};
	const handleLogin = async () => {
		try {
			if (email.length === 0) {
				throw new Error(
					getStaticTranslation('auth.no email', 'Email cannot be empty')
				);
			}
			if (!validateEmail(email)) {
				throw new Error(
					getStaticTranslation(
						'auth.invalid email',
						'Please enter a valid email.'
					)
				);
			}
			if (password.length === 0) {
				throw new Error(
					getStaticTranslation('auth.no password', 'Password cannot be empty.')
				);
			}
			await signInWithEmailAndPassword(auth, email, password);
			dispatch(setIsAuthenticated(true));
			successToast(getStaticTranslation('auth.login success', 'Login successfully'));
		} catch (e) {
			handleFirebaseAuthError(e);
		}
	};

	const handleSignUp = async () => {
		try {
			if (name.length === 0) {
				throw new Error(
					getStaticTranslation('auth.no name', 'Name cannot be empty.')
				);
			}
			if(name.length>20){
				throw new Error(
					getStaticTranslation('auth.long name', 'Name must be less than 20 characters.')
				);
			}
			if (email.length === 0) {
				throw new Error(
					getStaticTranslation('auth.no email', 'Email cannot be empty.')
				);
			}
			if (!validateEmail(email)) {
				throw new Error(
					getStaticTranslation(
						'auth.invalid email',
						'Please enter a valid email.'
					)
				);
			}
			if (!validatePassword(password)) {
				throw new Error(
					getStaticTranslation(
						'auth.invalid password',
						'Password must be at least 8 characters long and include a combination of letters, numbers, symbols, and at least one uppercase letter.'
					)
				);
			}
			if (password !== confirmPassword) {
				throw new Error(
					getStaticTranslation(
						'auth.password not match',
						'Passwords do not match.'
					)
				);
			}
			await createUserWithEmailAndPassword(auth, email, password);
			await updateProfile(auth.currentUser, {
				displayName: name,
				password: password,
			});
			dispatch(setIsAuthenticated(true));
			successToast(getStaticTranslation('auth.sign up success', 'Sign up successfully'));

		} catch (e) {
			handleFirebaseAuthError(e);
		}
	};
	const handleInputFocus = textinput => {
		setIsFocused({
			[textinput]: true,
		});
	};
	const handleInputBlur = textinput => {
		setIsFocused({
			[textinput]: false,
		});
	};
	return (
		<Background>
			<View style={styles.languageContainer}>
				<Language isAuth={true} />
			</View>
			<View style={styles.logoContainer}>
				<Text style={styles.welcome}>
					{getStaticTranslation('auth.welcome', 'Welcome to')}
				</Text>
				<LogoSvg
					width={height < 500 ? '250' : '280'}
					height={height < 500 ? '48' : '49'}
				/>
				<Text style={styles.slogan}>
					{getStaticTranslation(
						'auth.slogan',
						'The transformation of future healthcare system'
					)}
				</Text>
			</View>

			<View style={styles.loginContainer}>
				{!isSignUp && (
					<>
						<Pressable style={styles.googleButton} onPress={socialLogin}>
						<View style={[styles.googleIcon, {padding: language==='ms'? 20: 8}]}>

								<GoogleSvg />
							</View>

							<View style={styles.googleTextContainer}>
								<Text style={styles.googleText}>
									{getStaticTranslation('auth.google', 'Sign in with Google')}
								</Text>
							</View>
						</Pressable>
						<Text style={styles.or}>
							{getStaticTranslation('auth.or', 'Or')}
						</Text>
					</>
				)}
				<Text style={styles.info}>
					{isSignUp
						? getStaticTranslation('auth.sign up', 'Sign up')
						: getStaticTranslation('auth.using email', 'Login using Email')}
				</Text>
				{isSignUp && (
					<TextInput
						placeholder={getStaticTranslation('auth.name', 'Name')}
						style={[
							styles.input,
							isFocused.name && styles.border,
							isWeb && { outlineStyle: 'none' }
						]}
						value={name}
						onChangeText={setName}
						placeholderTextColor={theme.palette.grey[300]}
						onFocus={() => handleInputFocus('name')}
						onBlur={() => handleInputBlur('name')}
					/>
				)}
				<TextInput
					placeholder={getStaticTranslation('auth.email', 'Email')}
					style={[
						styles.input,
						isFocused.email && styles.border,
						isWeb && { outlineStyle: 'none' }
					]}
					value={email}
					onChangeText={setEmail}
					placeholderTextColor={theme.palette.grey[300]}
					onFocus={() => handleInputFocus('email')}
					onBlur={() => handleInputBlur('email')}
				/>
				<View
					style={
						isFocused.password
							? [styles.passwordContainer, styles.border]
							: styles.passwordContainer
					}
				>
					<TextInput
						placeholder={getStaticTranslation('auth.password', 'Password')}
						secureTextEntry={!passwordVisible}
						style={[
							styles.input,
							{ marginTop: 0, width: isAndroid || isIos ? '80%' : '100%' },
							isWeb && { outlineStyle: 'none' }
						]}
						value={password}
						onChangeText={setPassword}
						placeholderTextColor={theme.palette.grey[300]}
						onFocus={() => handleInputFocus('password')}
						onBlur={() => handleInputBlur('password')}
					/>
					<Pressable onPress={() => setPasswordVisible(!passwordVisible)}>
						<Icon
							name={passwordVisible ? 'eye-off' : 'eye'}
							size={25}
							style={styles.toggleIcon}
						/>
					</Pressable>
				</View>
				{isSignUp && (
					<>
						<View
							style={
								isFocused.confirmPassword
									? [styles.passwordContainer, styles.border]
									: styles.passwordContainer
							}
						>
							<TextInput
								placeholder={getStaticTranslation(
									'auth.confirm password',
									'Confirm Password'
								)}
								secureTextEntry={!confirmPasswordVisible}
								style={[
									styles.input,
									{
										marginTop: 0,
										width: isAndroid || isIos ? '80%' : '100%',
									},
									isWeb && { outlineStyle: 'none' }
								]}
								value={confirmPassword}
								onChangeText={setConfirmPassword}
								placeholderTextColor={theme.palette.grey[300]}
								onFocus={() => handleInputFocus('confirmPassword')}
								onBlur={() => handleInputBlur('confirmPassword')}
							/>
							<Pressable
								onPress={() =>
									setConfirmPasswordVisible(!confirmPasswordVisible)
								}
							>
								<Icon
									name={confirmPasswordVisible ? 'eye-off' : 'eye'}
									size={25}
									style={styles.toggleIcon}
								/>
							</Pressable>
						</View>
						<Text style={styles.passwordInfo}>
							{getStaticTranslation(
								'auth.password info',
								'Password must be at least 8 characters long and include a combination of letters, numbers, and symbols, with at least one  uppercase letter, one number, and one symbol.'
							)}
						</Text>
					</>
				)}
				{!isSignUp && (
					<View style={[styles.forgotContainer]}>
						<ForgotPassword />
					</View>
				)}
				<Pressable
					style={[
						styles.button,
						{
							backgroundColor: (
								isSignUp
									? !name || !email || !password || !confirmPassword :
									!email || !password
							)
								? theme.palette.grey[200]
								: theme.palette.green[200],
						},
					]}
					disabled={
						isSignUp
							? !name || !email || !password || !confirmPassword :
							!email || !password
					}
					onPress={isSignUp ? handleSignUp : handleLogin}
				>
					<Text style={[
						styles.buttonText,
						{
							color: (
								isSignUp
									? !name || !email || !password || !confirmPassword :
									!email || !password
							)
								? theme.palette.grey[600]
								: theme.palette.green[700],
						},
					]}>
						{isSignUp
							? getStaticTranslation('auth.sign up', 'Sign Up')
							: getStaticTranslation('auth.sign in', 'Sign In')}
					</Text>
				</Pressable>
				<View style={styles.changeFormContainer}>
					<Text style={styles.changeText}>
						{isSignUp
							? getStaticTranslation(
								'auth.have account',
								'Already have an account?'
							)
							: getStaticTranslation(
								'auth.no account',
								"Don't have an account?"
							)}{' '}
					</Text>
					<Pressable
						onPress={() => (isSignUp ? setIsSignUp(false) : setIsSignUp(true))}
					>
						<Text style={styles.changeForm}>
							{isSignUp
								? getStaticTranslation('auth.sign in', 'Sign In')
								: getStaticTranslation('auth.sign up', 'Sign Up')}
						</Text>
					</Pressable>
				</View>
				<View style={styles.footerContainer}>
					<Pressable onPress={() => { }}>
						<ARXLogo color={theme.palette.primary} />
					</Pressable>
				</View>
			</View>
		</Background>
	);
};

const cssStyles = (isWeb, isAndroid, isIos, width, height, isSignUp) => {
	const fontSize = getFontSize();
	const align = getAlign()
	return StyleSheet.create({
		languageContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-end',
			paddingTop: isAndroid || isIos ? 10 : 20,
			paddingRight: isAndroid || isIos ? 15 : 30,
		},
		logoContainer: {
			flex: 1,
			display: height < 500 ? 'none' : 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			paddingVertical: 20,
		},
		welcome: {
			color: theme.palette.secondary,
			fontSize: fontSize[20],
			textAlign: align.center,
			fontFamily: fontFamily.poppins[500],
			display: height < 570 ? 'none' : 'flex',
		},
		slogan: {
			color: theme.palette.secondary,
			marginTop: 10,
			fontSize: fontSize[14],
			textAlign: align.center,
			fontFamily: fontFamily.poppins[300],
			display: height < 570 ? 'none' : 'flex',
			width: !isWeb? "90%": "auto",
		},
		loginContainer: {
			display: 'flex',
			paddingVertical: 20,
			backgroundColor: theme.palette.lightGreen[100],
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-end',
			borderTopRightRadius: 25,
			borderTopLeftRadius: 25,
			paddingHorizontal: '15%',
			marginBottom: isIos ? 40 : 0
		},
		scrollContainer: {
			flex: 1,
			width: '100%',
		},
		scrollContent: {
			flexGrow: 1,
			gap: 10,
			padding: 5,
		},
		googleButton: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: theme.palette.blue[100],
			borderRadius: 10,
			width: '100%',
			paddingVertical: 10,
		},
		googleIcon: {
			backgroundColor: theme.palette.secondary,
			borderTopLeftRadius: 8,
			borderBottomLeftRadius: 8,
			marginLeft: 3,
			marginVertical: -7,
			padding: 8,
		},
		googleTextContainer: {
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		googleText: {
			color: theme.palette.secondary,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[500],
		},
		or: {
			color: theme.palette.primary,
			marginVertical: 10,
			textAlign: align.center,
			fontSize: fontSize[16],
			fontFamily: fontFamily.poppins[400],
		},
		info: {
			color: theme.palette.primary,
			marginBottom: 10,
			textAlign: align.center,
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[500],
		},
		input: {
			backgroundColor: theme.palette.secondary,
			padding: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: '100%',
			height: 50,
			marginTop: isSignUp && height < 570 ? 5 : 10,
			// outlineStyle: 'none',
			fontFamily: fontFamily.poppins[400],
		},
		passwordContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: isSignUp && height < 570 ? 5 : 10,
			justifyContent: 'space-between',
			backgroundColor: theme.palette.secondary,
			width: '100%',
			borderRadius: 10,
		},
		toggleIcon: {
			color: theme.palette.grey[300],
			marginHorizontal: 10,
		},
		forgotContainer: {
			width: '100%',
			alignItems: 'flex-end',
			justifyContent: 'flex-end',
		},
		border: {
			borderWidth: 2,
			borderColor: theme.palette.green[700],
		},
		button: {
			backgroundColor: theme.palette.green[200],
			marginTop: 15,
			width: '100%',
			height: 50,
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: 'center',
		},
		buttonText: {
			color: theme.palette.green[700],
			fontSize: fontSize[18],
			fontFamily: fontFamily.poppins[600],
		},
		changeFormContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
		},
		changeText: {
			color: theme.palette.primary,
			fontSize: fontSize[16],
			marginTop: 5,
			textAlign: align.center,
			fontFamily: fontFamily.poppins[400],
		},
		changeForm: {
			fontFamily: fontFamily.poppins[600],
			color: theme.palette.blue[100],
			fontSize: fontSize[16],
			marginTop: 5,
			textAlign: align.center,
			textDecorationLine: 'underline',
		},
		passwordInfo: {
			color: theme.palette.grey[300],
			fontSize: fontSize[12],
			textAlign: align.justify,
			marginTop: 10,
			fontFamily: fontFamily.poppins[300],
			display: height < 670 ? 'none' : 'flex',
		},
		footerContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: 20,
			marginBottom: isIos ? 10 : 0,
		},
	});
};

export default AuthScreen;
