import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  useWindowDimensions,
} from "react-native";
import { fontFamily, getFontSize, theme } from "../../theme";
import Background from "../../components/Background";
import { LogoSvg, SmallLogoSvg } from "../../components/Svg";
import Language from "../../components/Language";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "react-native-elements";
import Overview from "../../navigation/Overview";
import { auth } from "../../config/firebase";
import { useTranslation } from "../../components/TranslationProvider";
import { useFocusEffect } from "@react-navigation/native";
import { setMode } from "../../redux/reducer";

const HomeScreen = () => {
  const isWeb = Platform.OS === "web";
  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const { width, height } = useWindowDimensions();
  const { getStaticTranslation } = useTranslation();
  const styles = cssStyles(isWeb, isAndroid, isIos, width, height);
  const { user } = useSelector((state) => state.app);
  const [photo, setPhoto] = useState(user?.photoUrl || auth.currentUser?.photoURL);

  const dispatch = useDispatch();

  // Set mode on focus
  useFocusEffect(
    useCallback(() => {
      dispatch(setMode("dark"));
    }, [dispatch])
  );

  // Update photo if user or auth changes
  useEffect(() => {
    if (!user?.photoUrl && auth.currentUser?.photoURL) {
      setPhoto(auth.currentUser.photoURL);
    } else {
      setPhoto(user?.photoUrl);
    }
  }, [user, auth.currentUser]);

  const getInitials = (name) => {
    if (name) {
      const words = name.split(" ");
      if (words.length === 1) {
        return words[0].slice(0, 2).toUpperCase();
      } else {
        return words[0][0].toUpperCase() + words[1][0].toUpperCase();
      }
    }
  };

  return (
    <Background>
      <View style={styles.header}>
        <View style={{ paddingRight: 10 }}>
          {isWeb && width < 360 ? (
            <SmallLogoSvg width={49} height={30} />
          ) : (
            <LogoSvg
              width={isAndroid ? 150 : isIos ? 173 : "100%"}
              height={isWeb ? "60%" : 30}
            />
          )}
        </View>
        <Language />
      </View>

      <View style={styles.container}>
        <Avatar
          rounded
          size={height < 570 ? 50 : 64}
          source={{ uri: photo ? photo : "data:image/png" }}
          title={!photo ? getInitials(user?.displayName) : ""}
          containerStyle={styles.avatarContainer}
          titleStyle={styles.avatarTitle}
        />
        <View style={styles.textContainer}>
          <Text style={styles.welcomeText}>
            {getStaticTranslation("home.welcome", "Welcome,")}
          </Text>
          <Text style={styles.userName}>{user?.displayName}</Text>
          <Text style={styles.userEmail}>{user?.email}</Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Overview />
      </View>
    </Background>
  );
};

const cssStyles = (isWeb, isAndroid, isIos, width, height) => {
  const fontSize = getFontSize();
  return StyleSheet.create({
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      paddingHorizontal: isWeb ? "5%" : 30,
      paddingVertical: isAndroid || isIos ? 15 : 25,
      paddingBottom: height < 570 ? 0 : 25,
      maxWidth: isWeb ? (width > 500 ? 500 : width) : width,
    },
    text: {
      color: theme.palette.green[700],
    },
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 30,
      borderRadius: 10,
      gap: 20,
    },
    avatarContainer: {
      backgroundColor: theme.palette.green[200],
    },
    avatarTitle: {
      color: theme.palette.green[700],
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[30],
      paddingTop: isAndroid ? 5 : 0,
    },
    textContainer: {
      flex: 1,
    },
    welcomeText: {
      color: theme.palette.secondary,
      fontFamily: fontFamily.poppins[300],
      fontSize: fontSize[14],
    },
    userName: {
      color: theme.palette.secondary,
      fontFamily: fontFamily.poppins[500],
      fontSize: fontSize[16],
    },
    userEmail: {
      color: theme.palette.secondary,
      fontFamily: fontFamily.poppins[400],
      fontSize: fontSize[14],
    },
    contentContainer: {
      flex: 1,
      backgroundColor: theme.palette.lightGreen[100],
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      marginTop: height < 570 ? 10 : 20,
    },
  });
};

export default HomeScreen;
