import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import PersonalDetailsScreen from '../screens/Checker/PersonalDetailsScreen';
import MedicalHistoryScreen from '../screens/Checker/MedicalHistoryScreen';
import FamilyHistoryScreen from '../screens/Checker/FamilyHistoryScreen';
import SymptomSelectionScreen from '../screens/Checker/SymptomSelectionScreen';
import { theme } from '../theme';
import SymptomDurationScreen from '../screens/Checker/SymptomDurationScreen';
import SeverityScreen from '../screens/Checker/SeverityScreen';
import VitalScreen from '../screens/Checker/VitalScreen';
import QuestionnaireScreen from '../screens/Checker/QuestionnaireScreen';
import ResultScreen from '../screens/Checker/ResultScreen';
import RedFlagScreen from '../screens/Checker/RedFlagScreen';
import PatientEducationScreen from '../screens/Checker/PatientEducationScreen';

const Stack = createStackNavigator();
const CheckerNavigator = () => {
	return (
		<>
			<Stack.Navigator
				initialRouteName='Personal'
				screenOptions={{
					gestureEnabled: false,
					headerShown: false,
					cardStyle: {
						backgroundColor: theme.palette.lightGreen[100],
					},
				}}
			>
				<Stack.Screen name='Personal' component={PersonalDetailsScreen} />
				<Stack.Screen name='Medical' component={MedicalHistoryScreen} />
				<Stack.Screen name='Family' component={FamilyHistoryScreen} />
				<Stack.Screen name='Symptom' component={SymptomSelectionScreen} />
				<Stack.Screen name='Duration' component={SymptomDurationScreen} />
				<Stack.Screen name='Severity' component={SeverityScreen} />
				<Stack.Screen name='Question' component={QuestionnaireScreen} />
				<Stack.Screen name='Vital' component={VitalScreen} />
				<Stack.Screen name='Result' component={ResultScreen} />
				<Stack.Screen name='RedFlag' component={RedFlagScreen} />
				<Stack.Screen name='Education' component={PatientEducationScreen} />
			</Stack.Navigator>
		</>
	);
};

export default CheckerNavigator;
