import {
    ImageBackground,
    StyleSheet,
    Keyboard,
    TouchableWithoutFeedback,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';
import React from 'react';

const Background = ({ children }) => {
    const styles = cssStyles();
    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <ImageBackground
                style={styles.background}
                source={require('../../assets/bg.png')}
                resizeMode='cover'
            >
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                    style={{ flex: 1 }}
                    keyboardVerticalOffset={Platform.select({
                        ios: () => 0,
                        android: () => -85,
                        web: () => 0,
                    })()}
                >
                    {children}
                </KeyboardAvoidingView>
            </ImageBackground>
        </TouchableWithoutFeedback>
    );
};
const cssStyles = () => {
    return StyleSheet.create({
        background: {
            flex: 1,
            width: '100%',
            height: '100%',
            display: 'flex',
        },
    });
};
export default Background;
